import { Input } from 'components/Input'
import { CurrencyInput } from 'components/Input/CurrencyInput'
import { Select } from 'components/Select'
import { RadioGroup } from 'components/RadioGroup'
import { Breadcrumb } from 'components/Breadcrumb'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { getVenue, getDepartmentVenue, getDepartmentVenueRate, updateDepartmentVenueRate, createDepartmentVenueRate } from 'api/venues'
import { getDepartment } from 'api/clients'
import { getAccommodationRates } from 'api/rates'
import { notification } from 'utils/notifications'

export const EditDepartmentVenueRate = ({ attrs: { clientId, departmentId, venueId, departmentVenueId, departmentVenueRateId } }) => {
  let ready = false
  const vis = [{ label: 'Public', value: 3, desc: 'Available to delegates for booking' }, { label: 'Private', value: 2, desc: 'Available to admin for booking' }, { label: 'Closed', value: 1, desc: 'Not available for booking' }]
  let rates = []
  let departmentVenueRate = {}
  let venue
  let department
  let departmentVenue

  // This screen can be called from the department or from the Venue.
  // When routed through the department the venueId will be zero
  // When routed through the venue the clientId will be zero

  if (venueId) {
    getVenue(venueId).then(data => {
      venue = data
    })
  }
  if (clientId && departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
    })
  }
  getDepartmentVenue(departmentId, departmentVenueId).then(dv => {
    departmentVenue = dv
  })

  if (departmentVenueRateId > 0) {
    getDepartmentVenueRate(departmentId, departmentVenueId, departmentVenueRateId).then(dvr => {
      departmentVenueRate = dvr
      ready = true
    })
  } else {
    getAccommodationRates({ pagesize: 10000 }).then(data => {
      // eslint-disable-next-line no-unused-vars
      rates = data
      departmentVenueRate = { departmentVenueId: departmentVenueId }
      ready = true
    })
  }

  const createUpdateDepartmentVenueRate = (e) => {
    e.preventDefault()
    if (departmentVenueRate.id) {
      departmentVenueRate.rateTypeId = departmentVenueRate.rateType.id
      updateDepartmentVenueRate(departmentId, departmentVenueId, departmentVenueRate.id, departmentVenueRate).then(() => {
        notification.success({ title: 'Updated' })
        if (venueId) {
          m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`)
        } else {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`)
        }
      }).catch(() => {
        notification.error({ title: 'Error - unable to update department venue rate' })
      })
    } else {
      createDepartmentVenueRate(departmentId, departmentVenueId, departmentVenueRate).then(() => {
        notification.success({ title: 'Created' })
        if (venueId) {
          m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`)
        } else {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`)
        }
      }).catch(err => {
        console.log(err)
        notification.error({ title: 'Error - unable to create department venue' })
      })
    }
  }
  return {
    view ({ attrs: { clientId, departmentId, venueId, departmentVenueId, departmentVenueRateId } }) {
      return m('div', [
        department && departmentVenue && m(Breadcrumb, {
          links: [{
            title: 'Clients',
            href: '/clients'
          }, {
            title: department.clientName,
            href: `/clients/${clientId}`
          }, {
            title: 'Departments',
            href: `/clients/${clientId}?tab=Departments`
          }, {
            title: department.name,
            href: `/clients/${clientId}/departments/${departmentId}`
          }, {
            title: 'Venues',
            href: `/clients/${clientId}/departments/${departmentId}?tab=Venues`
          }, {
            title: departmentVenue.venue.name,
            href: `/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`
          }, {
            title: 'Rates'
          }, {
            title: departmentVenueRateId ? 'Edit' : 'Add'
          }]
        }),
        venue && departmentVenue && m(Breadcrumb, {
          links: [{
            title: 'Venues',
            href: '/venues'
          }, {
            title: venue.name,
            href: `/venues/${venueId}`
          }, {
            title: 'Departments',
            href: `/venues/${venueId}?tab=Departments`
          }, {
            title: departmentVenue.department.name,
            href: `/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`
          }, {
            title: 'Rates'
          }, {
            title: departmentVenueRateId ? 'Edit' : 'Add'
          }]
        }),
        ready && m(EditForm, {
          onsubmit: createUpdateDepartmentVenueRate
        },
        [
          m(EditFormSection, {
            heading: departmentVenueRateId ? 'Edit Venue Rate' : 'Add Venue Rate',
            description: 'Please enter the rate details for the venue at this department'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6',
                [
                  departmentVenueRateId ? m(Input, {
                    label: 'Rate',
                    value: departmentVenueRate.rateType ? departmentVenueRate.rateType.name : '',
                    type: 'readonly'
                  }) : m(Select, {
                    label: 'Select Rate',
                    search: true,
                    value: departmentVenueRate.rateTypeId,
                    required: true,
                    onchange (e) {
                      departmentVenueRate.rateTypeId = e
                      departmentVenueRate.label = rates.data.find(d => { return d.id === parseInt(e) }).name
                    },
                    choices: rates.data.map(d => {
                      return { label: `${d.bookingTypeDescription} - ${d.name}`, value: d.id, isActive: d.isActive }
                    })
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(Input, {
                    label: 'Label',
                    value: departmentVenueRate.label,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      departmentVenueRate.label = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-3.lg:col-span-2',
                [
                  m(CurrencyInput, {
                    label: 'Tariff',
                    value: departmentVenueRate.tariff,
                    type: 'number',
                    min: 0,
                    step: '.01',
                    required: true,
                    oninput (value) {
                      departmentVenueRate.tariff = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-3.lg:col-span-2'),
              m('div.col-span-6.lg:col-span-3',
                [
                  m(RadioGroup, {
                    label: 'Visibility',
                    value: departmentVenueRate.visibilityId,
                    required: true,
                    options: vis,
                    name: 'visibilityId',
                    onchange (value) {
                      departmentVenueRate.visibilityId = value
                    }
                  })
                ]
              )
            ])
          ])
        ])
      ])
    }
  }
}
