import { Card } from 'components/Card'
import { Heading } from 'components/Heading'
import { Svg } from 'components/Svg'

import loadingIcon from 'assets/loading.svg'
import arrowIcon from 'assets/arrow-right.svg'

const loadingSpinner = () => {
  return m(Svg, {
    classes: ['w-6', 'h-6', 'text-white', 'rotate', 'm-auto']
  }, loadingIcon)
}

export const DelegateCard = () => {
  return {
    view ({ attrs: { stepNumber, currentStep, heading, buttonText, onchange, back, isValid = false, loading = false }, children }) {
      return m('form.flex.flex-col.flex-shrink-0.relative', {
        onsubmit (e) {
          e.preventDefault()
          onchange()
        }
      }, [
        m(Card, {
          classes: ['w-full', 'max-w-full', 'bg-gray-200', 'relative', 'transition-opacity', 'duration-200', 'ease-in', (currentStep() < (stepNumber - 1) && 'opacity-0')]
        }, [
          m('.py-6.px-4.heading.flex.items-center', [
            m('.rounded-full.h-8.w-8.mr-4.bg-cyan.text-white.flex.items-center.flex-shrink-0', [
              m('p.text-xl.text-center.w-full', {
                style: stepNumber === 1 && {
                  transform: 'translateX(-1px)'
                }
              }, stepNumber)
            ]),
            m(Heading, {
              level: 'delegate'
            }, heading)
          ]),
          m('div', [...children]),
          m('.flex.gap-x-1', [
            back && m('button.text-white.w-full.h-full.p-4.relative.flex.align-items-center.justify-center.focus:outline-none.focus:shadow-none.flex-1', {
              type: 'button',
              class: 'bg-gray-400 hover:bg-gray-500',
              onclick (e) {
                if (back) { back() }
              }
            }, [
              loading && loading()
                ? m('.absolute-center', loadingSpinner())
                : 'Back'
            ]), m('button.text-white.w-full.h-full.p-4.relative.flex.align-items-center.justify-center.focus:outline-none.focus:shadow-none.flex-2', {
              type: 'submit',
              class: isValid && !isValid() ? 'bg-gray-300' : 'bg-cyan',
              disabled: isValid && !isValid()
            }, [
              loading && loading()
                ? m('.absolute-center', loadingSpinner())
                : buttonText
            ])
          ])
        ]),
        m('.overlay.absolute.inset-0', {
          class: currentStep() > (stepNumber - 1)
            ? 'bg-cyan-50 z-50 cursor-pointer fadeBlueIn'
            : (currentStep() < (stepNumber - 1)
              ? 'z-50 opacity-75'
              : 'opacity-0 pointer-events-none'),
          onclick () {
            if (currentStep() > (stepNumber - 1)) {
              currentStep(stepNumber - 1)
            }
          }
        }, [
          currentStep() > (stepNumber - 1) && m('.rounded-full.w-10.h-10.bg-cyan.transform.absolute.top-1/2.right-0.-translate-y-1/2.mr-2.flex.justify-center.items-center', [
            m(Svg, {
              classes: ['w-6', 'transform', 'rotate-180', 'text-white'],
              style: {
                position: 'absolute',
                top: '50%',
                right: '0.5rem'
              }
            }, arrowIcon)
          ])

        ])
      ])
    }
  }
}
