
import { Heading } from 'components/Heading'
import { Svg } from 'components/Svg'
// import { UserAvatar } from 'components/Avatar'
import { EmptyState } from 'components/EmptyState'
import { LoadingFullScreen } from 'components/Loading/FullScreen'
import { Activity } from './Activity'
import moreIcon from 'assets/more.svg'
import alertIcon from 'assets/alert-circle.svg'

export const PastActivityBar = () => {
  const activity = flyd.stream(false)
  const open = flyd.stream(false)
  const bodyClickFunction = () => {
    open(false)
    m.redraw()
  }
  const toggleBodyClick = () => {
    if (open()) {
      document.body.addEventListener('click', bodyClickFunction)
    } else {
      document.body.removeEventListener('click', bodyClickFunction)
    }
  }
  return {
    view ({ attrs: { request, title, hideModelUpdates } }) {
      return m('.fixed.right-0.bottom-0.z-30.bg-white.flex.w-96.shadow-lg.activity-initial', {
        style: 'top: 63px',
        onbeforeremove () {
          open(false)
          toggleBodyClick()
        },
        onclick (e) {
          e.stopPropagation()
        },
        class: open() ? 'activity-slide-in' : ''
      }, [
        m('.w-8.flex.h-100.items-center.justify-center.cursor-pointer.flex-shrink-0.border-r', {
          onclick () {
            open(!open())
            toggleBodyClick()
            if (!activity()) {
              request(activity)
            }
          }
        }, [
          m(Svg, {
            classes: ['w-5', 'transform', 'rotate-90']
          }, moreIcon)
        ]),
        m('.w-full.p-4.flex.flex-col', [
          m(Heading, { level: 3 }, title),
          activity()
            ? activity().length
              ? m('.flex.flex-col.border-t.mt-4.h-full.overflow-auto', activity().map(ea => {
                return ea.isModelUpdate && hideModelUpdates ? '' : m(Activity, { activity: ea })
              }))
              : m(EmptyState, { icon: alertIcon, message: t('activityBar', 'noActivity') })
            : m(LoadingFullScreen)
        ])
      ])
    }
  }
}
