import { request } from './request'

export const getClients = (params) => {
  return request({
    url: 'clients',
    method: 'GET',
    params: {
      pagesize: 25,
      ...params
    }
  })
}

export const getClient = (id) => {
  return request({
    url: `clients/${id}`,
    method: 'GET'
  })
}

export const createClient = (client) => {
  return request({
    url: 'clients',
    method: 'POST',
    body: client
  })
}

export const updateClient = (client) => {
  return request({
    url: `clients/${client.id}`,
    method: 'PUT',
    body: client
  })
}

export const getClientActivity = (id) => {
  return request({
    url: `clients/${id}/activity`,
    method: 'GET'
  })
}

export const getDepartments = (id) => {
  return request({
    url: `clients/${id}/departments`
  })
}

export const getAllDepartments = (params) => {
  return request({
    url: 'departments',
    method: 'GET',
    params: {
      pagesize: 25,
      ...params
    }
  })
}

export const getDepartment = (id, dId) => {
  return request({
    url: `departments/${dId}`
  })
}

export const createDepartment = (id, department) => {
  department.clientId = id
  return request({
    url: 'departments',
    method: 'POST',
    body: department
  })
}

export const updateDepartment = (id, department) => {
  department.clientId = id
  return request({
    url: `departments/${department.id}`,
    method: 'PUT',
    body: department
  })
}

export const getDepartmentBookingFields = (departmentId, params) => {
  return request({
    url: `departments/${departmentId}/bookingFields`,
    method: 'GET',
    params: {
      pagesize: 25,
      ...params
    }
  })
}

export const createDepartmentBookingField = (departmentId, bookingField) => {
  bookingField.departmentId = departmentId
  return request({
    url: `departments/${departmentId}/bookingFields`,
    method: 'POST',
    body: bookingField
  })
}

export const updateDepartmentBookingField = (departmentId, bookingField) => {
  bookingField.departmentId = departmentId
  return request({
    url: `departments/${departmentId}/bookingFields/${bookingField.id}`,
    method: 'PUT',
    body: bookingField
  })
}

export const getDepartmentBillingInstructions = (departmentId, params) => {
  return request({
    url: `departments/${departmentId}/billing`,
    method: 'GET',
    params: { ...params }
  })
}

export const getDepartmentBillingInstruction = (departmentId, billingInstructionId) => {
  return request({
    url: `departments/${departmentId}/billing/${billingInstructionId}`,
    method: 'GET'
  })
}

export const createDepartmentBillingInstruction = (departmentId, billingInstruction) => {
  billingInstruction.departmentId = departmentId
  return request({
    url: `departments/${departmentId}/billing`,
    method: 'POST',
    body: billingInstruction
  })
}

export const updateDepartmentBillingInstruction = (departmentId, billingInstruction) => {
  billingInstruction.departmentId = departmentId
  return request({
    url: `departments/${departmentId}/billing/${billingInstruction.id}`,
    method: 'PUT',
    body: billingInstruction
  })
}

export const deleteDepartmentBillingInstruction = (departmentId, billingInstruction) => {
  billingInstruction.departmentId = departmentId
  return request({
    url: `departments/${departmentId}/billing/${billingInstruction.id}`,
    method: 'DELETE'
  })
}

export const getDepartmentVenues = (id, params) => {
  return request({
    url: `departments/${id}/venues`,
    method: 'GET',
    params: {
      pagesize: 25,
      ...params
    }
  })
}

export const getDepartmentActivity = (departmentId) => {
  return request({
    url: `departments/${departmentId}/activity`,
    method: 'GET'
  })
}

export const getDepartmentBookings = (departmentId, params) => {
  return request({
    url: 'bookings',
    method: 'GET',
    params: {
      departmentId,
      pagesize: 25,
      sortOrder: 'desc',
      ...params
    }
  })
}

export const getDepartmentMeetings = (departmentId, params) => {
  return request({
    url: 'meetings',
    method: 'GET',
    params: {
      departmentId,
      pagesize: 25,
      sortOrder: 'desc',
      ...params
    }
  })
}

export const getDepartmentUsers = (departmentId) => {
  return request({
    url: `departments/${departmentId}/users`,
    method: 'GET'
  })
}

export const getClientUsers = (clientId) => {
  return request({
    url: `clients/${clientId}/users`,
    method: 'GET'
  })
}

export const getClientBookings = (params) => {
  return request({
    url: 'clientadmin/bookings',
    method: 'GET',
    params: {
      pagesize: 25,
      sortOrder: 'desc',
      ...params
    }
  })
}

export const getClientDepartments = (params) => {
  return request({
    url: 'clientadmin/departments',
    method: 'GET',
    params: {
      pagesize: 25,
      sortOrder: 'desc',
      isActive: true,
      ...params
    }
  })
}

export const getClientDepartmentVenues = (id, params) => {
  return request({
    url: `clientadmin/departments/${id}/venues`,
    method: 'GET',
    params: {
      pagesize: 25,

      ...params
    }
  })
}

export const getBookingStatuses = () => {
  return request({
    url: 'clientadmin/booking-statuses',
    method: 'GET'
  })
}
