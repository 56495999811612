export const messages = {
  app: {
    loggedIn: 'Successfully logged in!',
    edit: 'Edit',
    cancel: 'Cancel',
    save: 'Save',
    updated: 'Updated',
    remove: 'Remove',
    accommodation: 'Accommodation',
    meetings: 'Meetings',
    dashboard: 'Dashboard',
    s4v: 'Search4Venues'
  },
  logIn: {
    title: 'Log in',
    email: 'Username / Email Address',
    password: 'Password',
    signUpLink: 'No account? Register here',
    action: 'Log in',
    forgotten: 'Forgotten your sign in details?',
    forgottenInstruction: 'Email the accommodation team or call 01676 522868',
    genericError: 'There was an error logging in.'
  },
  register: {
    title: 'Register',
    email: 'Email Address',
    password: 'Password',
    logInLink: 'Log in',
    action: 'Register'
  },
  sidebar: {
    addDepartment: 'Add Department',
    addClient: 'Add Client'
  },
  venues: {
    title: 'Venues',
    addNew: 'Add New',
    venueName: 'Venue Name',
    venueGroup: 'Venue Group',
    venueStatus: 'Status',
    editVenue: 'Edit venue',
    updated: 'Venue Updated',
    notes: 'Notes',
    currency: 'Currency'
  },
  venueGroups: {
    title: 'Venue Groups',
    addNew: 'Add New',
    venueGroupName: 'Venue Group',
    venueGroupNameField: 'Venue group name',
    created: 'Venue Group Created',
    updated: 'Venue Group Updated',
    submit: 'Submit'
  },
  clients: {
    title: 'Clients',
    addTitle: 'Add Client',
    editTitle: 'Edit Client',
    updateTitle: 'Update Client',
    name: 'Name',
    status: 'Status',
    description: 'Description',
    notes: 'Notes',
    clientDescription: 'Description',
    clientNotes: 'Notes',
    address1: 'Address line 1',
    address2: 'Address line 2',
    townCity: 'Town / City',
    county: 'County',
    postCode: 'Postcode',
    country: 'Country',
    telephone: 'Phone number',
    logo: 'Logo',
    save: 'Save',
    created: 'Client created',
    updated: 'Client updated'
  },
  viewClient: {
    cardTitle: 'Client Information',
    cardDescription: 'Description',
    name: 'Name',
    description: 'Description',
    notes: 'Notes',
    address: 'Address',
    telephone: 'Phone number',
    status: 'Status',
    departments: 'Departments',
    departmentsDescription: 'Description',
    addDepartment: 'Add',
    activityTitle: 'Client Activity'
  },
  contact: {
    contact: 'Contact',
    name: 'Name',
    position: 'Position',
    edit: 'Edit',
    telephone: 'Phone number',
    mobile: 'Mobile number',
    email: 'Email address',
    notes: 'Notes'
  },
  viewDepartment: {
    cardTitle: 'Department Information',
    cardDescription: 'Description',
    name: 'Name',
    description: 'Description',
    notes: 'Notes',
    status: 'Status',
    activityTitle: 'Department Activity',
    venuesTitle: 'Venues',
    addVenue: 'Add venue',
    ratesTitle: 'Rates',
    addBookingField: 'Add field'
  },
  forgottenPassword: {
    title: 'Reset password',
    email: 'Email Address',
    logInLink: 'Log in',
    action: 'Request password change',
    resetAction: 'Set new password'
  },
  nav: {
    home: 'Home',
    settings: 'Settings'
  },
  navbar: {
    dashboard: 'Dashboard',
    venues: 'Venues',
    rates: 'Rates',
    settings: 'Settings',
    invoices: 'Invoices',
    emails: 'Emails',
    venueGroups: 'Venue Groups',
    users: 'Users',
    logOut: 'Log out'
  },
  rates: {
    title: 'Rates',
    addNew: 'Add New',
    rateName: 'Rate Name',
    submit: 'Submit',
    created: 'Rate added',
    updated: 'Rate updated'
  },
  users: {
    title: 'Users',
    addTitle: 'Add New User',
    name: 'Name',
    email: 'Email',
    submit: 'Submit',
    created: 'User added',
    updated: 'User updated',
    status: 'Status'
  },
  viewVenue: {
    genericTitle: 'Venue Details',
    genericDescription: 'Description',
    contactsTitle: 'Contacts',
    contactsDescription: 'Description',
    addAccommodationInfo: '+ Accommodation Info',
    addMeetingsInfo: '+ Meetings Info',
    status: 'Status',
    venueGroup: 'Venue Group',
    venueName: 'Venue Name',
    currency: 'Currency',
    address: 'Address',
    town: 'Town',
    county: 'County',
    postCode: 'Postcode',
    country: 'Country',
    accommodationDetails: 'Accommodation details',
    email: 'Email address',
    website: 'Website',
    phoneNumber: 'Phone number',
    cancellationPolicy: 'Cancellation policy',
    fax: 'Fax',
    meetingsDetails: 'Meetings details',
    maxOccupancy: 'Max occupancy',
    activityTitle: 'Venue Activity',
    updated: 'Venue Updated'
  },
  createDepartment: {
    addTitle: 'Add Department',
    updateTitle: 'Edit Department',
    departmentName: 'Department name',
    departmentDescription: 'Description',
    departmentNotes: 'Notes',
    status: 'Status',
    save: 'Save'
  },
  createVenue: {
    createVenue: 'Create Venue',
    save: 'Save',
    venueDetails: 'Venue Details',
    venueName: 'Name',
    venueGroup: 'Group',
    status: 'Status',
    currency: 'Currency',
    address: 'Address',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    town: 'Town / City',
    county: 'County',
    postCode: 'Postcode',
    country: 'Country',
    accommodationDetails: 'Accommodation details',
    emailAddress: 'Email address',
    phoneNumber: 'Phone number',
    cancellationPolicy: 'Cancellation policy',
    fax: 'Fax',
    meetingsDetails: 'Meetings details',
    maxOccupancy: 'Max occupancy',
    contactDetails: 'Contact details',
    telephone: 'Telephone',
    email: 'Email',
    website: 'Website',
    created: 'Venue Created'
  },
  contacts: {
    firstName: 'First name',
    lastName: 'Last name',
    position: 'Position',
    telephone: 'Telephone',
    mobile: 'Mobile',
    email: 'Email',
    notes: 'Notes',
    createContactTitle: 'Create Venue Contact',
    createContact: 'Create Contact',
    editContactTitle: 'Edit Venue Contact',
    editContact: 'Edit Contact',
    updated: 'Contact Updated',
    created: 'Contact Created'
  },
  venueAccommodation: {
    addAccommodationInfoTitle: 'Venue Accommodation Information',
    website: 'Website',
    checkIn: 'Check in time',
    checkOut: 'Check out time',
    conferenceOfficeContact: 'Conference office contact',
    cancellationPolicy: 'Cancellation policy',
    addAccommodationInfo: 'Add Accommodation Information'
  },
  venueMeeting: {
    addMeetingsInfoTitle: 'Venue Meetings Information',
    website: 'Website',
    maxOccupancy: 'Max occupancy',
    cancellationPolicy: 'Cancellation policy',
    conferenceOfficeContact: 'Conference office contact',
    addMeetingsInfo: 'Add Meetings Information'
  },
  activityBar: {
    noActivity: 'No activity recorded'
  },
  settings: {
    logOut: 'Log out',
    title: 'Settings',
    emailNotConfirmed: 'Email not confirmed',
    emailNotConfirmedText: 'You have yet to confirm your email address. Please check your inbox for further instructions or click resend below.',
    resendEmailConfirmation: 'Resend',
    profileTitle: 'Your Profile',
    profileEmail: 'Email address:',
    profileFirstName: 'First name:',
    profileLastName: 'Last name:',
    profileCompany: 'Company:',
    profilePosition: 'Position:',
    profilePhoneNumber: 'Phone number:',
    profileSubmit: 'Update',
    appTitle: 'App settings',
    appLanguage: 'Language:',
    appSubmit: 'Update App Settings',
    profileSaved: 'Profile updated'
  },
  delegateBooking: {
    guestInformation: 'Guest Information',
    next: 'Next',
    name: 'Full Name',
    firstName: 'First Name',
    surname: 'Surname',
    namePlaceholder: 'First Last',
    email: 'Email',
    emailPlaceholder: 'user@tobook.co.uk',
    telephone: 'Telephone',
    street: 'Street',
    townCity: 'Town / City',
    county: 'County',
    postcode: 'Post Code',
    bookingDetails: 'Booking Details',
    location: 'Location',
    dateOfStay: 'Date of stay',
    numberOfNights: 'Number of nights',
    additionalInfo: 'Additional info / Personal requirements',
    authorisation: 'Authorisation code',
    hotelSelection: 'Choose Hotel',
    confirmBooking: 'Confirm booking',
    firstChoice: '1st Choice',
    secondChoice: '2nd Choice',
    chooseHotel: 'Choose hotel',
    firstChoiceTitle: 'Select your 1st choice hotel',
    confirmSelection: 'Confirm selection',
    options: 'Select accommodation',
    secondChoiceTitle: 'Select your 2nd choice hotel',
    changeThis: 'Change this',
    bookingRequestSubmitted: 'Submitted',
    makeAnotherRequest: 'Make another request'
  }
}
