import { Breadcrumb } from 'components/Breadcrumb'
import { Button } from 'components/Button'
import { Table } from 'components/Table'
import { Svg } from 'components/Svg'
import { Pagination } from 'components/Pagination'

import { getClients } from 'api/clients'

import { debounce } from 'utils/debounce'

import addIcon from 'assets/icons/outline/plus.svg'

const Client = ({ attrs: { item, classes = [] } }) => {
  return {
    view () {
      return m(m.route.Link, {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'cursor-pointer', 'bg-white', ...classes].join(' '),
        style: {
          height: '40px'
        },
        href: `/clients/${item.id}`
      },
      [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.name)
        ),
        m('.flex.w-32.h-10.px-6.py-3.whitespace-nowrap.justify-center',
          m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
            class: item.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          },
          item.isActive ? 'Active' : 'Closed'
          )
        )
      ]
      )
    }
  }
}

export const Clients = () => {
  const filters = { page: 1, search: null, isActive: true }

  let clients
  let meta

  getClients(filters).then(data => {
    clients = data.data
    meta = data.meta
  })

  const filterStatus = (val) => {
    filters.isActive = val
    getClients(filters).then(data => {
      clients = data.data
      meta = data.meta
    })
  }

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    getClients(filters).then(data => {
      clients = data.data
      meta = data.meta
    })
  }, 300)

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: t('clients', 'title')
          }],
          buttons: [
            m(Button, {
              size: 'sm',
              classes: ['my-2', 'mx-auto', 'whitespace-nowrap', 'text-white'],
              type: 'button',
              onclick: () => {
                m.route.set('/clients/create')
              }
            }, [m(Svg, {
              classes: ['w-4', 'mx-auto']
            }, [addIcon]), t('clients', 'addTitle')])]
        }),
        m('.p-4', { style: { height: 'calc(100% - 3rem)' } }, [
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            clients && m(Table, {
              cols: [
                {
                  label: t('clients', 'name'),
                  search: true,
                  searchOnChange: (val) => {
                    debouncedSearch(val)
                  }
                },
                {
                  label: t('clients', 'status'),
                  classes: ['w-32 justify-center'],
                  filter: true,
                  filterOptions: [
                    { name: 'Active', id: true },
                    { name: 'Closed', id: false }],
                  filterOnChange: (val) => {
                    filterStatus(val)
                  }
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto',
                clients.map((v, ix) => {
                  return m(Client, { key: v.id, item: v, classes: [ix % 2 ? 'bg-gray-50' : 'bg-white'] })
                })
              ),
              meta && m(Pagination, {
                meta: meta,
                onpage: (page) => {
                  filters.page = page
                  getClients(filters).then(data => {
                    clients = data.data
                    meta = data.meta
                  })
                }
              })
            ])
          )
        ])
      ]
    }
  }
}
