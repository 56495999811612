import { Card } from 'components/Card'
import { Svg } from 'components/Svg'
import { Heading } from 'components/Heading'
import { DateTime } from 'luxon'

import tickIcon from 'assets/tick.svg'

export const SubmittedState = () => {
  return {
    // onbeforeremove ({ dom }) {
    //   dom.classList.add('fadeOutFull')
    //   return new Promise(function (resolve) {
    //     dom.addEventListener('animationend', resolve)
    //   })
    // },
    view ({ attrs: { isSubmitted, onclear } }) {
      return m('.px-4', [
        m(Card, {
          classes: ['w-128', 'max-w-full', 'mx-auto', 'overflow-hidden', 'bg-gray-200', 'fadeInFull']
        }, [
          m('.py-8.px-5.heading.flex.items-center', [
            m('.rounded-full.h-8.w-8.mr-4.bg-cyan.text-white.flex.items-center.justify-center.flex-shrink-0', [
              m(Svg, {
                classes: ['w-5', 'h-5']
              }, tickIcon)
            ]),
            m(Heading, {
              level: 'delegate'
            }, t('delegateBooking', 'bookingRequestSubmitted'))
          ]),
          m('.text-sm.px-5.pb-8', `Your booking request made ${DateTime.fromMillis(isSubmitted()).toLocaleString(DateTime.DATETIME_MED)} has been submitted for processing.`),
          m('button.text-white.w-full.h-full.p-4.relative.flex.align-items-center.justify-center.bg-cyan.focus:outline-none.focus:shadow-none', {
            onclick: onclear,
            type: 'button'
          }, [
            t('delegateBooking', 'makeAnotherRequest')
          ])
        ])
      ])
    }
  }
}
