import { PageHeading } from 'components/PageHeading'
import { Breadcrumb } from 'components/Breadcrumb'

import { Card } from 'components/Card'
import { Button } from 'components/Button'
import { Avatar } from 'components/Avatar'
import { Tabs } from 'components/Tabs'
import { Svg } from 'components/Svg'
import { PastActivityBar } from 'components/PastActivityBar'

import { getClient, getClientActivity, getClientUsers } from 'api/clients'
import editIcon from 'assets/icons/solid/pencil-alt.svg'
import phoneIcon from 'assets/icons/solid/phone.svg'
import locationIcon from 'assets/icons/solid/location-marker.svg'
import pencilIcon from 'assets/icons/solid/pencil.svg'
import addIcon from 'assets/icons/outline/plus.svg'
import emailIcon from 'assets/icons/solid/mail.svg'

const DepartmentCard = () => {
  return {
    view ({ attrs: { client, department } }) {
      return m(Card, {
        onclick: () => {
          m.route.set(`/clients/${client.id}/departments/${department.id}`)
        },
        clickable: true
      }, [
        m('.flex.items-center.justify-between', [
          m('div', [
            m('p', department.name),
            m('p.text-sm.text-gray-600', department.description || 'No description'),
            m('p.text-sm.text-gray-600.mt-2', [
              m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
                class: department.isActive ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'
              },
              department.isActive ? 'Active' : 'Closed'
              )
            ])
          ]),
          m(Avatar, { name: department.name })
        ])
      ])
    }
  }
}

export const ViewClient = ({ attrs: { clientId, tab } }) => {
  let client
  const metaItems = []
  getClient(clientId).then(data => {
    client = data
    if (client.description) {
      metaItems.push({
        icon: pencilIcon,
        label: client.description
      })
    }
    if (client.notes) {
      metaItems.push({
        icon: pencilIcon,
        label: client.notes
      })
    }
    if (client.addressLine1 || client.townCity || client.postCode) {
      const addressArray = [client.addressLine1, client.townCity, client.postCode]
      metaItems.push({
        icon: locationIcon,
        label: addressArray.join(', ')
      })
    }
    if (client.telephone) {
      metaItems.push({
        icon: phoneIcon,
        label: client.telephone
      })
    }
    if (client.poNumberContactEmail) {
      metaItems.push({
        icon: emailIcon,
        label: client.poNumberContactEmail ? `${client.poNumberContactName} <${client.poNumberContactEmail}>` : 'No PO contact set'
      })
    }
  })

  const tabs = [
    {
      id: 1,
      name: 'Departments',
      active: !tab || tab === 'Departments'
    },
    {
      id: 2,
      name: 'Users',
      active: tab && tab === 'Users'
    }
  ]

  return {
    view ({ attrs: { clientId, tab } }) {
      return [
        m('.pr-8', [
          client && m(Breadcrumb, {
            links: [{
              title: 'Clients',
              href: '/clients'
            }, {
              title: client.name
            }]
          }),
          client && m('.flex.flex-wrap.relative.h-full.overflow-x-hidden.content-start', [
            m('.p-4.w-full', [
              m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', {
              }, [
                m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
                  m(PageHeading, {
                    heading: [client.name, m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
                      class: client.isActive ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'
                    },
                    client.isActive ? 'Active' : 'Closed'
                    )],
                    metaItems,
                    actions: [
                      {
                        icon: editIcon,
                        label: 'Edit',
                        action: () => {
                          m.route.set(`/clients/${clientId}/edit`)
                        }
                      }
                    ]
                  })
                ]),
                m('div.bg-gray-50.px-4.py-5.space-y-6.sm:p-6', [
                  m(Tabs, {
                    tabs,
                    onTabChange: (tabId) => {
                      tabs.forEach(t => {
                        t.active = false
                        if (t.id === tabId) {
                          t.active = true
                          tab = t
                        }
                      })
                      m.redraw()

                      var pageUrl = `/clients/${clientId}?tab=${tab.name}`
                      window.history.pushState('', '', pageUrl)
                    }
                  }),
                  m('.sm:grid.sm:grid-cols-4.sm:gap-4.p-4.w-full', [
                    m('div.sm:col-span-4', [
                      tabs.find(t => t.active).id === 1
                        ? [m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-3', [
                          client.departments && client.departments.sort((x, y) => (x.isActive === y.isActive) ? 0 : x.isActive ? -1 : 1).map(department => {
                            return m('.col-span-1', [
                              m(DepartmentCard, { client, department })
                            ])
                          })
                        ]),
                        m('.flex.justify-end.mt-3', [
                          m(Button, {
                            size: 'sm',
                            classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                            type: 'button',
                            onclick: () => {
                              m.route.set(`/clients/${client.id}/departments/create`)
                            }
                          }, [m(Svg, {
                            classes: ['w-4', 'mx-auto']
                          }, [addIcon]), 'Add department'])
                        ])]
                        : tabs.find(t => t.active).id === 2
                          ? m(UsersTab, {
                            clientId
                          })
                          : null
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        m(PastActivityBar, {
          title: t('viewClient', 'activityTitle'),
          request: (data) => {
            getClientActivity(client.id).then(res => {
              data(res)
              m.redraw()
            })
          }
        })
      ]
    }
  }
}

const UsersTab = ({ attrs: { clientId } }) => {
  let users

  getClientUsers(clientId).then(data => {
    users = data
  })
  return {
    view () {
      return m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-3.pt-3', [
        users && users.length ? users.sort((x, y) => (x.isActive === y.isActive) ? 0 : x.isActive ? -1 : 1).map(user => {
          return m('.col-span-1', [
            m(UserCard, { clientId, user })
          ])
        }) : m('p.col-span-3.text-center.p-3.text-gray-400', 'No results'),
        m('.flex.col-span-3.justify-center.gap-6', [
          m(Button, {
            size: 'sm',
            classes: ['my-2', 'whitespace-nowrap', 'text-white'],
            type: 'button',
            onclick: () => {
              m.route.set(`/clients/${clientId}/users/create`)
            }
          }, [
            m(Svg, {
              classes: ['w-4', 'mx-auto']
            }, [addIcon]), 'Add User'])
        ])
      ])
    }
  }
}

const UserCard = () => {
  return {
    view ({ attrs: { clientId, departmentId, user } }) {
      return m(Card, {
        onclick: () => {
          m.route.set(`/clients/${clientId}/users/${user.id}`)
        },
        clickable: false // !user.isDepartmentUser
      }, [
        m('.flex.items-center.justify-between', [
          m('div', [
            m('p', user.name),
            m('p.text-sm.text-gray-600', user.email),
            user.email !== user.userName && m('p.text-sm.text-gray-600', user.userName),
            m('p.text-sm.text-gray-600.mt-2', [
              m('span.inline-flex.items-center.rounded-full.text-xs.font-medium', {
                class: !user.isDepartmentUser ? 'px-2 py-0.5 bg-purple-200 text-purple-800' : 'px-2 py-0.5 bg-gray-200 text-gray-800'
              },
              user.isDepartmentUser ? 'Department User' : 'Client Admin'
              )
            ]),
            user.isDepartmentUser && m('p.text-sm.text-gray-600.mt-2', [
              Object.keys(user.departments).map(dept => {
                return m('span.inline-flex.items-center.mr-2.px-2.rounded-full.text-xs.font-medium.bg-purple-200.text-purple-800',
                  user.departments[dept]
                )
              })
            ]),
            !user.isConfirmed && m('p.text-sm.text-gray-600.mt-2', [
              m('span.inline-flex.items-center.rounded-full.text-xs.font-medium', {
                class: 'px-2 py-0.5 bg-red-200 text-red-800'
              },
              'Account pending'
              )
            ])
          ]),
          m(Avatar, { name: user.name })
        ])
      ])
    }
  }
}
