import { Breadcrumb, Table, Cell, Button, Pagination, BookingStatusBadge, LoadingFullScreen } from 'components'
import { DateTime } from 'luxon'
import { getClientBookings } from 'api/clients'
import { debounce } from 'utils/debounce'

export const ClientHome = () => {
  const filters = { page: 1, search: null, isActive: 'true' }
  let bookings
  let meta
  getClientBookings(filters).then(data => {
    bookings = data.data
    meta = data.meta
  })

  const filterStatus = (val) => {
    filters.isActive = val
    filters.page = 1
    getClientBookings(filters).then(data => {
      bookings = data.data
      meta = data.meta
    })
  }

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    filters.page = 1
    getClientBookings(filters).then(data => {
      bookings = data.data
      meta = data.meta
    })
  }, 300)

  const debouncedSearchReservation = debounce(val => {
    filters.reservationCode = val.length > 2 ? val : null
    filters.page = 1
    getClientBookings(filters).then(data => {
      bookings = data.data
      meta = data.meta
    })
  }, 300)

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Client Dashboard'
          }]
        }),
        m('.my-4.px-4',
          [
            m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
              bookings ? m(Table, {
                cols: [
                  {
                    label: 'Department'
                  },
                  {
                    label: 'Name',
                    search: true,
                    searchPlaceholder: 'Search name or email...',
                    searchOnChange: (val) => {
                      debouncedSearch(val)
                    }
                  },
                  {
                    label: 'Email'
                  },
                  {
                    label: 'Venue Name'
                  },
                  {
                    label: 'Arrival',
                    classes: ['w-32 justify-center']
                  },
                  {
                    label: 'Res Code',
                    classes: ['w-32 justify-center'],
                    search: true,
                    searchOnChange: (val) => {
                      debouncedSearchReservation(val)
                    }
                  },
                  {
                    label: 'Status',
                    classes: ['w-32 justify-center'],
                    filter: true,
                    filterOptions: [
                      { name: 'All', id: '', selected: filters.isActive === '' },
                      { name: 'Active', id: true, selected: filters.isActive === 'true' },
                      { name: 'Closed', id: false, selected: filters.isActive === 'false' }],
                    filterOnChange: (val) => {
                      filterStatus(val)
                    }
                  }
                ],
                filters: true
              },
              [
                m('.overflow-auto.flex-grow',
                  bookings && bookings.length ? bookings.map((v, ix) => {
                    return m(BookingListItem, { key: v.id, item: v })
                  }) : m('p.text-center.p-3.text-gray-400', 'No results')
                ),
                meta && m(Pagination, {
                  meta: meta,
                  buttons: [
                    m('.flex.justify-end', [
                      m(Button, {
                        size: 'sm',
                        classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                        type: 'button',
                        onclick: () => {
                          m.route.set('/clientadmin/search')
                        }
                      }, ['Advanced Search'])
                    ])],
                  onpage: (page) => {
                    filters.page = page
                    getClientBookings(filters).then(data => {
                      bookings = data.data
                      meta = data.meta
                    })
                  }
                })
              ]) : m(LoadingFullScreen)
            )
          ])
      ]
    }
  }
}

const BookingListItem = ({ attrs: { item, classes = [] } }) => {
  const newFormat = { ...DateTime.DATE_SHORT, year: '2-digit' }
  return {
    view () {
      const rowColor = 'bg-white'
      return m('div', {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', rowColor, ...classes].join(' ')

      }, [
        m(Cell, { tight: true }, m('span', item.name)),
        m(Cell, { tight: true, cellClasses: ['tracking-tight'] }, m('span', item.email)),
        m(Cell, { width: 'w-24', tight: true },
          item.departmentName
        ),
        m(Cell, { tight: true, cellClasses: ['tracking-tight'] },
          item.venueName
        ),
        m(Cell, { width: 'w-24', tight: true, cellClasses: ['tracking-tight'] },
          item.venueTownCity ? item.venueTownCity.split(' ').map((word) => {
            return word[0].toUpperCase() + word.substring(1).toLowerCase()
          }).join(' ') : ''
        ),
        m(Cell, { width: 'w-32', align: 'center', tight: true },
          item.reservationCode
        ),
        m(Cell, { width: 'w-24', align: 'center', tight: true },
          DateTime.fromISO(item.arrivalDate).toLocaleString(newFormat)
        ),
        m(Cell, { width: 'w-20', align: 'center', tight: true },
          item.nights
        ),
        m(Cell, { width: 'w-40', align: 'center', tight: true, noEllipsis: 'true' },
          m(BookingStatusBadge, {
            status: item.statusName, size: 'xs', margin: 'm-0'
          }
          )
        )
      ])
    }
  }
}
