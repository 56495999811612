import { Navbar } from './components/Navbar'

import { GuestInformation } from './components/GuestInformation'
import { BookingDetails } from './components/BookingDetails'
import { HotelSelection } from './components/HotelSelection'

import { SubmittedState } from './components/SubmittedState'

import { Select, Link } from 'components'

import Glide from '@glidejs/glide'

import bgImg from 'assets/images/bg.jpg'
import { profile } from 'store/profile'
import { getDelegateDeptInfo, getDelegateVenues } from 'api/delegate'
import { getAllDepartments } from 'api/clients'

import { LogoutDialog } from '../../components/Layout/components/LogoutDialog'

const isFirstLoad = flyd.stream(true)

const Carousel = ({ attrs: { currentStep } }) => {
  let glide
  const currentStepValid = flyd.stream()

  const swipeFromIndex = flyd.stream()
  const swipeFromValid = flyd.stream()
  let rewind = false

  currentStep.map(e => {
    if (glide) {
      glide.go(`=${currentStep()}`)
      localStorage.setItem('currentSlide', currentStep())
      m.redraw()
    }
  })
  return {
    oncreate ({ dom }) {
      glide = new Glide(dom, {
        type: 'slider',
        startAt: currentStep(),
        perView: 3,
        rewind: false,
        focusAt: 'center',
        perTouch: 1,
        keyboard: false,
        peek: 65,
        dragThreshold: false,
        swipeThreshold: false,
        animationDuration: 200,
        breakpoints: {
          400: {
            perView: 1,
            peek: 10
          },
          500: {
            perView: 1,
            peek: 30
          },
          768: {
            perView: 1
          },
          1150: {
            perView: 2,
            peek: 65
          }
        }
      })

      glide.on('run', () => {
        currentStep(glide.index)
        if (dom.parentElement) { dom.parentElement.scroll({ top: 0, left: 0, behavior: 'smooth' }) }
      })
      glide.on('run.after', () => {
        if (rewind) {
          setTimeout(() => {
            glide.go('<')
          }, 100)
          // Can't trust glide run.after
          rewind = false
        }
      })
      glide.on('swipe.start', () => {
        // if going forward && current slide is not valid
        // send back to current
        swipeFromIndex(glide.index)
        swipeFromValid(currentStepValid())
      })
      glide.on('swipe.end', () => {
        if (swipeFromIndex() < glide.index && !swipeFromValid()) {
          rewind = true
        }
      })

      glide.mount()
    },
    onbeforeremove ({ dom }) {
      dom.classList.add('fadeOutFull')
      return new Promise(function (resolve) {
        setTimeout(resolve, 400)
      })
    },
    view ({ attrs: { booking, venues, isSubmitted, departmentInfo } }) {
      return m('.glide.pb-4', {
        class: !isFirstLoad() && 'fadeInFull opacity-0'
      }, [
        m('.glide__track', {
          'data-glide-el': 'track'
        }, [
          m('ul.glide__slides', [
            m('li.glide__slide', [
              m(GuestInformation, { booking, departmentInfo, currentStep, currentStepValid })
            ]),
            m('li.glide__slide', [
              venues && m(BookingDetails, { booking, departmentInfo, venues, currentStep, currentStepValid })
            ]),
            m('li.glide__slide', [
              venues && m(HotelSelection, { booking, departmentInfo, venues, currentStep, currentStepValid, isSubmitted })
            ])
          ])
        ])
      ])
    }
  }
}

export const DelegateBooking = () => {
  const showLogoutDialog = flyd.stream(false)
  let ready = false
  let departmentInfo
  let venues
  let departments

  const currentStep = localStorage.getItem('currentSlide') ? flyd.stream(parseInt(localStorage.getItem('currentSlide'))) : flyd.stream(0)
  let booking = localStorage.getItem('booking') ? JSON.parse(localStorage.getItem('booking')) : {}
  const isSubmitted = flyd.stream(localStorage.getItem('submitted') ? JSON.parse(localStorage.getItem('submitted')) : false)

  booking.userAgentDetails = navigator.userAgent
  isSubmitted.map(time => {
    localStorage.setItem('submitted', time)
  })
  if (profile().delegateDepartmentId || booking.departmentId) {
    getDelegateDeptInfo(booking.departmentId ? { departmentId: booking.departmentId } : null).then(data => {
      departmentInfo = data
      ready = true
    })
    getDelegateVenues(booking.departmentId ? { departmentId: booking.departmentId } : null).then(v => {
      venues = v
    })
  } else {
    getAllDepartments({ pagesize: 1000 }).then(data => {
      departments = data.data.filter(d => d.isActive).map(d => {
        return { value: d.id, label: `${d.clientName !== d.name ? d.clientName + ' - ' : ''}${d.name}` }
      })
    })
  }

  const onclear = () => {
    isSubmitted(false)
    isFirstLoad(true)
    currentStep(0)
    booking = {}
    localStorage.setItem('booking', JSON.stringify(booking))
    localStorage.setItem('currentSlide', currentStep())
    if (!profile().delegateDepartmentId) {
      ready = false
      getAllDepartments({ pagesize: 1000 }).then(data => {
        departments = data.data.map(d => {
          return { value: d.id, label: `${d.clientName !== d.name ? d.clientName + ' - ' : ''}${d.name}` }
        })
      })
    } else {
    }
    m.redraw()
  }

  const setDepartment = (deptId) => {
    isFirstLoad(true)
    booking.departmentId = deptId
    localStorage.setItem('booking', JSON.stringify(booking))
    getDelegateDeptInfo({ departmentId: booking.departmentId }).then(data => {
      departmentInfo = data
      ready = true
      m.redraw()
    })

    getDelegateVenues(booking.departmentId ? { departmentId: booking.departmentId } : null).then(v => {
      venues = v
    })
  }

  return {
    view () {
      localStorage.setItem('booking', JSON.stringify(booking))
      return ready ? m('.delegate-booking.flex.flex-col.h-screen.font-apercu', [
        m(Navbar, { departmentInfo }),
        m('.main.h-full.py-4.overflow-auto', {
          style: {
            backgroundImage: `url(${bgImg})`,
            backgroundSize: 'cover'
          }
        }, [
          isSubmitted()
            ? m(SubmittedState, { isSubmitted, booking, isFirstLoad, onclear })
            : m(Carousel, { booking, departmentInfo, venues, currentStep, isSubmitted })
        ]),
        m('footer.bg-gray-300.fixed.bottom-0.right-0.left-0.flex.justify-between.items-center', [
          m('p.text-xs.ml-3', '© Copyright tobook Limited 2023'),
          m('button', {
            onclick () {
              onclear()
            },
            class: ['block', 'text-left', 'px-4', 'py-1', 'text-sm', 'text-gray-700', 'hover:bg-gray-100'].join(' ')
          }, 'Restart'),
          m('button', {
            onclick () {
              showLogoutDialog(true)
            },
            class: ['block', 'text-left', 'px-4', 'py-1', 'text-sm', 'text-gray-700', 'hover:bg-gray-100'].join(' ')
          }, t('navbar', 'logOut'))
        ]),
        m(LogoutDialog, {
          open: showLogoutDialog
        })
      ]) : m('.main.h-screen.w-full.flex.items-center.justify-center', [
        departments && m('.md:w-1/2.lg:w-1/3', [
          m(Select, {
            label: 'Select a department',
            value: 0,
            choices: departments,
            search: true,
            required: true,
            onchange (e) {
              setDepartment(e)
            }
          }),
          m('.mt-6.text-sm',
            m(Link, {
              href: '/'
            }, 'Back to Dashboard')
          )
        ])
      ])
    }
  }
}
