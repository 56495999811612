
import { Select } from 'components/Select'
import { Dialog2 } from 'components/Dialog2'
import { getDepartmentVenueRates } from 'api/venues'
import { formatMoney } from 'utils'

export const DepartmentVenueRateDialog = ({ attrs: { booking, venues, loading } }) => {
  loading = false
  let rates = []
  const formData = {
    departmentVenueRateId: booking.departmentVenueRateId,
    departmentVenueId: booking.departmentVenue ? booking.departmentVenue.id : null,
    departmentVenueLabel: booking.departmentVenue ? booking.departmentVenue.venue.name : null
  }

  if (booking.departmentVenue) {
    getDepartmentVenueRates(booking.departmentId, booking.departmentVenue.id).then(data => {
      rates = data
    })
  }
  return {
    view ({ attrs: { booking, venues, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          booking.departmentVenueRateId = formData.departmentVenueRateId
          if (booking.departmentVenue && formData.departmentVenueId) {
            booking.departmentVenue.id = formData.departmentVenueId
            booking.departmentVenue.venue.name = formData.departmentVenueLabel
          } else if (formData.departmentVenueId) {
            booking.departmentVenue = { id: formData.departmentVenueId, venue: { name: formData.departmentVenueLabel } }
          }
          onsubmit()
        },
        heading: 'Set venue and rate for booking',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          (booking.venuePreferences && booking.venuePreferences.length > 0) && m('h5.text-sm.text-gray-500', 'Select Delegate Preference:'),
          m('.mt-2.grid.md:grid-cols-2.gap-3', [
            booking.venuePreferences.map(vp => {
              return m('button.hover:bg-gray-200', {
                type: 'submit',
                onclick () {
                  loading = true
                  formData.departmentVenueRateId = vp.departmentVenueRateId
                  booking.departmentVenueRateId = vp.departmentVenueRateId
                }
              }, [
                m('p.text-md', vp.venue.name),
                m('p.text-sm', vp.departmentVenueRate.label),
                m('p.text-sm', formatMoney(vp.departmentVenueRate.tariff, vp.venue.currency.symbol))
              ])
            })
          ]),
          (booking.venuePreferences && booking.venuePreferences.length > 0) && m('h5.text-sm.text-gray-500.mt-2', 'Or Select Venue and Rate:'),
          m(Select, {
            label: 'Venue',
            value: booking.departmentVenue ? booking.departmentVenue.id : '',
            choices: venues.map(dv => {
              return { label: dv.venueName, value: dv.id }
            }),
            onchange (val, label) {
              formData.departmentVenueId = val
              formData.departmentVenueLabel = label
              rates = null

              getDepartmentVenueRates(booking.departmentId, formData.departmentVenueId).then(data => {
                rates = data
              })
            }
          }),
          formData.departmentVenueId && rates && rates.length ? m(Select, {
            label: 'Rate',
            value: '',
            choices: rates.map(dvr => {
              return { label: `${dvr.label} <br> ${formatMoney(dvr.tariff, dvr.currencySymbol)}`, value: dvr.id, disabled: dvr.visibilityId === 1 }
            }),
            onchange (val, label) {
              formData.departmentVenueRateId = val
            }
          }) : null
        ])
      ])
    }
  }
}
