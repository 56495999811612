
export const Table = () => {
  return {
    view ({ children, attrs: { filters, cols, tight, footer } }) {
      return m('.flex.flex-col.w-full.h-full.divide-y.divide-gray-200', [
        m('.flex.flex-shrink-0.bg-gray-200', [
          cols.map(col => {
            return m(".flex.text-xs.font-medium.text-gray-600.uppercase.tracking-wider[scope='col']",
              {
                class: [tight ? 'h-6 px-2 py-1' : 'h-8 px-5 py-2',
                  ...col.classes ? col.classes : ['flex-grow w-0']].join(' ')
              },
              m('span', col.label)
            )
          })
        ]),
        filters && m('.flex.flex-shrink-0.bg-gray-200', [
          cols.map(col => {
            let filterClasses = col.classes ? col.classes.join(' ') : 'flex-grow w-0'
            if (col.filterClasses) {
              filterClasses = col.filterClasses
            }
            return m('.flex.h-7.px-3.py-1.pt-0.text-left',
              { class: filterClasses }, [
                col.search && m('input.py-0.px-3.w-full.text-xs.border-gray-300.bg-gray-100.focus:bg-white', {
                  type: 'text',
                  placeholder: col.searchPlaceholder || 'Search...',
                  oninput: (e) => {
                    col.searchOnChange(e.target.value)
                  }
                }),
                col.filter && col.filterOptions && m('select.py-0.px-3.w-full.text-xs', {
                  onchange: (e) => {
                    col.filterOnChange(e.target.value)
                  }
                }, [
                  col.filterEmptyOption && m('option', { value: '' }, ''),
                  col.filterOptions.map(fo => {
                    return m('option', { value: fo.id, selected: fo.selected }, fo.name)
                  })
                ])
              ]
            )
          })
        ]
        ),
        children,
        footer && m('.flex.flex-shrink-0.bg-gray-200.justify-around.py-2.text-sm', footer)
      ])
    }
  }
}

export const Cell = ({ children, attrs: { width, align, padding, noEllipsis, tight, cellClasses = [] } }) => {
  return {
    view ({ children }) {
      return m('.overflow-hidden.whitespace-nowrap.text-sm.font-medium.text-gray-900',
        {
          class: [
            width || 'flex-grow w-0',
            align ? `text-${align}` : '',
            padding || (tight ? 'px-2 py-1 h-8' : 'px-5 py-3 h-10'),
            noEllipsis ? '' : 'overflow-ellipsis',
            ...cellClasses
          ].join(' ')
        },
        children
      )
    }
  }
}
