import { Breadcrumb } from 'components/Breadcrumb'
import { Button } from 'components/Button'
import { Table } from 'components/Table'
import { Svg } from 'components/Svg'
import { Pagination } from 'components/Pagination'

import { getVenues, getVenueGroups } from 'api/venues'

import { debounce } from 'utils/debounce'

import tickIcon from 'assets/icons/outline/check-circle.svg'
import addIcon from 'assets/icons/outline/plus.svg'

const Venue = ({ attrs: { item, classes = [] } }) => {
  return {
    view () {
      return m(m.route.Link, {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'cursor-pointer', 'bg-white', ...classes].join(' '),
        style: {
          height: '40px'
        },
        href: `/venues/${item.id}`
      },
      [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.truncate.text-sm.font-medium.text-gray-900',
          m('span', item.name)
        ),
        m('flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500',
          m('span', item.groupName)
        ),
        m('flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500',
          m('span', item.townCity)
        ),
        m('flex.w-32.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500.justify-center',
          item.isAccommodation && m(Svg, {
            classes: ['w-5', 'mx-auto']
          }, tickIcon)
        ),
        m('flex.w-32.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500.justify-center',
          item.isMeetings && m(Svg, {
            classes: ['w-5', 'mx-auto']
          }, tickIcon)
        ),
        m('flex.w-32.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500.justify-center',
          item.isS4V && m(Svg, {
            classes: ['w-5', 'mx-auto']
          }, tickIcon)
        ),
        m('.flex.w-32.h-10.px-6.py-3.whitespace-nowrap.justify-center',
          m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
            class: item.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          },
          item.isActive ? 'Active' : 'Closed'
          )
        )
      ]
      )
    }
  }
}

export const Venues = () => {
  const filters = { page: 1, search: null, venueGroupId: null, isActive: true }

  let venues
  let meta
  let venueGroups
  getVenueGroups({ pagesize: 1000 }).then(data => {
    venueGroups = data.data
    getVenues(filters).then(data => {
      venues = data.data
      meta = data.meta
    })
  })

  const filterVenueGroups = (val) => {
    filters.venueGroupId = val
    filters.page = 1
    getVenues(filters).then(data => {
      venues = data.data
      meta = data.meta
    })
  }
  const filterStatus = (val) => {
    filters.isActive = val
    filters.page = 1
    getVenues(filters).then(data => {
      venues = data.data
      meta = data.meta
    })
  }

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    filters.page = 1
    getVenues(filters).then(data => {
      venues = data.data
      meta = data.meta
    })
  }, 300)
  const debouncedSearchTownCity = debounce(val => {
    filters.searchTownCity = val.length > 2 ? val : null
    filters.page = 1
    getVenues(filters).then(data => {
      venues = data.data
      meta = data.meta
    })
  }, 300)

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Venues'
          }],
          buttons: [
            m(Button, {
              size: 'sm',
              classes: ['my-2', 'mx-auto', 'whitespace-nowrap', 'text-white'],
              type: 'button',
              onclick: () => {
                m.route.set('/venues/create')
              }
            }, [m(Svg, {
              classes: ['w-4', 'mx-auto']
            }, [addIcon]), 'Add Venue'])]
        }),
        m('.p-4', { style: { height: 'calc(100% - 5rem)' } }, [
          m('.flex.justify-center', [
            ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '0-9', 'All'].map(letter => {
              return m('a.px-2.py-1', {
                href: '#',
                class: filters.startLetter === letter ? 'bg-gray-400 text-white' : 'hover:text-blue-800 hover:bg-white',
                onclick: (e) => {
                  e.preventDefault()
                  filters.startLetter = letter === 'All' ? '' : letter
                  filters.page = 1
                  getVenues(filters).then(data => {
                    venues = data.data
                    meta = data.meta
                  })
                }
              }, letter)
            })

          ]),
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            venues && m(Table, {
              cols: [
                {
                  label: t('venues', 'venueName'),
                  search: true,
                  searchOnChange: (val) => {
                    debouncedSearch(val)
                  }
                },
                {
                  label: t('venues', 'venueGroup'),
                  filter: true,
                  filterOptions: venueGroups,
                  filterEmptyOption: true,
                  filterOnChange: (val) => {
                    filterVenueGroups(val)
                  }
                },
                {
                  label: 'Town/City',
                  search: true,
                  searchOnChange: (val) => {
                    debouncedSearchTownCity(val)
                  }
                },
                {
                  label: t('app', 'accommodation'),
                  classes: ['w-32 justify-center']
                },
                {
                  label: t('app', 'meetings'),
                  classes: ['w-32 justify-center']
                },
                {
                  label: t('app', 's4v'),
                  classes: ['w-32 justify-center']
                },
                {
                  label: t('venues', 'venueStatus'),
                  classes: ['w-32 justify-center'],
                  filter: true,
                  filterOptions: [
                    { name: 'Active', id: true },
                    { name: 'Closed', id: false }],
                  filterOnChange: (val) => {
                    filterStatus(val)
                  }
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto.flex-grow',
                venues ? venues.map((v, ix) => {
                  return m(Venue, { key: v.id, item: v, classes: [ix % 2 ? 'bg-gray-50' : 'bg-white'] })
                }) : m('p.text-center', 'No results')
              ),
              meta && m(Pagination, {
                meta: meta,
                onpage: (page) => {
                  filters.page = page
                  getVenues(filters).then(data => {
                    venues = data.data
                    meta = data.meta
                  })
                }
              })
            ])
          )
        ])
      ]
    }
  }
}
