import { Breadcrumb, EditForm, EditFormSection, Input, Select, RadioGroup } from 'components'
import { getAllDepartments, getDepartmentVenues } from 'api/clients'
// import { getVenues } from 'api/venues'
import { createInvoiceRun, getInvoiceRunSummary } from 'api/invoices'
import { notification } from 'utils/notifications'

export const CreateInvoiceRun = ({ attrs: { invoiceId } }) => {
  let departments
  let loading = false
  let selectVenue
  let summary
  const ready = true
  const invoiceRunRequest = {
    bookingType: 1
  }

  getAllDepartments({ pagesize: 1000 }).then(data => {
    departments = data.data.filter(d => d.isActive).map(d => {
      return { value: d.id, label: `${d.clientName !== d.name ? d.clientName + ' - ' : ''}${d.name}` }
    })
  })

  const refreshSummary = () => {
    summary = null

    if (invoiceRunRequest.bookingType && invoiceRunRequest.bookingsToDate) {
      getInvoiceRunSummary(invoiceRunRequest).then(data => {
        summary = data
      })
    } else {
      summary = null
    }
  }

  const create = (e) => {
    e.preventDefault()
    loading = true
    getInvoiceRunSummary(invoiceRunRequest).then(data => {
      if (data.numberOfBookings === 0) {
        loading = false
        notification.error({ title: 'No Bookings for entered selection' })
      } else {
        createInvoiceRun(invoiceRunRequest).then(() => {
          m.route.set('/invoices?tab=Invoice Runs')
        })
      }
    })
      .catch(err => {
        loading = false
        notification.error({ title: err.response.Errors })
      })
  }

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Invoices',
            href: '/invoices'
          }, {
            title: 'Create Invoice Run'
          }]
        }),
        ready && m(EditForm, {
          onsubmit: create
        },
        [
          m(EditFormSection, {
            heading: 'Create Invoice Run',
            description: 'Please enter the criteria for this invoice run.',
            loading: loading
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6',
                [
                  m(RadioGroup, {
                    label: 'Booking type',
                    value: invoiceRunRequest.bookingType,
                    required: true,
                    onchange (val) {
                      invoiceRunRequest.bookingType = val
                      refreshSummary()
                    },
                    options: [
                      {
                        label: t('app', 'accommodation'),
                        value: 1
                      },
                      {
                        label: t('app', 'meetings'),
                        value: 2
                      }
                    ]
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-2',
                [
                  m(Input, {
                    label: 'Invoice Date',
                    value: invoiceRunRequest.invoiceDate,
                    type: 'date',
                    required: true,
                    oninput (value) {
                      invoiceRunRequest.invoiceDate = value
                    }
                  })

                ]
              ),
              m('div.col-span-6.md:col-span-2',
                [
                  m(Input, {
                    label: 'Bookings To Date',
                    value: invoiceRunRequest.bookingsToDate,
                    type: 'date',
                    required: true,
                    oninput (value) {
                      invoiceRunRequest.bookingsToDate = value
                      refreshSummary()
                    }
                  })

                ]
              ),
              m('div.col-span-6',
                [
                  m(Input, {
                    label: 'Reference',
                    value: invoiceRunRequest.reference,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      invoiceRunRequest.reference = value
                    }
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  departments && m(Select, {
                    label: 'Select a department',
                    value: invoiceRunRequest.departmentId,
                    choices: departments,
                    search: true,
                    required: false,
                    onchange (val, label) {
                      invoiceRunRequest.departmentId = val
                      if (invoiceRunRequest.departmentId) {
                        getDepartmentVenues(val, { pagesize: 10000 }).then(dv => {
                          const venues = dv.data.map(d => {
                            return {
                              label: d.venueName,
                              value: d.venueId
                            }
                          })
                          selectVenue.setChoices(venues, 'value', 'label', true)
                        })
                      } else {
                        selectVenue.setChoices([], 'value', 'label', true)
                      }
                      refreshSummary()
                    }
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  departments && m(Select, {
                    label: 'Select a venue',
                    value: invoiceRunRequest.venueId,
                    choices: [],
                    search: true,
                    required: false,
                    onchange (val, label) {
                      invoiceRunRequest.venueId = val
                      refreshSummary()
                    },
                    instance (obj) {
                      selectVenue = obj
                    }
                  })
                ]
              ),
              m('div.col-span-6', [
                m('hr'),
                m('p.text-xl.text-gray-500.py-3', 'Invoice Summary'),
                m('div.flex-col', [
                  m('div.grid.grid-cols-3.text-gray-900',
                    m('div.text-lg.font-medium',
                      'Number of Bookings:'
                    ),
                    m('div.text-lg.font-medium',
                      summary ? summary.numberOfBookings : '--'
                    )
                  ),
                  m('div.grid.grid-cols-3.text-gray-900',
                    m('div.text-lg.font-medium',
                      'Number of Invoices:'
                    ),
                    m('div.text-lg.font-medium',
                      summary ? summary.numberOfInvoices : '--'
                    )
                  )
                ])
              ])
            ])
          ])
        ])
      ]
    }
  }
}
