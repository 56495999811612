import { Heading, PageHeading, Breadcrumb, Svg, Link, Cell } from 'components'
import { Activity } from 'components/PastActivityBar/Activity'
import { Card } from 'components/Card'
import { Button } from 'components/Button'
import { DepartmentVenueDialog } from './DepartmentVenueDialog'
import { EmailDialog } from './EmailDialog'
import { NoteDialog } from './NoteDialog'
import { UpdateStatusDialog } from './UpdateStatusDialog'
import { EditRefsDialog } from './EditRefsDialog'
import { EditFeesDialog } from './EditFeesDialog'
import { EditRateDialog } from './EditRateDialog'
import { EditPONumberDialog } from './EditPONumberDialog'
import { BookingStatusBanner, TrueFalseBadge } from 'components/Badge'
import { EmptyState } from 'components/EmptyState'
import { LoadingFullScreen } from 'components/Loading/FullScreen'
import { DateTime } from 'luxon'
import { getMeeting, getMeetingActivity, setMeetingVenue, getMeetingEmailTemplates, sendMeetingEmail as sendEmail, updateMeeting, addMeetingNote, updateMeetingStatus, getMeetingStatuses, deleteMeetingRate, copyMeeting } from 'api/meetings'

import { getDepartment, getDepartmentVenues } from 'api/clients'
import editIcon from 'assets/icons/solid/pencil-alt.svg'
import clientsIcon from 'assets/icons/outline/briefcase.svg'
import departmentsIcon from 'assets/icons/outline/collection.svg'
import phoneIcon from 'assets/icons/solid/phone.svg'
import emailIcon from 'assets/icons/outline/mail.svg'
import faxIcon from 'assets/icons/outline/printer.svg'
import locationIcon from 'assets/icons/solid/location-marker.svg'
import venuesIcon from 'assets/icons/outline/office-building.svg'
import globeIcon from 'assets/icons/solid/globe.svg'
import notesIcon from 'assets/icons/outline/information-circle.svg'
import addNoteIcon from 'assets/icons/outline/annotation.svg'
import copyIcon from 'assets/icons/outline/duplicate.svg'
import cancelIcon from 'assets/icons/outline/x-circle.svg'
import statusIcon from 'assets/icons/outline/fast-forward.svg'
import moreIcon from 'assets/more.svg'
import alertIcon from 'assets/alert-circle.svg'
import commissionIcon from 'assets/icons/outline/chart-pie.svg'
import exLinkIcon from 'assets/icons/solid/external-link.svg'
import cashIcon from 'assets/icons/outline/cash.svg'
// import resCodeIcon from 'assets/icons/outline/terminal.svg'
import poIcon from 'assets/icons/outline/hashtag.svg'
import { notification } from 'utils/notifications'
import { nl2br, formatMoney, formatCommission, timeUntilEmailSent } from 'utils'
import { EmailMessageDialog } from 'pages/Emails/EmailMessageDialog'
import { cancelEmailMessage } from 'api/system'
import { Table } from 'components/Table'

export const ViewMeeting = ({ attrs: { clientId, departmentId, meetingId, tab } }) => {
  let ready
  let ratesReady
  let meeting
  let currency
  let department
  let departmentVenues
  let emailTemplates
  let statuses
  let meetingActivity
  let newStatus
  let templateType
  let sendEmailOnUpdate = false
  let selectedRate

  let savingDepartmentVenue
  const departmentVenueOpen = flyd.stream(false)

  let savingEmail
  const emailOpen = flyd.stream(false)

  let addingNote
  const addNoteOpen = flyd.stream(false)

  let updatingStatus
  const updateStatusOpen = flyd.stream(false)

  let savingRefs
  const editRefsOpen = flyd.stream(false)

  let savingPONumber
  const editPONumberOpen = flyd.stream(false)

  let savingFees
  const editFeesOpen = flyd.stream(false)

  let savingRate
  const editRateOpen = flyd.stream(false)

  const metaItems = []
  Promise.all([
    getDepartment(clientId, departmentId),
    getMeeting(meetingId),
    getMeetingEmailTemplates(meetingId),
    getDepartmentVenues(departmentId, { pagesize: 1000 }),
    getMeetingStatuses()
  ]).then(([d, b, et, dv, s]) => {
    department = d
    meeting = b
    departmentVenues = dv.data
    emailTemplates = et
    statuses = s

    metaItems.push({
      icon: locationIcon,
      label: meeting.name
    })
    metaItems.push({
      icon: phoneIcon,
      label: meeting.telephone
    })
    metaItems.push({
      icon: emailIcon,
      label: meeting.email
    })

    currency = meeting.departmentVenue && meeting.departmentVenue.venue.currency ? meeting.departmentVenue.venue.currency.symbol : '£'
    ready = true
    ratesReady = true
  })

  const RefreshMeetingDetails = (reopen) => {
    savingRate = true
    editRateOpen(false)
    ratesReady = false
    getMeeting(meetingId).then(data => {
      meeting = data
      savingRate = false
      ratesReady = true
      if (reopen) {
        selectedRate = null
        editRateOpen(true)
      }
    }).catch(err => {
      notification.error({ title: err.response })
      savingRate = false
    })
  }

  const setVenue = () => {
    if (meeting.departmentVenueId) {
      savingDepartmentVenue = true
      ratesReady = false

      setMeetingVenue(meeting.id, meeting.departmentVenueId).then(data => {
        meeting = data
        ratesReady = true
        savingDepartmentVenue = false
        currency = meeting.departmentVenue && meeting.departmentVenue.venue.currency ? meeting.departmentVenue.venue.currency.symbol : '£'
        departmentVenueOpen(false)
      }).catch(err => {
        notification.error({ title: err.response })
        savingDepartmentVenue = false
      })
    }
  }

  const sendMeetingEmail = (email) => {
    savingEmail = true
    sendEmail(meeting.id, email).then(data => {
      emailOpen(false)
      savingEmail = false

      getMeetingActivity(meetingId).then(data => {
        meetingActivity = data.data
      })
    }).catch(err => {
      notification.error({ title: err.response })
      savingEmail = false
    })
  }

  const updateStatus = (request) => {
    updatingStatus = true
    updateMeetingStatus(meeting.id, request).then(data => {
      meeting = data
      updateStatusOpen(false)
      updatingStatus = false

      getMeetingActivity(meetingId).then(data => {
        meetingActivity = data.data
      })
    }).catch(err => {
      notification.error({ title: err.response })
      updatingStatus = false
    })
  }

  const updateTheMeeting = () => {
    savingPONumber = true
    savingFees = true
    savingRefs = true
    ready = false
    ratesReady = false
    updateMeeting(meeting.id, meeting).then(data => {
      meeting = data
      savingFees = false
      savingPONumber = false
      savingRefs = false
      ready = true
      ratesReady = true
      editRefsOpen(false)
      editFeesOpen(false)
      editPONumberOpen(false)

      getMeetingActivity(meetingId).then(data => {
        meetingActivity = data.data
      })
    }).catch(err => {
      notification.error({ title: err.response })
      savingFees = false
      savingPONumber = false
      savingRefs = false
    })
  }

  getMeetingActivity(meetingId).then(data => {
    meetingActivity = data.data
  })

  let activityOpen = true

  const addNote = (note) => {
    addingNote = true
    addMeetingNote(meeting.id, note).then(data => {
      meetingActivity = data.data
      addNoteOpen(false)
      addingNote = false
    })
  }

  const copyTheMeeting = () => {
    copyMeeting(meeting.id).then(data => {
      m.route.set(`/clients/${clientId}/departments/${departmentId}/meetings/${data.id}/copy`)
    })
  }

  let currentEmail = {}
  const emailMessageOpen = flyd.stream(false)
  let cancelEmailConfirm = false

  const cancelEmail = (emailMessageId) => {
    cancelEmailConfirm = false
    cancelEmailMessage(emailMessageId).then(() => {
      getMeetingActivity(meetingId).then(data => {
        meetingActivity = data.data
      })
    })
  }

  const RateListItem = ({ attrs: { rate, classes, clientId, departmentId, departmentVenueId, currencySymbol } }) => {
    let deleteRateConfirm = false
    const deleteRate = (meetingId, meetingRateId) => {
      deleteRateConfirm = false
      deleteMeetingRate(meetingId, meetingRateId).then(() => {
        RefreshMeetingDetails()
      }).catch(err => {
        notification.error({ title: err.response })
      })
    }

    return {
      view () {
        return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
          class: classes ? classes.join(' ') : 'bg-white',
          style: {
            height: '40px'
          },
          onclick: () => {
            selectedRate = rate
            editRateOpen(true)
          }
        }, [
          m(Cell, { width: 'grow min-w-32', align: 'left', tight: true },
            rate.label),
          m(Cell, { width: 'w-16', align: 'left', tight: true },
            rate.rateModeString),
          m(Cell, { width: 'w-24', align: 'right', tight: true },
            formatMoney(rate.tariff, currencySymbol, 2)),
          m(Cell, { width: 'w-16', align: 'right', tight: true },
            rate.totalQuantity),
          m(Cell, { width: 'w-24', align: 'right', tight: true },
            formatMoney(rate.totalTariff, currencySymbol, 2)),
          m(Cell, { width: 'w-24', align: 'right', tight: true },
            formatCommission(rate.activeCommissionRate)),
          m(Cell, { width: 'w-20', align: 'right', tight: true },
            formatMoney(rate.commissionAmount, currencySymbol, 2)),
          m(Cell, { width: 'w-24', align: 'left', tight: true, noEllipsis: true },
            m('button.text-red-700.hover:text-red-900.text-xs.flex.items-center.ml-2.focus:bg-red-800.focus:text-red-200', {
              type: 'button',
              onclick (e) {
                if (deleteRateConfirm) { deleteRate(rate.meetingId, rate.id) } else {
                  deleteRateConfirm = true
                  setTimeout(() => {
                    deleteRateConfirm = false
                    m.redraw()
                  }, 3000)
                }
                e.cancelBubble = true
              }
            }, [
              m(Svg, { classes: ['w-6', 'h-6'] }, cancelIcon),
              deleteRateConfirm && m('span.ml-1.pr-2', 'Confirm')
            ]
            )
          )
        ])
      }
    }
  }

  return {
    view ({ attrs: { meetingId, clientId, departmentId, tab } }) {
      return [
        ready && m('div', {
          class: activityOpen ? 'pr-64 xl:pr-72 xxl:pr-96' : 'pr-8'
        }, [
          m(Breadcrumb, {
            links: [{
              title: department.name,
              href: `/clients/${clientId}/departments/${departmentId}`
            }, {
              title: t('app', 'meetings'),
              href: `/clients/${clientId}/departments/${departmentId}?tab=Meetings`
            }, {
              title: `${meeting.description}: ${DateTime.fromISO(meeting.arrivalDate).toLocaleString()}`
            }]
          }),
          m('.flex.flex-wrap.relative.h-full.overflow-x-hidden.content-start', {
            class: meeting.statusName === 'Confirmed' ? 'bg-green-100'
              : meeting.statusName === 'Cancelled' ? 'bg-red-100'
                : 'bg-gray-100'
          }, [
            m('.p-4.w-full', [
              m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', {
              }, [
                m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
                  m(PageHeading, {
                    heading: [meeting.description],
                    subtitle: [m(Svg, { classes: ['w-5', 'h-5'] }, clientsIcon), m(Link, { href: `/clients/${department.clientId}` }, department.clientName), m(Svg, { classes: ['w-5', 'h-5', 'ml-3'] }, departmentsIcon), m(Link, { href: `/clients/${department.clientId}/departments/${department.id}` }, department.name)],
                    metaItems,
                    actions: [
                      meeting.departmentVenue && meeting.statusId === 0 && {
                        icon: emailIcon,
                        label: 'Send Quote Request',
                        action: () => {
                          newStatus = 1
                          templateType = 12
                          sendEmailOnUpdate = true
                          updateStatusOpen(true)
                        }
                      },
                      meeting.departmentVenue && meeting.statusId < 3 && {
                        icon: emailIcon,
                        label: 'Send Quote to Client',
                        action: () => {
                          newStatus = 2
                          templateType = 13
                          sendEmailOnUpdate = true
                          updateStatusOpen(true)
                        }
                      },
                      meeting.departmentVenue && meeting.statusId === 3 && {
                        icon: emailIcon,
                        label: 'Send Confirmation to Client',
                        action: () => {
                          newStatus = 5
                          templateType = 14
                          sendEmailOnUpdate = true
                          updateStatusOpen(true)
                        }
                      },
                      {
                        icon: emailIcon,
                        label: 'Send Email',
                        action: () => {
                          templateType = null
                          emailOpen(true)
                        }
                      },
                      {
                        icon: statusIcon,
                        label: 'Update Status',
                        action: () => {
                          newStatus = meeting.statusId
                          templateType = null
                          sendEmailOnUpdate = false
                          updateStatusOpen(true)
                        }
                      },
                      // {
                      //   icon: resCodeIcon,
                      //   label: 'Set Reservation Code',
                      //   action: () => {
                      //     editRefsOpen(true)
                      //   }
                      // },
                      {
                        icon: poIcon,
                        label: 'Set PO Number',
                        action: () => {
                          editPONumberOpen(true)
                        }
                      },
                      {
                        icon: cashIcon,
                        label: 'Set Commission Fees',
                        action: () => {
                          editFeesOpen(true)
                        }
                      },
                      {
                        icon: editIcon,
                        label: 'Edit Meeting Details',
                        action: () => {
                          m.route.set(`/clients/${clientId}/departments/${departmentId}/meetings/${meetingId}/edit`)
                        }
                      },
                      {
                        icon: copyIcon,
                        label: 'Clone Meeting',
                        action: () => {
                          copyTheMeeting()
                        }
                      },
                      meeting.statusId !== 15 && meeting.statusId !== 20 && {
                        icon: cancelIcon,
                        label: 'Cancel Meeting',
                        color: 'text-red-800',
                        action: () => {
                          newStatus = 15
                          templateType = 16
                          sendEmailOnUpdate = true
                          updateStatusOpen(true)
                        }
                      }
                    ],
                    timestamps: [
                      {
                        title: 'Created',
                        value: meeting.createdDate
                      },
                      {
                        title: 'Last Action',
                        value: meeting.lastActionDate
                      }
                    ]
                  })
                ]),
                m(BookingStatusBanner, { status: meeting.statusName, size: 'xl' }),
                m('div.px-4.py-5.sm:p-6', {
                  class: 'bg-gray-50'
                }, [
                  m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-6', [
                    meeting && meeting.invoiceId
                      ? m('.sm:col-span-2.lg:col-span-6', [
                        m(Card, m('.space-y-6.divide-y.divide-gray-200', [
                          m('.flex.w-full', [
                            m('.flex-2', [
                              m('h5.text-sm.text-gray-500', 'Invoice'),
                              m('h3.text-lg.xl:text-xl', m(Link, { href: `/invoices/${meeting.invoiceId}` }, `${meeting.invoiceNumber} - ${DateTime.fromISO(meeting.invoiceDate).toLocaleString(DateTime.DATE_FULL)}`))
                            ])
                          ])
                        ]))
                      ]) : null,
                    m('.sm:col-span-2.lg:col-span-6', [
                      m(Card, m('.space-y-6', [
                        m('.flex.w-full', [
                          m('.flex-2', [
                            m('h5.text-sm.text-gray-500', 'Arrival'),
                            m('h3.text-lg.xl:text-xl', DateTime.fromISO(meeting.arrivalDate).toLocaleString(DateTime.DATE_HUGE))
                          ]),
                          m('.flex-1', [
                            m('h5.text-sm.text-gray-500', 'Duration'),
                            m('h4.xl:text-lg', `${meeting.days} Day${(meeting.days > 1 ? 's' : '')}`)
                          ]),
                          m('.flex-1', [
                            m('h5.text-sm.text-gray-500', 'No of Delegates'),
                            m('h4.xl:text-lg', `${meeting.noOfDelegates} Delegate${(meeting.noOfDelegates > 1 ? 's' : '')}`)
                          ])
                        ]),
                        m('.flex.w-full.flex-start', [
                          m('div', [
                            m('h5.text-sm.text-gray-500', 'Start Time'),
                            m('h4.xl:text-lg', meeting.startTime)
                          ]),
                          m('.ml-6', [
                            m('h5.text-sm.text-gray-500', 'Finish'),
                            m('h4.xl:text-lg', meeting.endTime)
                          ])
                        ])
                      ]))
                    ]),
                    m('div.lg:col-span-3.xl:col-span-4.space-y-2', [
                      m(Card, {
                        buttons: [
                          m(Button, {
                            type: 'button',
                            size: 'sm',
                            variant: meeting.departmentVenue ? 'plain' : 'info',
                            icon: venuesIcon,
                            onclick () {
                              departmentVenueOpen(true)
                            }
                          }, meeting.departmentVenue ? 'Change Venue' : 'Set Venue')
                        ]
                      }, m('.space-y-6.divide-y.divide-gray-200', [
                        meeting.departmentVenue && meeting.departmentVenue.venue ? m('div.space-y-1', [
                          m('h5.text-sm.text-gray-500', 'Selected Venue'),
                          m('.flex.gap-3', [
                            m(Svg, { classes: ['w-8', 'h-8'] }, venuesIcon),
                            m('h3.text-2xl', m(Link, { href: `/venues/${meeting.departmentVenue.venue.id}` }, meeting.departmentVenue.venue.name))
                          ]),
                          m('.flex.gap-x-2.text-gray-600.flex-wrap.leading-tight', [
                            m(Svg, { classes: ['w-4', 'h-4'] }, locationIcon),
                            meeting.departmentVenue.venue.addressLine1 && m('span', meeting.departmentVenue.venue.addressLine1 + ','),
                            meeting.departmentVenue.venue.townCity && m('span', meeting.departmentVenue.venue.townCity + ','),
                            meeting.departmentVenue.venue.county && m('span', meeting.departmentVenue.venue.county + ','),
                            meeting.departmentVenue.venue.postCode && m('span', meeting.departmentVenue.venue.postCode),
                            meeting.departmentVenue.venue.country && m('span', meeting.departmentVenue.venue.country.code)
                          ]),
                          m('.flex.gap-2.text-gray-500.text-sm.flex-wrap.leading-tight', [
                            m(Svg, { classes: ['w-4', 'h-4'] }, phoneIcon),
                            meeting.departmentVenue.venue.telephone,
                            meeting.departmentVenue.venue.fax && m(Svg, { classes: ['w-4', 'h-4', 'ml-3'] }, faxIcon),
                            meeting.departmentVenue.venue.fax && meeting.departmentVenue.venue.fax,
                            meeting.departmentVenue.venue.website && m(Svg, { classes: ['w-4', 'h-4', 'ml-3'] }, globeIcon),
                            meeting.departmentVenue.venue.website && m('a.hover:underline', {
                              href: meeting.departmentVenue.venue.website,
                              options: { target: '_blank' }
                            }, [
                              'Website',
                              m(Svg, { classes: ['w-4', 'h-4', 'inline', 'ml-1'] }, exLinkIcon)
                            ])
                          ]),
                          m('.flex.gap-2.text-gray-500.text-sm.leading-tight', [
                            m(Svg, { classes: ['w-4', 'h-4'] }, commissionIcon),
                            m('p', meeting.isCommissionFree ? [m('span.line-through.mr-2.text-red-600', formatCommission(meeting.departmentVenue.commission)), `Commission free: ${meeting.commissionFreeNotes}`] : formatCommission(meeting.departmentVenue.commission))
                          ]),
                          meeting.departmentVenue.venue.notes && m('.flex.gap-2.text-gray-600.pt-3.text-sm', [
                            m(Svg, { classes: ['w-4', 'h-4'] }, notesIcon),
                            m('span', meeting.departmentVenue.venue.notes)
                          ])

                        ]) : m('div.space-y-1', [
                          m('h5.text-sm.text-gray-500', 'No venue selected')
                        ]),
                        meeting.departmentVenue && meeting.departmentVenue.venue.accommodation && meeting.departmentVenue.venue.accommodation.cancellationPolicy && m('div.space-y-1.pt-3', [
                          m('h5.text-sm.text-gray-500', 'Cancellation Policy'),
                          m('p', meeting.departmentVenue.venue.accommodation.cancellationPolicy)
                        ])
                      ]))
                    ]),
                    m('div.lg:col-span-3.xl:col-span-2.space-y-2', [
                      meeting.reservationCode || meeting.isPONumberRequired || meeting.poNumber || meeting.statusId === 15
                        ? m(Card, m('.space-y-2.divide-y.divide-gray-200', [
                          meeting.statusId === 15 && m('.flex.justify-between.items-center', [
                            m('h5.text-sm.text-gray-500', 'Cancellation Fee Payable?'),
                            m(TrueFalseBadge, { trueFalse: meeting.isCancellationFeePayable })
                          ]),
                          (meeting.reservationCode || meeting.isPONumberRequired || meeting.poNumber) && m('div', {
                            class: meeting.statusId === 15 ? 'pt-2' : ''
                          }, [
                            meeting.reservationCode && m('h5.text-sm.text-gray-500', 'Reservation Code'),
                            meeting.reservationCode && m('p.text-lg', meeting.reservationCode),
                            (meeting.isPONumberRequired || meeting.poNumber) && m('h5.text-sm.text-gray-500', 'PO Number'),
                            (meeting.isPONumberRequired || meeting.poNumber) && (meeting.poNumber ? m('p.text-lg', meeting.poNumber) : m('p.text-orange-400.uppercase.text-xs', 'Not set'))
                          ])
                        ])) : null,
                      meeting.eventRef
                        ? m(Card, m('.space-y-2.divide-y.divide-gray-200', [
                          m('.pt-2', [
                            m('h5.text-sm.text-gray-500', 'tobook Ref'),
                            m('p.text-lg', meeting.eventRef)

                          ])
                        ])) : null
                    ]),
                    m('.sm:col-span-2.lg:col-span-6', [
                      m(Card, {}, [
                        m('.flex.w-full.justify-between', [
                          m('div', [
                            m('h5.text-sm.text-gray-500', 'Room Name'),
                            m('p', meeting.roomName)

                          ]),
                          m('div', [
                            m('h5.text-sm.text-gray-500', 'Layout'),
                            m('p', meeting.layout)

                          ]),
                          m('div', [
                            m('h5.text-sm.text-gray-500', 'AV Required'),
                            m('p', meeting.isAVRequired ? 'Yes' : 'No')

                          ])
                        ])
                      ])
                    ]),
                    m('.sm:col-span-2.lg:col-span-6', [
                      m(Card, {}, [
                        m('.space-y-6.divide-y.divide-gray-200', [
                          m('div', [
                            m('h5.text-sm.text-gray-500', 'Notes'),
                            m('p', m.trust(nl2br(meeting.notes ? meeting.notes : '')))

                          ])
                        ])
                      ])
                    ]),
                    m('.sm:col-span-2.lg:col-span-6', [
                      m(Card, {
                        buttons: [
                          m(Button, {
                            type: 'button',
                            size: 'sm',
                            variant: 'info',
                            icon: editIcon,
                            onclick () {
                              selectedRate = null
                              editRateOpen(true)
                            }
                          }, 'Add Rates')
                        ]
                      }, m('.space-y-6.divide-y.divide-gray-200', [
                        m('div.space-y-1.pt-3', [
                          m('h5.text-sm.text-gray-500', 'Rates'),
                          m(Table, {
                            tight: true,
                            cols: [
                              {
                                label: 'Rate',
                                classes: ['grow min-w-32 justify-start']
                              },
                              {
                                label: 'Type',
                                classes: ['w-16 justify-start']
                              },
                              {
                                label: 'Tariff',
                                classes: ['w-24 justify-end']
                              },
                              {
                                label: 'Qty',
                                classes: ['w-16 justify-end']
                              },
                              {
                                label: 'Total',
                                classes: ['w-24 justify-end']
                              },
                              {
                                classes: ['w-20 justify-end']
                              },
                              {
                                label: 'Commission',
                                classes: ['w-24 justify-end']
                              },
                              // Delete button
                              {
                                classes: ['w-24']
                              }
                            ],
                            filters: false
                          },
                          [
                            ratesReady && meeting && meeting.meetingRates && m('.overflow-auto',
                              meeting.meetingRates.map((v, ix) => {
                                return m(RateListItem, { rate: v, clientId: clientId, departmentId: departmentId, departmentVenueId: meeting.departmentVenueId, currencySymbol: meeting.departmentVenue ? meeting.departmentVenue.venue.currency.symbol : '£' })
                              })
                            )
                          ])
                        ]),
                        m('.flex.w-96.pt-2', [
                          m('.flex-1', [
                            m('h5.text-sm.text-gray-500', 'Total Tariff'),
                            m('p.text-xl', formatMoney(meeting.totalTariff, currency, 2))
                          ]),
                          m('.flex-1', [
                            m('h5.text-sm.text-gray-500', 'Total Commission'),
                            m('p.text-xl', formatMoney(meeting.totalCommission, currency, 2))
                          ])
                        ])
                      ])
                      )
                    ]),
                    m('.sm:col-span-2.lg:col-span-6', [
                      m(Card, {
                        // buttons: [
                        //   m(Button, {
                        //     type: 'button',
                        //     size: 'sm',
                        //     variant: 'info',
                        //     icon: editIcon,
                        //     onclick () {
                        //       departmentVenueOpen(true)
                        //     }
                        //   }, 'Edit Requirements')
                        // ]
                      },
                      m('.space-y-6.divide-y.divide-gray-200', [
                        m('div', [
                          m('h5.text-sm.text-gray-500', 'Requirements'),
                          m('p', m.trust(nl2br(meeting.requirementsInfo ? meeting.requirementsInfo : '')))

                        ])
                      ]))
                    ]),
                    m('.sm:col-span-2.lg:col-span-6', [
                      m(Card, {
                        // buttons: [
                        //   m(Button, {
                        //     type: 'button',
                        //     size: 'sm',
                        //     variant: 'info',
                        //     icon: editIcon,
                        //     onclick () {
                        //       departmentVenueOpen(true)
                        //     }
                        //   }, 'Edit Quote Details')
                        // ]
                      }, m('.space-y-6.divide-y.divide-gray-200', [
                        m('div', [
                          m('h5.text-sm.text-gray-500', 'Quote Details'),
                          m('p', m.trust(nl2br(meeting.quoteInfo ? meeting.quoteInfo : '')))

                        ])
                      ]))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        m('.fixed.right-0.bottom-0.z-30.bg-white.flex.shadow-lg.activity-initial', {
          style: 'top: 63px',
          class: activityOpen ? 'w-64 xl:w-72 xxl:w-96 activity-slide-in' : 'w-8 activity-slide-in'
        }, [
          m('.w-8.flex.h-100.items-center.justify-center.cursor-pointer.flex-shrink-0.border-r', {
            onclick () {
              activityOpen = !activityOpen
            }
          }, [
            m(Svg, {
              classes: ['w-5', 'transform', 'rotate-90']
            }, moreIcon)
          ]),
          activityOpen && m('.w-full.flex.flex-col', [
            m('.flex.justify-between.items-end.p-3', [
              m(Heading, { level: 3 }, 'Activity'),
              m(Button, {
                type: 'button',
                size: 'xs',
                icon: addNoteIcon,
                onclick () {
                  addNoteOpen(true)
                }
              }, 'Add Note')
            ]),
            meetingActivity
              ? meetingActivity.length
                ? m('.flex.flex-col.border-t.h-full.overflow-auto', meetingActivity.map(ea => {
                  return [
                    ea.emailMessage && m('div.p-1.flex.justify-start.items-center', {
                      class: ea.emailMessage.emailMessageStatusId === 0 ? 'bg-orange-300 text-orange-800' : ea.emailMessage.emailMessageStatusId === 1 ? 'bg-green-300 text-green-800' : 'bg-red-300 text-red-800'
                    }, [
                      m('button.hover:text-black', {
                        type: 'button',
                        onclick () {
                          currentEmail = ea.emailMessage
                          emailMessageOpen(true)
                        }
                      }, m(Svg, { classes: ['w-6', 'h-6', 'mx-1'] }, emailIcon)
                      ),
                      m('span.text-sm', ea.emailMessage.statusName),
                      ea.emailMessage.emailMessageStatusId !== 3 && m('span.text-xs.flex-grow.text-right', [
                        ea.emailMessage.sentDate && DateTime.fromISO(ea.emailMessage.sentDate).toLocaleString(DateTime.DATETIME_MED),
                        !ea.emailMessage.sentDate && timeUntilEmailSent(ea.emailMessage.createdDate, ea.emailMessage.delay * 60)
                      ]),
                      ea.emailMessage.emailMessageStatusId === 0 && m('button.text-red-700.hover:text-red-900.text-xs.flex.items-center.ml-2.focus:bg-red-800.focus:text-red-200', {
                        type: 'button',
                        onclick () {
                          if (cancelEmailConfirm) { cancelEmail(ea.emailMessage.id) } else {
                            cancelEmailConfirm = true
                            setTimeout(() => {
                              cancelEmailConfirm = false
                              m.redraw()
                            }, 3000)
                          }
                        }
                      }, [
                        m(Svg, { classes: ['w-6', 'h-6'] }, cancelIcon),
                        cancelEmailConfirm && m('span.ml-1.px-1', 'Click again to confirm')
                      ]
                      )
                    ]),
                    ea.isModelUpdate ? '' : m(Activity, { activity: ea })]
                }))
                : m(EmptyState, { icon: alertIcon, message: t('activityBar', 'noActivity') })
              : m(LoadingFullScreen)
          ])
        ]),
        departmentVenueOpen() &&
          m(DepartmentVenueDialog, {
            open: departmentVenueOpen,
            onsubmit: setVenue,
            loading: savingDepartmentVenue,
            meeting: meeting,
            venues: departmentVenues
          }),
        emailOpen() &&
          m(EmailDialog, {
            open: emailOpen,
            onsubmit: sendMeetingEmail,
            loading: savingEmail,
            meeting: meeting,
            templates: emailTemplates,
            templateType: templateType
          }),
        updateStatusOpen() &&
          m(UpdateStatusDialog, {
            open: updateStatusOpen,
            onsubmit: updateStatus,
            loading: updatingStatus,
            meeting: meeting,
            templates: emailTemplates,
            statuses,
            statusId: newStatus,
            templateType: templateType,
            sendEmail: sendEmailOnUpdate
          }),
        addNoteOpen() &&
          m(NoteDialog, {
            open: addNoteOpen,
            onsubmit: addNote,
            loading: addingNote,
            meeting: meeting,
            templates: emailTemplates
          }),
        editRefsOpen() &&
          m(EditRefsDialog, {
            open: editRefsOpen,
            onsubmit: updateTheMeeting,
            loading: savingRefs,
            meeting: meeting
          }),
        editPONumberOpen() &&
          m(EditPONumberDialog, {
            open: editPONumberOpen,
            onsubmit: updateTheMeeting,
            loading: savingPONumber,
            meeting: meeting
          }),
        editFeesOpen() &&
          m(EditFeesDialog, {
            open: editFeesOpen,
            onsubmit: updateTheMeeting,
            loading: savingFees,
            meeting: meeting
          }),
        editRateOpen() &&
          m(EditRateDialog, {
            open: editRateOpen,
            onsubmit: RefreshMeetingDetails,
            loading: savingRate,
            meeting: meeting,
            meetingRate: selectedRate
          }),
        emailMessageOpen() &&
          m(EmailMessageDialog, {
            open: emailMessageOpen,
            email: currentEmail
          })
      ]
    }
  }
}
