import { Input, Cell } from 'components'
import { Select } from 'components/Select'
import { RadioGroup } from 'components/RadioGroup'
import { Breadcrumb } from 'components/Breadcrumb'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { Svg } from 'components/Svg'
import { Button } from 'components/Button'
import { Table } from 'components/Table'
import { getVenues, getVenue, getDepartmentVenue, updateDepartmentVenue, createDepartmentVenue, getDepartmentVenueRates, getDepartmentVenueMeetingRates, deleteDepartmentVenueMeetingRate, getDepartmentVenueNotes } from 'api/venues'
import { getDepartment, getAllDepartments } from 'api/clients'
import { notification } from 'utils/notifications'
import addIcon from 'assets/icons/outline/plus.svg'
import tickIcon from 'assets/icons/outline/check-circle.svg'
import cancelIcon from 'assets/icons/outline/x-circle.svg'
import { formatMoney, formatCommission } from 'utils'

export const EditDepartmentVenue = ({ attrs: { clientId, departmentId, venueId, departmentVenueId } }) => {
  let ready = false
  const vis = [{ label: 'Public', value: 3, desc: 'Available to delegates for booking' }, { label: 'Private', value: 2, desc: 'Available to admin for booking' }, { label: 'Closed', value: 1, desc: 'Not available for booking' }]
  let venues = []
  let departments = []
  let departmentVenue = {}
  let department
  let venue
  let departmentVenueRates
  let departmentVenueMeetingRates
  let departmentVenueNotes

  // This screen can be called from the department or from the Venue.
  // When routed through the department the venueId will be zero
  // When routed through the venue the clientId will be zero

  if (venueId) {
    getVenue(venueId).then(data => {
      venue = data
    })
  }
  if (clientId && departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
    })
  }

  if (departmentVenueId > 0) {
    getDepartmentVenue(departmentId, departmentVenueId).then(dv => {
      departmentVenue = dv
      dv.commissionPercent = (dv.commission * 100).toFixed(2)
      ready = true
    })

    getDepartmentVenueRates(departmentId, departmentVenueId).then(data => {
      departmentVenueRates = data
    })

    getDepartmentVenueMeetingRates(departmentId, departmentVenueId).then(data => {
      departmentVenueMeetingRates = data
    })

    getDepartmentVenueNotes(departmentId, departmentVenueId).then(data => {
      departmentVenueNotes = data
    })
  } else {
    if (venueId) {
      getAllDepartments({ pagesize: 1000 }).then(data => {
        // eslint-disable-next-line no-unused-vars
        departments = data
        departmentVenue = { venueId: venueId, meetingVisibilityId: 1, accommodationVisibilityId: 3 }
        ready = true
      })
    } else {
      getVenues({ pagesize: 10000 }).then(data => {
        // eslint-disable-next-line no-unused-vars
        venues = data
        departmentVenue = { departmentId: departmentId, meetingVisibilityId: 1, accommodationVisibilityId: 3 }
        ready = true
      })
    }
  }

  const deleteMeetingRate = (meetingRateId) => {
    deleteDepartmentVenueMeetingRate(departmentVenue.department.id, departmentVenueId, meetingRateId).then(() => {
      notification.success({ title: 'meeting rate deleted' })
      getDepartmentVenueMeetingRates(departmentVenue.department.id, departmentVenueId).then(data => {
        departmentVenueMeetingRates = data
      })
    }).catch(err => {
      notification.error({ title: err.response })
    })
  }

  const createUpdateDepartmentVenue = (e) => {
    e.preventDefault()
    const departmentVenueRequest = {
      id: departmentVenue.id || 0,
      venueId: departmentVenue.venue ? departmentVenue.venue.id : departmentVenue.venueId,
      departmentId: departmentVenue.department ? departmentVenue.department.id : departmentVenue.departmentId,
      accommodationVisibilityId: departmentVenue.accommodationVisibilityId,
      meetingVisibilityId: departmentVenue.meetingVisibilityId,
      commission: departmentVenue.commission
    }
    if (departmentVenue.id) {
      updateDepartmentVenue(departmentVenueRequest.departmentId, departmentVenueRequest).then(() => {
        notification.success({ title: 'Updated' })
        // Respect the initial routing (from venue or department)
        if (venueId) {
          m.route.set(`/venues/${departmentVenueRequest.venueId}?tab=Departments`)
        } else {
          m.route.set(`/clients/${departmentVenueRequest.clientId}/departments/${departmentVenueRequest.departmentId}?tab=Venues`)
        }
      }).catch(err => {
        console.log(err)
        notification.error({ title: err.response })
      })
    } else {
      createDepartmentVenue(departmentVenueRequest.departmentId, departmentVenueRequest).then(d => {
        notification.success({ title: 'Created' })
        // Respect the initial routing (from venue or department)
        if (venueId) {
          m.route.set(`/venues/${departmentVenueRequest.venueId}/departments/${departmentVenueRequest.departmentId}/${d.id}`)
        } else {
          m.route.set(`/clients/${departmentVenueRequest.clientId}/departments/${departmentVenueRequest.departmentId}/venues/${d.id}`)
        }
      }).catch(err => {
        console.log(err)
        notification.error({ title: err.response })
      })
    }
  }
  return {
    view ({ attrs: { clientId, departmentId, venueId, departmentVenueId } }) {
      return m('div', [
        department && m(Breadcrumb, {
          links: [{
            title: 'Clients',
            href: '/clients'
          }, {
            title: department.clientName,
            href: `/clients/${clientId}`
          }, {
            title: 'Departments',
            href: `/clients/${clientId}?tab=Departments`
          }, {
            title: department.name,
            href: `/clients/${clientId}/departments/${departmentId}`
          }, {
            title: 'Venues',
            href: `/clients/${clientId}/departments/${departmentId}?tab=Venues`
          }, {
            title: departmentVenueId ? 'Edit' : 'Add'
          }]
        }),
        venue && m(Breadcrumb, {
          links: [{
            title: 'Venues',
            href: '/venues'
          }, {
            title: venue.name,
            href: `/venues/${venueId}`
          }, {
            title: 'Departments',
            href: `/venues/${venueId}?tab=Departments`
          }, {
            title: departmentVenueId ? 'Edit' : 'Add'
          }]
        }),
        ready && m(EditForm, {
          onsubmit: createUpdateDepartmentVenue
        },
        [
          m(EditFormSection, {
            heading: departmentVenueId ? 'Edit Department Venue' : 'Add Department Venue',
            description: 'Please enter the settings for the venue at this department'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6',
                [
                  departmentVenueId || venueId ? m(Input, {
                    label: 'Venue',
                    value: departmentVenueId ? departmentVenue.venue.name : venue.name,
                    type: 'readonly'
                  }) : m(Select, {
                    label: 'Select Venue',
                    search: true,
                    value: departmentVenue.venueId,
                    required: true,
                    onchange (e) {
                      departmentVenue.venueId = e
                    },
                    choices: venues.data.map(d => {
                      return { label: `${d.name} ${(d.isActive ? '' : '[CLOSED]')}`, value: d.id, isActive: d.isActive }
                    })
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  departmentVenueId || departmentId ? m(Input, {
                    label: 'Department',
                    value: departmentVenueId ? departmentVenue.department.name : department.name,
                    type: 'readonly'
                  }) : m(Select, {
                    label: 'Select Department',
                    search: true,
                    value: departmentVenue.departmentId,
                    required: true,
                    onchange (e) {
                      departmentVenue.departmentId = e
                    },
                    choices: departments.data.map(d => {
                      return { label: `${d.clientName} > ${d.name} ${(d.isActive ? '' : '[CLOSED]')}`, value: d.id, isActive: d.isActive }
                    })
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-3.lg:col-span-2.flex.items-end.gap-2',
                [
                  m(Input, {
                    label: 'Commission',
                    value: departmentVenue.commissionPercent,
                    type: 'number',
                    min: 0,
                    step: '.01',
                    required: true,
                    placeholder: 'e.g. 8.00',
                    oninput (value) {
                      departmentVenue.commissionPercent = value
                      departmentVenue.commission = (value / 100).toFixed(4)
                    }
                  }),
                  m('.mb-2', '%')
                ]
              ),
              m('div.col-span-6.md:col-span-3.lg:col-span-2'),
              m('div.col-span-6.lg:col-span-3',
                [
                  m(RadioGroup, {
                    label: 'Accommodation Visibility',
                    value: departmentVenue.accommodationVisibilityId,
                    required: true,
                    options: vis,
                    name: 'accommodationVisibilityId',
                    onchange (value) {
                      departmentVenue.accommodationVisibilityId = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.lg:col-span-3',
                [
                  m(RadioGroup, {
                    label: 'Meetings Visibility',
                    value: departmentVenue.meetingVisibilityId,
                    required: true,
                    options: vis,
                    name: 'meetingVisibilityId',
                    onchange (value) {
                      departmentVenue.meetingVisibilityId = value
                    }
                  })
                ]
              )
            ])
          ]),
          departmentVenueRates ? m(EditFormSection, {
            heading: 'Accommodation Rates',
            description: 'Please enter the accommodation rates for the venue at this department',
            buttons: [
              m(Button, {
                size: 'sm',
                classes: ['whitespace-nowrap', 'text-white'],
                type: 'button',
                onclick: () => {
                  if (venueId) {
                    m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}/rates/create`)
                  } else {
                    m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}/rates/create`)
                  }
                }
              }, [
                m(Svg, {
                  classes: ['w-4', 'mx-auto']
                }, [addIcon]),
                'Add accommodation rate'
              ])
            ]
          }, [
            m(Table, {
              cols: [
                {
                  label: 'Rate'
                },
                {
                  label: 'Tariff'
                },
                {
                  label: 'Visibility'
                }
              ],
              filters: false
            },
            [
              m('.overflow-auto',
                departmentVenueRates.map((v, ix) => {
                  return m(RateListItem, { item: v, clientId: clientId, departmentId: departmentId, departmentVenueId: departmentVenueId, currencySymbol: departmentVenue.venue.currency.symbol })
                })
              )
            ])
          ]) : m(EditFormSection, {
            heading: 'Accommodation Rates',
            description: 'Please enter the accommodation rates for the venue at this department',
            buttons: []
          }, [
            m('p', 'Please save the new department venue first to enable adding the rates')
          ]),
          departmentVenueMeetingRates && m(EditFormSection, {
            heading: 'Meeting Rates',
            description: 'Please enter the meeting rates for the venue at this department',
            buttons: [
              m(Button, {
                size: 'sm',
                classes: ['whitespace-nowrap', 'text-white'],
                type: 'button',
                onclick: () => {
                  if (venueId) {
                    m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}/meetingrates/create`)
                  } else {
                    m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}/meetingrates/create`)
                  }
                }
              }, [
                m(Svg, {
                  classes: ['w-4', 'mx-auto']
                }, [addIcon]),
                'Add meeting rate'
              ])
            ]
          }, [
            m(Table, {
              tight: true,
              cols: [
                {
                  label: 'Rate'
                },
                {
                  label: 'Type',
                  classes: ['w-36 justify-start']
                },
                {
                  label: 'Tariff',
                  classes: ['w-36 justify-end']
                },
                {
                  label: 'Override',
                  classes: ['w-36 justify-center']
                },
                {
                  label: 'Commission',
                  classes: ['w-36 justify-end']
                },
                // Delete button
                {
                  classes: ['w-24']
                }
              ],
              filters: false
            },
            [
              m('.overflow-auto',
                departmentVenueMeetingRates.map((v, ix) => {
                  return m(MeetingRateListItem, { rate: v, clientId: clientId, departmentId: departmentId, departmentVenueId: departmentVenueId, currencySymbol: departmentVenue.venue.currency.symbol, onDelete: deleteMeetingRate })
                })
              )
            ])
          ]),
          departmentVenueMeetingRates ? null : m(EditFormSection, {
            heading: 'Meeting Rates',
            description: 'Please enter the meeting rates for the venue at this department',
            buttons: []
          }, [
            m('p', 'Please save the new department venue first to enable adding the rates')
          ]),
          departmentVenueNotes && m(EditFormSection, {
            heading: 'Notes',
            description: 'Please enter notes for the venue at this department',
            buttons: [
              m(Button, {
                size: 'sm',
                classes: ['whitespace-nowrap', 'text-white'],
                type: 'button',
                onclick: () => {
                  if (venueId) {
                    m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}/notes/create`)
                  } else {
                    m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}/notes/create`)
                  }
                }
              }, [
                m(Svg, {
                  classes: ['w-4', 'mx-auto']
                }, [addIcon]),
                'Add note'
              ])
            ]
          }, [
            m(Table, {
              cols: [
                {
                  label: 'Note'
                },
                {
                  label: t('app', 'accommodation'),
                  classes: ['w-32 justify-center']
                },
                {
                  label: t('app', 'meetings'),
                  classes: ['w-32 justify-center']
                }
              ],
              filters: false
            },
            [
              m('.overflow-auto',
                departmentVenueNotes.map((v, ix) => {
                  return m(NoteListItem, { item: v, clientId: clientId, departmentId: departmentId, departmentVenueId: departmentVenueId })
                })
              )
            ])
          ])
        ])
      ])
    }
  }
}

const RateListItem = ({ attrs: { item, classes, clientId, departmentId, venueId, departmentVenueId, currencySymbol } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          if (venueId) {
            m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}/rates/${item.id}`)
          } else {
            m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}/rates/${item.id}`)
          }
        }
      }, [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.label)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', `${currencySymbol}${item.tariff}`)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.visibilityName)
        )
      ])
    }
  }
}

const MeetingRateListItem = ({ attrs: { rate, classes, clientId, departmentId, venueId, departmentVenueId, currencySymbol, onDelete } }) => {
  let deleteRateConfirm = false
  const deleteRate = (meetingRateId) => {
    deleteRateConfirm = false
    onDelete(meetingRateId)
  }

  return {

    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          if (venueId) {
            m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}/meetingrates/${rate.id}`)
          } else {
            m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}/meetingrates/${rate.id}`)
          }
        }
      }, [
        m(Cell, { align: 'left', tight: true }, rate.label),
        m(Cell, { width: 'w-36', align: 'left', tight: true }, rate.rateModeString),
        m(Cell, { width: 'w-36', align: 'right', tight: true }, formatMoney(rate.tariff, currencySymbol, 2)),
        m(Cell, { width: 'w-36', align: 'center', tight: true }, rate.overrideCommission ? m(Svg, {
          classes: ['w-5', 'mx-auto']
        }, tickIcon) : ''),
        m(Cell, { width: 'w-36', align: 'right', tight: true }, formatCommission(rate.activeCommissionRate, 2)),
        m(Cell, { width: 'w-24', align: 'left', tight: true, noEllipsis: true },
          m('button.text-red-700.hover:text-red-900.text-xs.flex.items-center.ml-2.focus:bg-red-800.focus:text-red-200', {
            type: 'button',
            onclick (e) {
              if (deleteRateConfirm) { deleteRate(rate.id) } else {
                deleteRateConfirm = true
                setTimeout(() => {
                  deleteRateConfirm = false
                  m.redraw()
                }, 3000)
              }
              e.cancelBubble = true
            }
          }, [
            m(Svg, { classes: ['w-6', 'h-6'] }, cancelIcon),
            deleteRateConfirm && m('span.ml-1.pr-2', 'Confirm')
          ]
          )
        )
      ])
    }
  }
}

const NoteListItem = ({ attrs: { item, classes, clientId, venueId, departmentId, departmentVenueId } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          if (venueId) {
            m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}/notes/${item.id}`)
          } else {
            m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}/notes/${item.id}`)
          }
        }
      }, [
        m('.flex-grow.overflow-hidden.overflow-ellipsis.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.body)
        ),
        m('.flex.w-32.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500.justify-center',
          item.isAccommodation && m(Svg, {
            classes: ['w-5', 'mx-auto']
          }, tickIcon)
        ),
        m('.flex.w-32.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500.justify-center',
          item.isMeetings && m(Svg, {
            classes: ['w-5', 'mx-auto']
          }, tickIcon)
        )
      ])
    }
  }
}
