import { Breadcrumb } from 'components/Breadcrumb'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { Input } from 'components/Input'
import { TextArea } from 'components/TextArea'
import { Checkbox } from 'components/Checkbox'
import { notification } from 'utils/notifications'

import { getClient, updateClient, createClient } from 'api/clients'

export const EditClient = ({ attrs: { clientId } }) => {
  let client
  let ready

  if (clientId) {
    getClient(clientId).then(data => {
      client = data
      ready = true
    })
  } else {
    client = { name: '', isActive: true }
    ready = true
  }

  const update = (e) => {
    e.preventDefault()
    if (clientId) {
      updateClient(client).then(() => {
        notification.success({ title: t('clients', 'updated') })
        m.route.set(`/clients/${clientId}`)
      })
    } else {
      createClient(client).then(res => {
        notification.success({ title: t('clients', 'created') })
        m.route.set(`/clients/${res.id}`)
      })
    }
  }

  return {
    view ({ attrs: { clientId } }) {
      return [
        ready && m(Breadcrumb, {
          links: clientId
            ? [{
              title: 'Clients',
              href: '/clients'
            }, {
              title: client.name,
              href: `/clients/${clientId}`
            }, {
              title: 'Edit'
            }]
            : [{
              title: 'Clients',
              href: '/clients'
            }, {
              title: 'Create'
            }]
        }),
        ready && m(EditForm, {
          onsubmit: update
        },
        [
          m(EditFormSection, {
            heading: 'Edit Client',
            description: 'Update the client details'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'Name',
                    value: client.name,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      client.name = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-6',
                [
                  m(TextArea, {
                    classes: ['w-full'],
                    label: 'Description',
                    value: client.description,
                    oninput (e) {
                      client.description = e
                    }
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(Checkbox, {
                    label: 'Active',
                    checked: client.isActive,
                    onchange () {
                      client.isActive = !client.isActive
                    }
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(TextArea, {
                    classes: ['w-full'],
                    label: 'Notes',
                    value: client.notes,
                    oninput (e) {
                      client.notes = e
                    }
                  })
                ]
              )
            ])
          ]),
          m(EditFormSection, {
            heading: 'Contact details',
            description: 'Address and phone number'
          }, [
            m('.grid.grid-cols-6.gap-y-6.gap-x-3',
              [
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: t('createVenue', 'addressLine1'),
                      value: client.addressLine1,
                      type: 'text',
                      oninput (value) {
                        client.addressLine1 = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: t('createVenue', 'addressLine2'),
                      value: client.addressLine2,
                      type: 'text',
                      oninput (value) {
                        client.addressLine2 = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'town'),
                      value: client.townCity,
                      type: 'text',
                      oninput (value) {
                        client.townCity = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'county'),
                      value: client.county,
                      type: 'text',
                      oninput (value) {
                        client.county = value
                      }
                    })
                  ]
                ),
                m('.hidden.md:block.md:col-span-2'),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'postCode'),
                      value: client.postCode,
                      type: 'text',
                      oninput (value) {
                        client.postCode = value
                      }
                    })
                  ]
                )
              ]
            ),
            m('.pt-6.grid.grid-cols-6.gap-6', [
              m('.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'Telephone',
                    value: client.telephone,
                    type: 'tel',
                    oninput (value) {
                      client.telephone = value
                    }
                  })
                ]
              ),
              m('.col-span-0.md:col-span-3'),
              m('.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'PO Contact Name',
                    value: client.poNumberContactName,
                    type: 'text',
                    oninput (value) {
                      client.poNumberContactName = value
                    }
                  })
                ]
              ),
              m('.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'PO Contact Email',
                    value: client.poNumberContactEmail,
                    type: 'email',
                    oninput (value) {
                      client.poNumberContactEmail = value
                    }
                  })
                ]
              )
            ])
          ])
        ])
      ]
    }
  }
}
