import { Breadcrumb } from 'components/Breadcrumb'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { Input } from 'components/Input'
import { TextArea } from 'components/TextArea'
import { Select } from 'components/Select'
import { Checkbox } from 'components/Checkbox'
import { getVenue, updateVenue, getContacts } from 'api/venues'
import { notification } from 'utils/notifications'
import { getCountries, getVenueGroups, getCurrencies } from 'api/system'
import { trimStrings } from 'utils'

export const EditVenue = ({ attrs: { venueId } }) => {
  let ready
  let venue
  let countries = []
  let currencies = []
  let venueGroups = []
  let contacts = []

  Promise.all([
    getCountries(),
    getCurrencies(),
    getVenueGroups(),
    getVenue(venueId),
    getContacts(venueId)
  ])
    .then(([cs, cu, vg, v, vc]) => {
      countries = cs
      currencies = cu
      venueGroups = vg
      venue = v
      contacts = vc
      ready = true
    })

  const update = (e) => {
    e.preventDefault()
    trimStrings(venue)
    updateVenue(venue).then(() => {
      notification.success({ title: t('venues', 'updated') })
      m.route.set(`/venues/${venueId}`)
    })
  }

  return {
    view () {
      return [
        ready && m(Breadcrumb, {
          links: [{
            title: 'Venues',
            href: '/venues'
          }, {
            title: venue.name,
            href: `/venues/${venueId}`
          }, {
            title: 'Edit'
          }]
        }),
        ready && m(EditForm, {
          onsubmit: update
        },
        [
          m(EditFormSection, {
            heading: 'Edit Venue',
            description: 'Update the venue name and group'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: t('createVenue', 'venueName'),
                    value: venue.name,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      venue.name = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.lg:col-span-3',
                [
                  m(Input, {
                    label: 'Alt Name',
                    value: venue.altName,
                    type: 'text',
                    oninput (value) {
                      venue.altName = value
                    }
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(Checkbox, {
                    label: 'Active',
                    checked: venue.isActive,
                    onchange () {
                      venue.isActive = !venue.isActive
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-5',
                [
                  m(Select, {
                    label: t('createVenue', 'venueGroup'),
                    search: false,
                    value: venue.venueGroupId,
                    onchange (e) {
                      venue.venueGroupId = e
                    },
                    choices: venueGroups
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-3.xl:col-span-4',
                [
                  m(Input, {
                    label: 'Website',
                    value: venue.website,
                    type: 'url',
                    oninput (value) {
                      venue.website = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-3.xl:col-span-2',
                [
                  m(Select, {
                    label: t('venues', 'currency'),
                    search: false,
                    required: true,
                    value: venue.currencyId,
                    onchange (e) {
                      venue.currencyId = e
                    },
                    choices: currencies
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(TextArea, {
                    classes: ['w-full'],
                    label: t('venues', 'notes'),
                    value: venue.notes,
                    oninput (e) {
                      venue.notes = e
                    }
                  })
                ]
              )
            ])
          ]),
          m(EditFormSection, {
            heading: 'Contact details',
            description: 'Address and phone number'
          }, [
            m('.grid.grid-cols-6.gap-y-6.gap-x-3',
              [
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: t('createVenue', 'addressLine1'),
                      value: venue.addressLine1,
                      type: 'text',
                      oninput (value) {
                        venue.addressLine1 = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: t('createVenue', 'addressLine2'),
                      value: venue.addressLine2,
                      type: 'text',
                      oninput (value) {
                        venue.addressLine2 = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'town'),
                      value: venue.townCity,
                      type: 'text',
                      oninput (value) {
                        venue.townCity = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'county'),
                      value: venue.county,
                      type: 'text',
                      oninput (value) {
                        venue.county = value
                      }
                    })
                  ]
                ),
                m('.hidden.md:block.md:col-span-2'),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'postCode'),
                      value: venue.postCode,
                      type: 'text',
                      oninput (value) {
                        venue.postCode = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Select, {
                      label: t('createVenue', 'country'),
                      search: true,
                      value: venue.countryCode,
                      strValue: true,
                      onchange (e) {
                        venue.countryCode = e
                      },
                      choices: countries
                    })
                  ]
                )
              ]
            ),
            m('.pt-6.grid.grid-cols-6.gap-6', [
              m('.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'Telephone',
                    value: venue.telephone,
                    type: 'tel',
                    oninput (value) {
                      venue.telephone = value
                    }
                  })
                ]
              ),
              m('.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'Fax',
                    value: venue.fax,
                    type: 'tel',
                    oninput (value) {
                      venue.fax = value
                    }
                  })
                ]
              )
            ]),
            m('.pt-6.grid.grid-cols-6.gap-6', [
              m('.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'Billing Name',
                    value: venue.billingContactName,
                    type: 'text',
                    oninput (value) {
                      venue.billingContactName = value
                    }
                  })
                ]
              ),
              m('.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'Billing Email',
                    value: venue.billingEmail,
                    type: 'email',
                    oninput (value) {
                      venue.billingEmail = value
                    }
                  })
                ]
              )
            ])
          ]),
          m(EditFormSection, {
            heading: t('app', 'accommodation'),
            description: 'Accommodation settings'
          }, [
            m('.grid.grid-cols-6.gap-y-6.gap-x-3',
              [

                m('div.col-span-6',
                  [
                    m(Checkbox, {
                      label: 'Active',
                      checked: venue.isAccommodation,
                      onchange () {
                        venue.isAccommodation = !venue.isAccommodation
                      }
                    })
                  ]
                )
              ]),
            venue.isAccommodation && m('.grid.grid-cols-6.gap-y-6.gap-x-3',
              [
                m('.col-span-6.md:col-span-5.mt-3',
                  [
                    m(Input, {
                      label: 'Website',
                      value: venue.accommodation ? venue.accommodation.website : '',
                      type: 'url',
                      oninput (value) {
                        if (!venue.accommodation) {
                          venue.accommodation = {}
                        }
                        venue.accommodation.website = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: 'Check-in',
                      value: venue.accommodation ? venue.accommodation.checkIn : '',
                      type: 'text',
                      oninput (value) {
                        if (!venue.accommodation) {
                          venue.accommodation = {}
                        }
                        venue.accommodation.checkIn = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: 'Check-out',
                      value: venue.accommodation ? venue.accommodation.checkOut : '',
                      type: 'text',
                      oninput (value) {
                        if (!venue.accommodation) {
                          venue.accommodation = {}
                        }
                        venue.accommodation.checkOut = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: 'Cancellation Policy',
                      value: venue.accommodation ? venue.accommodation.cancellationPolicy : '',
                      type: 'text',
                      oninput (value) {
                        if (!venue.accommodation) {
                          venue.accommodation = {}
                        }
                        venue.accommodation.cancellationPolicy = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Select, {
                      label: 'Reservations Contact',
                      search: true,
                      value: venue.accommodation ? venue.accommodation.reservationsContactId : '',
                      onchange (e) {
                        if (!venue.accommodation) {
                          venue.accommodation = {}
                        }
                        venue.accommodation.reservationsContactId = e
                      },
                      choices: contacts.map(c => {
                        return { label: `${c.firstName} ${c.lastName} [${c.email}]`, value: c.id }
                      })
                    })
                  ]
                )
              ]
            )
          ]),
          m(EditFormSection, {
            heading: t('app', 'meetings'),
            description: 'Meetings settings'
          }, [
            m('.grid.grid-cols-6.gap-y-6.gap-x-3',
              [

                m('div.col-span-6',
                  [
                    m(Checkbox, {
                      label: 'Active',
                      checked: venue.isMeetings,
                      onchange () {
                        venue.isMeetings = !venue.isMeetings
                      }
                    })
                  ]
                )
              ]),
            venue.isMeetings && m('.grid.grid-cols-6.gap-y-6.gap-x-3',
              [
                m('.col-span-6.md:col-span-5.mt-3',
                  [
                    m(Input, {
                      label: 'Website',
                      value: venue.meetings ? venue.meetings.website : '',
                      type: 'url',
                      oninput (value) {
                        if (!venue.meetings) {
                          venue.meetings = {}
                        }
                        venue.meetings.website = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: 'MaxOccupancy',
                      value: venue.meetings ? venue.meetings.maxOccupancy : '',
                      type: 'number',
                      oninput (value) {
                        if (!venue.meetings) {
                          venue.meetings = {}
                        }
                        venue.meetings.maxOccupancy = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: 'Cancellation Policy',
                      value: venue.meetings ? venue.meetings.cancellationPolicy : '',
                      type: 'text',
                      oninput (value) {
                        if (!venue.meetings) {
                          venue.meetings = {}
                        }
                        venue.meetings.cancellationPolicy = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Select, {
                      label: 'Meetings Contact',
                      search: true,
                      value: venue.meetings ? venue.meetings.conferenceOfficeContactId : '',
                      onchange (e) {
                        if (!venue.meetings) {
                          venue.meetings = {}
                        }
                        venue.meetings.conferenceOfficeContactId = e
                      },
                      choices: contacts.map(c => {
                        return { label: `${c.firstName} ${c.lastName} [${c.email}]`, value: c.id }
                      })
                    })
                  ]
                )
              ]
            )
          ])
        ])

      ]
    }
  }
}
