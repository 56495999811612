
import { Input, Checkbox, TextArea, Dialog2 } from 'components'
// import { getDepartmentVenueRates } from 'api/venues'

export const EditBookingRateDialog = ({ attrs: { booking, bookingRate, loading } }) => {
  loading = false

  const formData = {
    label: bookingRate ? bookingRate.label : '',
    tariff: bookingRate ? bookingRate.tariff : '',
    isCommissionFree: booking.isCommissionFree,
    commissionFreeNotes: booking.commissionFreeNotes

  }

  return {
    view ({ attrs: { booking, bookingRate, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          bookingRate.label = formData.label
          bookingRate.tariff = formData.tariff
          booking.isCommissionFree = formData.isCommissionFree
          booking.commissionFreeNotes = formData.commissionFreeNotes
          onsubmit()
        },
        heading: 'Edit rate',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          m(Input, {
            label: 'Venue',
            value: booking.departmentVenue ? booking.departmentVenue.venue.name : '',
            type: 'readonly'
          }),
          // bookingRate && rates && rates.length && m(Select, {
          //   label: 'Rate',
          //   value: bookingRate ? bookingRate.departmentVenueRateId : 0,
          //   choices: rates.map(dvr => {
          //     return { label: dvr.label, value: dvr.id }
          //   }),
          //   onchange (val, label) {
          //     bookingRate.departmentVenueRateId = parseInt(val)
          //     const rate = rates.find(r => {
          //       return r.id === bookingRate.departmentVenueRateId
          //     })
          //     bookingRate.tariff = rate.tariff
          //     bookingRate.label = rate.label
          //   }
          // }),
          m(Input, {
            label: 'Rate',
            value: bookingRate ? bookingRate.departmentVenueRate.label : '',
            type: 'readonly'
          }),
          m(Input, {
            label: 'Rate Label',
            value: formData.label,
            type: 'text',
            required: true,
            oninput (value) {
              formData.label = value
            }
          }),
          m(Input, {
            label: 'Tariff',
            value: formData.tariff,
            type: 'number',
            min: 0,
            step: '.01',
            required: true,
            oninput (value) {
              formData.tariff = value
            }
          }),
          m(Checkbox, {
            label: 'Commission Free?',
            checked: formData.isCommissionFree,
            onchange () {
              formData.isCommissionFree = !formData.isCommissionFree
            }
          }),
          formData.isCommissionFree && m(TextArea, {
            label: 'Commission Free Details',
            value: formData.commissionFreeNotes,
            rows: 3,
            oninput (value) {
              formData.commissionFreeNotes = value
            }
          })
        ])
      ])
    }
  }
}
