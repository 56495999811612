import { Card } from 'components/Card'
import { Heading } from 'components/Heading'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { register } from 'api/auth'
import { schema } from 'validators/register'
import { validate, getError, displayErrors } from 'validators/utils'
import { t } from '../../../translations'

const formData = {

}

export const Register = () => {
  let errors = []
  let serverErrors
  let loading
  const registerUser = (data) => {
    const validation = validate(schema, data)
    if (validation) {
      errors = validation
    } else {
      errors = []
      serverErrors = []
      loading = true
      register(data)
        .then(res => {
          loading = false
          m.route.set('/login')
        })
        .catch(err => {
          loading = false
          serverErrors = err.response.Errors
        })
    }
  }

  return {
    view () {
      return m('.h-full.flex.justify-center.items-center', [
        m(Card, {
          classes: ['w-4/5', 'max-w-md']
        }, [
          m('.p-6', [
            m(Heading, t('register', 'title')),
            m('form', {
              onsubmit (e) {
                e.preventDefault()
                registerUser(formData)
              }
            }, [
              m(Input, {
                label: t('register', 'email'),
                value: formData.email,
                type: 'email',
                errorMessage: getError(errors, 'email'),
                oninput (val) {
                  formData.email = val
                }
              }),
              m(Input, {
                label: t('register', 'password'),
                value: formData.password,
                type: 'password',
                errorMessage: getError(errors, 'password'),
                oninput (val) {
                  formData.password = val
                }
              }),
              displayErrors(serverErrors),
              m('.flex.justify-between.items-center', [
                m(Link, {
                  classes: ['text-orange-900', 'underline'],
                  href: '/login',
                  options: { replace: true }
                }, t('register', 'logInLink')),
                m(Button, {
                  variant: 'positive',
                  loading
                }, t('register', 'action'))
              ])
            ])
          ])
        ])
      ])
    }
  }
}
