
import { Checkbox, TextArea } from 'components'
import { Dialog2 } from 'components/Dialog2'

export const EditFeesDialog = ({ attrs: { meeting, loading } }) => {
  loading = false
  const formData = {
    isCommissionFree: meeting.isCommissionFree,
    commissionFreeNotes: meeting.commissionFreeNotes,
    isCancellationFeePayable: meeting.isCancellationFeePayable
  }

  return {
    view ({ attrs: { meeting, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          meeting.isCommissionFree = formData.isCommissionFree
          meeting.commissionFreeNotes = formData.commissionFreeNotes
          meeting.isCancellationFeePayable = formData.isCancellationFeePayable
          onsubmit()
        },
        heading: 'Edit fees',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          m(Checkbox, {
            label: 'Commission Free?',
            checked: formData.isCommissionFree,
            onchange () {
              formData.isCommissionFree = !formData.isCommissionFree
            }
          }),
          formData.isCommissionFree && m(TextArea, {
            label: 'Commission Free Notes',
            value: formData.commissionFreeNotes,
            rows: 4,
            oninput (value) {
              formData.commissionFreeNotes = value
            }
          }),
          m(Checkbox, {
            label: 'Charge Cancellation Fee?',
            checked: formData.isCancellationFeePayable,
            onchange () {
              formData.isCancellationFeePayable = !formData.isCancellationFeePayable
            }
          })
        ])
      ])
    }
  }
}
