import { Breadcrumb } from 'components'
import { Table } from 'components/Table'
import { Pagination } from 'components/Pagination'

import { getAllDepartments } from 'api/clients'

import { debounce } from 'utils/debounce'

const Department = ({ attrs: { item, classes = [] } }) => {
  return {
    view () {
      return m(m.route.Link, {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'cursor-pointer', 'bg-white', ...classes].join(' '),
        style: {
          height: '40px'
        },
        href: `/clients/${item.clientId}/departments/${item.id}`
      },
      [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.name)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.clientName)
        ),
        m('.flex.w-32.h-10.px-6.py-3.whitespace-nowrap.justify-center',
          m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
            class: item.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          },
          item.isActive ? 'Active' : 'Closed'
          )
        )
      ]
      )
    }
  }
}

export const Departments = () => {
  const filters = { page: 1, search: null, isActive: true }

  let departments
  let meta

  getAllDepartments(filters).then(data => {
    departments = data.data
    meta = data.meta
  })

  const filterStatus = (val) => {
    filters.isActive = val
    getAllDepartments(filters).then(data => {
      departments = data.data
      meta = data.meta
    })
  }

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    getAllDepartments(filters).then(data => {
      departments = data.data
      meta = data.meta
    })
  }, 300)

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Departments'
          }],
          buttons: [
          ]
        }),
        m('.p-4', { style: { height: 'calc(100% - 3rem)' } }, [
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            departments && m(Table, {
              cols: [
                {
                  label: t('clients', 'name'),
                  search: true,
                  searchOnChange: (val) => {
                    debouncedSearch(val)
                  }
                },
                {
                  label: 'Client'
                },
                {
                  label: t('clients', 'status'),
                  classes: ['w-32 justify-center'],
                  filter: true,
                  filterOptions: [
                    { name: 'Active', id: true },
                    { name: 'Closed', id: false }],
                  filterOnChange: (val) => {
                    filterStatus(val)
                  }
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto',
                departments.map((v, ix) => {
                  return m(Department, { key: v.id, item: v, classes: [ix % 2 ? 'bg-gray-50' : 'bg-white'] })
                })
              ),
              meta && m(Pagination, {
                meta: meta,
                onpage: (page) => {
                  filters.page = page
                  getAllDepartments(filters).then(data => {
                    departments = data.data
                    meta = data.meta
                  })
                }
              })
            ])
          )
        ])
      ]
    }
  }
}
