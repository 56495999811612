import { request } from './request'

export const getUsers = (params) => {
  return request({
    url: 'account/users',
    method: 'GET',
    params: {
      pagesize: 25,
      ...params
    }
  })
}
export const getAdminUsers = (params) => {
  return request({
    url: 'account/admin-users',
    method: 'GET',
    params: {
      pagesize: 25,
      ...params
    }
  })
}
export const getUser = (id) => {
  return request({
    url: `account/users/${id}`,
    method: 'GET'
  })
}

export const updateUser = (id, user) => {
  return request({
    url: `account/users/${id}`,
    method: 'PUT',
    body: user
  })
}

export const addAdminUser = (user) => {
  return request({
    url: 'account/admin-users',
    method: 'POST',
    params: {
      site: process.env.APP_URL
    },
    body: user
  })
}

export const addDelegateUser = (user) => {
  return request({
    url: 'account/delegate-users',
    method: 'POST',
    body: user
  })
}

export const addClientUser = (user) => {
  return request({
    url: 'account/client-users',
    method: 'POST',
    params: {
      site: process.env.APP_URL
    },
    body: user
  })
}

export const addDepartmentUser = (user) => {
  return request({
    url: 'account/department-users',
    method: 'POST',
    params: {
      site: process.env.APP_URL
    },
    body: user
  })
}

export const updateDelegateUser = (id, user) => {
  return request({
    url: `account/delegate-users/${id}`,
    method: 'PUT',
    body: user
  })
}
