import { Radio } from 'components/Radio'

export const RadioGroup = () => {
  return {
    view ({ attrs: { label, value, options, name, required, errorMessage, onchange } }) {
      return m('.fieldset', [
        m('legend.text-base.font-medium.text-gray-900', label),
        m('.mt-4.space-y-4', [
          options.map(o => {
            return m(Radio, {
              label: o.label,
              value: o.value,
              desc: o.desc,
              name,
              checked: o.value === value,
              required,
              onchange
            })
          })
        ]),
        m('.text-red-600.text-sm.ml-10', errorMessage)
      ])
    }
  }
}
