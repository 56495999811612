import { Breadcrumb, Button, Checkbox, Input, EditForm, EditFormSection, TinyMce } from 'components'
import { notification } from 'utils/notifications'
import { getDepartment, getDepartmentBillingInstruction, updateDepartmentBillingInstruction, createDepartmentBillingInstruction, deleteDepartmentBillingInstruction } from 'api/clients'

export const EditDepartmentBillingInstruction = ({ attrs: { clientId, departmentId, billingInstructionId } }) => {
  let department
  let billingInstruction
  let ready

  if (departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
      if (billingInstructionId) {
        getDepartmentBillingInstruction(departmentId, billingInstructionId)
          .then(res => {
            billingInstruction = res
            ready = true
          })
      } else {
        billingInstruction = { description: null, guestInstructions: null, venueInstructions: null, isPONumberRequired: false }
        ready = true
      }
    })
  }

  const createUpdateBillingInstruction = (e) => {
    e.preventDefault()
    if (billingInstructionId) {
      updateDepartmentBillingInstruction(billingInstruction.departmentId, billingInstruction)
        .then(res => {
          notification.success({ title: 'Billing Instruction saved' })
          m.route.set(`/clients/${clientId}/departments/${departmentId}?tab=Billing Instructions`)
        })
        .catch(() => {
          notification.error({ title: 'Error. Billing Instruction not saved' })
        })
    } else {
      createDepartmentBillingInstruction(departmentId, billingInstruction)
        .then(res => {
          notification.success({ title: 'Billing Instruction saved' })
          m.route.set(`/clients/${clientId}/departments/${departmentId}?tab=Billing Instructions`)
        })
        .catch(() => {
          notification.error({ title: 'Error. Billing Instruction not saved' })
        })
    }
  }

  const deleteBillingInstruction = () => {
    deleteDepartmentBillingInstruction(billingInstruction.departmentId, billingInstruction)
      .then(res => {
        notification.success({ title: 'Billing Instruction deleted' })
        m.route.set(`/clients/${clientId}/departments/${departmentId}?tab=Billing Instructions`)
      })
      .catch(() => {
        notification.error({ title: 'Error. Billing Instruction not deleted' })
      })
  }

  return {
    view () {
      return [ready && m(Breadcrumb, {
        links: [{
          title: 'Clients',
          href: '/clients'
        }, {
          title: department.clientName,
          href: `/clients/${clientId}`
        }, {
          title: 'Departments',
          href: `/clients/${clientId}?tab=Departments`
        }, {
          title: department.name,
          href: `/clients/${clientId}/departments/${departmentId}`
        }, {
          title: 'billing',
          href: `/clients/${clientId}/departments/${departmentId}?tab=Billing Instructions`
        }, {
          title: billingInstructionId ? billingInstruction.description : 'Add Billing Instruction'
        }]
      }),
      ready && m(EditForm, {
        onsubmit: createUpdateBillingInstruction
      }, [
        m(EditFormSection, {
          heading: billingInstructionId ? 'Edit Billing Instruction' : 'Add Billing Instruction',
          description: 'Please enter the Instructions for this department',
          buttons: [m(Button, {
            type: 'button',
            variant: 'warning',
            onclick () {
              history.back()
            }
          }, t('app', 'cancel')),
          billingInstructionId
            ? m(Button, {
              type: 'button',
              variant: 'danger',
              onclick () {
                deleteBillingInstruction()
              }
            }, 'Delete') : null,
          m(Button, {
            type: 'submit',
            variant: 'positive'
          }, t('app', 'save'))],
          buttonJustify: 'justify-between'
        }, [
          m('div.grid.grid-cols-6.gap-6', [
            m('div.col-span-6.md:col-span-4',
              [
                m(Input, {
                  label: 'Description',
                  value: billingInstruction.description,
                  required: true,
                  oninput (val) {
                    billingInstruction.description = val
                  }
                })]
            ),
            m('.col-span-6.flex.flex-col', [
              m(TinyMce, {
                value: billingInstruction.guestInstructions,
                label: 'Guest Billing Instructions',
                height: 200,
                onchange: (content) => {
                  billingInstruction.guestInstructions = content
                }
              })
            ]),
            m('.col-span-6.flex.flex-col', [
              m(TinyMce, {
                value: billingInstruction.venueInstructions,
                label: 'Venue Billing Instructions',
                height: 200,
                onchange: (content) => {
                  billingInstruction.venueInstructions = content
                }
              })
            ]),
            m('div.col-span-6',
              [
                m(Checkbox, {
                  label: 'PO Number Required?',
                  checked: billingInstruction.isPONumberRequired,
                  onchange () {
                    billingInstruction.isPONumberRequired = !billingInstruction.isPONumberRequired
                  }
                })
              ]
            )
          ])
        ]
        )
      ])
      ]
    }
  }
}
