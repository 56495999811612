import { request } from './request'

export const getVenues = params => {
  return request({
    url: 'venues',
    params: {
      pagesize: 25,
      ...params
    },
    method: 'GET'
  })
}

export const getVenue = (id) => {
  return request({
    url: `venues/${id}`,
    method: 'GET'
  })
    .then(v => {
      return {
        ...v,
        currencyId: v.currency ? v.currency.id : 1,
        countryCode: v.country ? v.country.code : null,
        venueGroupId: v.venueGroup ? v.venueGroup.id : null
      }
    })
}

export const updateVenue = data => {
  return request({
    url: `venues/${data.id}`,
    method: 'PUT',
    body: data
  })
}

export const createVenue = data => {
  return request({
    url: 'venues',
    method: 'POST',
    body: data
  })
}

export const getVenueActivity = id => {
  return request({
    url: `venues/${id}/activity`,
    method: 'GET'
  })
}

export const getContacts = id => {
  return request({
    url: `venues/${id}/contacts`,
    method: 'GET'
  })
}

export const getContact = (venueId, id) => {
  return request({
    url: `venues/${venueId}/contacts/${id}`,
    method: 'GET'
  })
}

export const createContact = (venueId, contact) => {
  return request({
    url: `venues/${venueId}/contacts`,
    method: 'POST',
    body: contact
  })
}

export const updateContact = (venueId, contact) => {
  return request({
    url: `venues/${venueId}/contacts/${contact.id}`,
    method: 'PUT',
    body: contact
  })
}

export const deleteContact = (venueId, contactId) => {
  return request({
    url: `venues/${venueId}/contacts/${contactId}`,
    method: 'DELETE'
  })
}

export const getVenueGroups = (params) => {
  return request({
    url: 'venues/groups',
    params: {
      pagesize: 25,
      ...params
    }
  })
}
export const getVenueGroup = (id) => {
  return request({
    url: `venues/groups/${id}`,
    method: 'GET'
  })
}

export const updateVenueGroup = (venueGroup) => {
  return request({
    url: `venues/groups/${venueGroup.id}`,
    method: 'PUT',
    body: venueGroup
  })
}

export const createVenueGroup = (venueGroup) => {
  return request({
    url: 'venues/groups',
    method: 'POST',
    body: venueGroup
  })
}

export const getDepartments = id => {
  return request({
    url: `venues/${id}/departments`,
    method: 'GET'
  })
}

export const getDepartmentVenue = (deptId, id) => {
  return request({
    url: `departments/${deptId}/venues/${id}`,
    method: 'GET'
  })
}

export const createDepartmentVenue = (deptId, departmentVenue) => {
  return request({
    url: `departments/${deptId}/venues`,
    method: 'POST',
    body: departmentVenue
  })
}

export const updateDepartmentVenue = (deptId, departmentVenue) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenue.id}`,
    method: 'PUT',
    body: departmentVenue
  })
}

export const getDepartmentVenueRates = (deptId, departmentVenueId) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/rates`,
    method: 'GET'
  })
}

export const getDepartmentVenueRate = (deptId, departmentVenueId, id) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/rates/${id}`,
    method: 'GET'
  })
}

export const createDepartmentVenueRate = (deptId, departmentVenueId, rate) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/rates`,
    method: 'POST',
    body: rate
  })
}

export const updateDepartmentVenueRate = (deptId, departmentVenueId, id, rate) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/rates/${id}`,
    method: 'PUT',
    body: rate
  })
}

export const getDepartmentVenueMeetingRates = (deptId, departmentVenueId) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/meetingrates`,
    method: 'GET'
  })
}

export const getDepartmentVenueMeetingRate = (deptId, departmentVenueId, id) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/meetingrates/${id}`,
    method: 'GET'
  })
}

export const createDepartmentVenueMeetingRate = (deptId, departmentVenueId, rate) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/meetingrates`,
    method: 'POST',
    body: rate
  })
}

export const updateDepartmentVenueMeetingRate = (deptId, departmentVenueId, id, rate) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/meetingrates/${id}`,
    method: 'PUT',
    body: rate
  })
}

export const deleteDepartmentVenueMeetingRate = (deptId, departmentVenueId, id) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/meetingrates/${id}`,
    method: 'DELETE'
  })
}

export const getDepartmentVenueNotes = (deptId, departmentVenueId) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/notes`,
    method: 'GET'
  })
}

export const getDepartmentVenueNote = (deptId, departmentVenueId, id) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/notes/${id}`,
    method: 'GET'
  })
}

export const createDepartmentVenueNote = (deptId, departmentVenueId, note) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/notes`,
    method: 'POST',
    body: note
  })
}

export const updateDepartmentVenueNote = (deptId, departmentVenueId, id, note) => {
  return request({
    url: `departments/${deptId}/venues/${departmentVenueId}/notes/${id}`,
    method: 'PUT',
    body: note
  })
}
