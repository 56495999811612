import { Breadcrumb, Button, Card, Table, Cell, Pagination, Select, Input } from 'components'
import { Tabs } from 'components/Tabs'
import { PageHeading } from 'components/PageHeading'
import { InvoiceStatusBadge } from 'components/Badge'
import { getInvoiceRuns, getInvoices, exportInvoices } from 'api/invoices'
import { DateTime } from 'luxon'
import addIcon from 'assets/icons/outline/plus.svg'
import { getAllDepartments, getDepartmentVenues } from 'api/clients'
import { getVenues } from 'api/venues'
import { formatMoney } from 'utils'

export const Invoices = ({ attrs: { tab } }) => {
  let departments
  let venues

  let selectDepartment
  let selectVenue
  let selectStatus
  let selectSentStatus
  let selectBookingType
  let inputFromDate
  let inputToDate

  getAllDepartments({ pagesize: 100 }).then((d) => {
    departments = d.data.map(row => {
      return { label: row.name, value: row.id, clientId: row.clientId }
    })
    if (selectDepartment) {
      selectDepartment.setChoices(departments, 'value', 'label', true)
    }
  })

  getVenues({ pagesize: 10000 }).then((v) => {
    venues = v.data.map(row => {
      return { label: row.name, value: row.id }
    })
    if (selectVenue) {
      selectVenue.setChoices(venues, 'value', 'label', true)
    }
  })

  if (!tab) {
    tab = 'All Invoices'
  }

  const tabs = ['All Invoices', 'Invoice Runs'].map((t, ix) => {
    return {
      id: ix + 1,
      name: t,
      active: tab && tab === t
    }
  })

  const InvoicesTab = () => {
    let invoices
    let meta

    let departmentVenues

    let exportLoading = false

    const filters = { page: 1, sortOrder: 'desc' }

    const clearFilter = () => {
      filters.page = 1
      filters.number = null
      filters.sortOrder = 'desc'
      filters.InvoiceStatusId = null
      filters.sent = null
      filters.venueId = null
      filters.departmentId = null
      filters.fromDate = null
      filters.toDate = null
      filters.bookingType = null

      departmentVenues = null
      if (selectVenue) {
        selectVenue.removeActiveItems()
        selectVenue.setChoices(venues, 'value', 'label', true)
      }
      if (selectDepartment) {
        selectDepartment.removeActiveItems()
      }
      if (selectStatus) {
        selectStatus.removeActiveItems()
      }
      if (selectSentStatus) {
        selectSentStatus.removeActiveItems()
      }
      if (selectBookingType) {
        selectBookingType.removeActiveItems()
      }
      if (inputFromDate) {
        inputFromDate.clear()
      }
      if (inputToDate) {
        inputToDate.clear()
      }
    }

    const setVenueLookup = (departmentId) => {
      if (departmentId) {
        getDepartmentVenues(departmentId, { pagesize: 1000 }).then(dv => {
          departmentVenues = dv.data.map(d => {
            return {
              label: d.venueName,
              value: d.venueId
            }
          })
          selectVenue.setChoices(departmentVenues, 'value', 'label', true)
        })
      } else {
        departmentVenues = null
        selectVenue.setChoices(venues, 'value', 'label', true)
      }
    }

    const getInvoiceList = () => {
      invoices = null
      getInvoices(filters).then(data => {
        invoices = data.data
        meta = data.meta
      })
    }

    getInvoiceList()

    return {
      view () {
        return [
          m('form', {
            onsubmit (e) {
              e.preventDefault()
              filters.page = 1
              getInvoiceList()
            }
          }, [
            m(Card, {
              buttons: [
                m(Button, {
                  type: 'button',
                  onclick: () => {
                    clearFilter()
                  }
                }, 'Clear Filter'),
                m(Button, {
                  type: 'submit'
                }, 'Search')]
            }, [
              m('.grid.grid-cols-6.gap-2', [
                m('.col-span-1', [
                  m(Select, {
                    label: 'Select a Type',
                    value: filters.bookingType,
                    choices: [{ label: t('app', 'accommodation'), value: 1 }, { label: t('app', 'meetings'), value: 2 }],
                    required: false,
                    onchange (val, label) {
                      filters.bookingType = val
                    },
                    instance (obj) {
                      selectBookingType = obj
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Select, {
                    label: 'Select a department',
                    value: filters.departmentId,
                    choices: departmentVenues || departments || [],
                    search: true,
                    required: false,
                    onchange (val, label) {
                      filters.departmentId = val
                      setVenueLookup(val)
                    },
                    instance (obj) {
                      selectDepartment = obj
                    }
                  })
                ]),
                m('.col-span-2', [
                  m(Select, {
                    label: 'Select a venue',
                    value: filters.venueId,
                    choices: venues || [],
                    search: true,
                    required: false,
                    onchange (val, label) {
                      filters.venueId = val
                    },
                    instance (obj) {
                      selectVenue = obj
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Select, {
                    label: 'Select a Status',
                    value: filters.InvoiceStatusId,
                    choices: [{ label: 'Draft', value: 0 }, { label: 'Approved', value: 1 }],
                    required: false,
                    onchange (val, label) {
                      filters.InvoiceStatusId = val
                    },
                    instance (obj) {
                      selectStatus = obj
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Select, {
                    label: 'Select a Sent Status',
                    value: filters.sent,
                    choices: [{ label: 'Sent', value: true }, { label: 'Not Sent', value: false }],
                    required: false,
                    onchange (val, label) {
                      filters.sent = val
                    },
                    instance (obj) {
                      selectSentStatus = obj
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Input, {
                    label: 'Date from',
                    value: filters.fromDate,
                    type: 'date',
                    oninput (value) {
                      filters.fromDate = value
                    },
                    dateInstance (obj) {
                      inputFromDate = obj
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Input, {
                    label: 'Date to',
                    value: filters.toDate,
                    type: 'date',
                    oninput (value) {
                      filters.toDate = value
                    },
                    dateInstance (obj) {
                      inputToDate = obj
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Input, {
                    label: 'Number',
                    value: filters.number,
                    type: 'text',
                    oninput (value) {
                      filters.number = value
                    }
                  })
                ])
              ])
            ])
          ]),

          m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-1', [
            m('', { style: { height: 'calc(100vh - 20rem)' } }, [
              m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
                invoices && m(Table, {
                  cols: [
                    {
                      label: 'Type',
                      classes: ['w-40 justify-start']
                    },
                    {
                      label: 'Department'
                    },
                    {
                      label: 'Venue'
                    },
                    {
                      label: 'Date',
                      classes: ['w-32 justify-center']
                    },
                    {
                      label: 'Number',
                      classes: ['w-32 justify-center']
                    },
                    {
                      label: 'Total',
                      classes: ['w-32 justify-end']
                    },
                    {
                      label: 'Status',
                      classes: ['w-32 justify-center']
                    },
                    {
                      label: 'Sent Date',
                      classes: ['w-32 justify-center']
                    }
                  ]
                },
                [
                  m('.overflow-auto.flex-grow',
                    invoices && invoices.length ? invoices.map((v, ix) => {
                      return m(InvoiceListItem, { item: v })
                    }) : m('p.text-center.p-3.text-gray-400', 'No results')
                  ),
                  meta && m(Pagination, {
                    meta: meta,
                    onpage: (page) => {
                      filters.page = page
                      getInvoiceList()
                    },
                    buttons: m(Button, {
                      type: 'button',
                      loading: exportLoading,
                      onclick () {
                        exportLoading = true
                        exportInvoices(filters).then(() => {
                          exportLoading = false
                        })
                      }
                    }, 'Export')
                  })
                ])
              )
            ])
          ])]
      }
    }
  }

  return {
    view ({ attrs: { tab } }) {
      return [
        m('.pr-8', [
          m(Breadcrumb, {
            links: [{
              title: 'Invoices'
            }]
          }),
          m('.flex.flex-wrap.relative.h-full.overflow-x-hidden.content-start', [
            m('.p-4.w-full', [
              m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', {
              }, [
                m('div.px-6.py-3.bg-white.space-y-6.sm:py-4', [
                  m(PageHeading, {
                    heading: 'Invoices',
                    actions: [
                      {
                        icon: addIcon,
                        label: 'Add Invoice Run',
                        action: () => {
                          m.route.set('/invoices/create-run')
                        }
                      }
                    ]
                  })
                ]),
                m('div.bg-gray-50.px-4.py-4.sm:px-6', [
                  m(Tabs, {
                    tabs,
                    onTabChange: (tabId) => {
                      tabs.forEach(t => {
                        t.active = false
                        if (t.id === tabId) {
                          t.active = true
                          tab = t
                        }
                      })
                      m.redraw()

                      var pageUrl = `/invoices?tab=${tab.name}`
                      window.history.pushState('', '', pageUrl)
                    }
                  }),
                  m('.sm:grid.sm:grid-cols-4.sm:gap-4', [
                    m('div.sm:col-span-4', [
                      tabs.find(t => t.active).id === 1
                        ? m(InvoicesTab, {
                        })
                        : tabs.find(t => t.active).id === 2
                          ? m(InvoiceRunsTab, {
                          })
                          : null
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    }
  }
}

const InvoiceRunsTab = () => {
  const filters = { page: 1, search: null }
  let invoiceRuns
  let meta

  getInvoiceRuns().then(data => {
    invoiceRuns = data.data
    meta = data.meta
  })

  return {
    view () {
      return m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-1', [
        m('', { style: { height: 'calc(100vh - 20rem)' } }, [
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            m(Table, {
              cols: [
                {
                  label: 'Type',
                  classes: ['w-40 justify-start']
                },
                {
                  label: 'Start Time'
                },
                {
                  label: 'End Time'
                },
                {
                  label: '% Complete',
                  classes: ['w-32 justify-end']
                },
                {
                  label: 'Invoices',
                  classes: ['w-32 justify-center']
                },
                {
                  label: 'Status'
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto.flex-grow',
                invoiceRuns && invoiceRuns.length ? invoiceRuns.map((v, ix) => {
                  return m(InvoiceRunListItem, { item: v })
                }) : m('p.text-center.p-3.text-gray-400', 'No results')
              ),
              meta && m(Pagination, {
                meta: meta,
                onpage: (page) => {
                  filters.page = page
                  getInvoiceRuns(filters).then(data => {
                    invoiceRuns = data.data
                    meta = data.meta
                  })
                }
              })
            ])
          )
        ])
      ])
    }
  }
}

const InvoiceRunListItem = ({ attrs: { item, classes = [] } }) => {
  return {
    view () {
      return m('', {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'bg-white', ...classes].join(' '),
        style: {
          height: '40px'
        }
      }, [
        m(Cell, { width: 'w-40', align: 'left' },
          item.bookingTypeDescription
        ),
        m(Cell,
          DateTime.fromISO(item.startTime).toLocaleString(DateTime.DATETIME_MED)
        ),
        m(Cell,
          item.endTime !== '0001-01-01T00:00:00Z' ? DateTime.fromISO(item.endTime).toLocaleString(DateTime.DATETIME_MED) : ''
        ),
        m(Cell, { width: 'w-32', align: 'right' },
          item.totalBookingsToProcess === 0 ? 0 + ' %' : (item.bookingsProcessed / item.totalBookingsToProcess * 100).toFixed() + ' %'
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          item.numberOfInvoices
        ),
        m(Cell,
          item.statusDescription
        )
      ])
    }
  }
}

const InvoiceListItem = ({ attrs: { item, classes = [] } }) => {
  return {
    view () {
      return m(m.route.Link, {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'cursor-pointer', 'bg-white', ...classes].join(' '),
        style: {
          height: '40px'
        },
        href: `/invoices/${item.id}`
      }, [
        m(Cell, { width: 'w-40', align: 'left' },
          item.bookingTypeDescription
        ),
        m(Cell,
          item.departmentName
        ),
        m(Cell,
          item.venueName
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          DateTime.fromISO(item.invoiceDate).toLocaleString()
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          item.number
        ),
        m(Cell, { width: 'w-32', align: 'right' },
          formatMoney(item.grossTotal, item.currency.symbol, 2)
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          m(InvoiceStatusBadge, { status: item.statusName, size: 'xs' })
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          item.sentDate && item.sent ? DateTime.fromISO(item.sentDate).toLocaleString() : ''
        )
      ])
    }
  }
}
