import { Breadcrumb } from 'components/Breadcrumb'
import { Input } from 'components/Input'
import { Card } from 'components/Card'
import { Button } from 'components/Button'
import { getUser, updateDelegateUser, addDelegateUser } from 'api/users'
import { notification } from 'utils/notifications'
import { getDepartment } from 'api/clients'

export const EditDelegateUser = ({ attrs: { clientId, departmentId, userId } }) => {
  let department
  let user
  let userLoading
  let ready

  if (departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
      if (userId) {
        getUser(userId)
          .then(res => {
            user = res
            ready = true
          })
      } else {
        user = {
          email: `${department.name.replace(' ', '_').toLowerCase()}_${Date.now()}@tobook.delegate`,
          firstName: department.name,
          lastName: 'Delegate',
          delegateDepartmentId: departmentId,
          roles: ['user']
        }
        ready = true
      }
    })
  }

  return {
    view () {
      return [ready && m(Breadcrumb, {
        links: [{
          title: 'Clients',
          href: '/clients'
        }, {
          title: department.clientName,
          href: `/clients/${clientId}`
        }, {
          title: 'Departments',
          href: `/clients/${clientId}?tab=Departments`
        }, {
          title: department.name,
          href: `/clients/${clientId}/departments/${departmentId}`
        }, {
          title: 'Delegate User'
        }]
      }),
      user && m('div.bg-gray-50.px-4.py-5.sm:p-6', [
        m('.p-4.w-full', [
          m('form', {
            onsubmit (e) {
              e.preventDefault()
              userLoading = true
              if (userId) {
                updateDelegateUser(user.id, user)
                  .then(res => {
                    notification.success({ title: 'Delegate User saved' })
                    userLoading = false
                    m.route.set(`/clients/${clientId}/departments/${departmentId}?tab=Users`)
                  })
                  .catch(() => {
                    notification.error({ title: 'Error. User not saved' })
                    userLoading = false
                  })
              } else {
                addDelegateUser(user)
                  .then(res => {
                    notification.success({ title: 'Delegate User saved' })
                    userLoading = false
                    m.route.set(`/clients/${clientId}/departments/${departmentId}?tab=Users`)
                  })
                  .catch(() => {
                    notification.error({ title: 'Error. User not saved' })
                    userLoading = false
                  })
              }
            }
          }, [
            m(Card, {
              buttons: [m(Button, {
                type: 'submit',
                loading: userLoading
              }, t('createVenue', 'save'))]
            },
            m('.space-y-6.divide-y.divide-gray-200', [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'Delegate Username',
                      value: user.userName,
                      type: user.id ? 'readonly' : 'text',
                      required: true,
                      oninput (val) {
                        user.userName = val
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-4'),
                user.id && m('div.col-span-12',
                  [
                    m(Input, {
                      label: 'Current Password',
                      value: user.phoneNumber,
                      type: 'readonly'

                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-2',
                  m(Input, {
                    label: 'Delegate ' + t('register', 'password'),
                    value: user.newPassword,
                    type: 'password',
                    required: true,
                    oninput (val) {
                      user.newPassword = val
                    }
                  })
                )
              ])
            ]))
          ])
        ])
      ])
      ]
    }
  }
}
