import { Button } from 'components/Button'
import { Card } from 'components/Card'
import { Breadcrumb } from 'components/Breadcrumb'
import { Input } from 'components/Input'
import { getProfile } from 'api/profile'
import { updateProfile } from 'store/profile'
import { notification } from 'utils/notifications'

export const Settings = () => {
  let email
  let profile
  let profileLoading = false
  getProfile()
    .then(p => {
      email = p.email
      profile = {
        firstName: p.firstName,
        lastName: p.lastName,
        phoneNumber: p.phoneNumber
      }
    })

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: t('settings', 'title')
          }]
        }),
        profile && m('div.p-4',
          m('div.md:grid.md:grid-cols-3.md:gap-6',
            [
              m('div.md:col-span-1',
                m('div.px-4.sm:px-0',
                  [
                    m('h3.text-lg.font-medium.leading-6.text-gray-900',
                      t('settings', 'profileTitle')
                    ),
                    m('p.mt-1.text-sm.text-gray-600'
                    )
                  ]
                )
              ),
              m('div.mt-5.md:mt-0.md:col-span-2', [
                m('form', {
                  onsubmit (e) {
                    e.preventDefault()
                    profileLoading = true
                    updateProfile(profile).then(() => {
                      notification.success({ title: t('settings', 'profileSaved') })
                      profileLoading = false
                    })
                  }
                }, [
                  m(Card, {
                    buttons: [m(Button, {
                      type: 'submit',
                      loading: profileLoading
                    }, t('createVenue', 'save'))]
                  },
                  m('.space-y-6.divide-y.divide-gray-200', [
                    m('div.grid.grid-cols-6.gap-6', [
                      m('div.col-span-6.md:col-span-4',
                        [
                          m(Input, {
                            label: t('settings', 'profileEmail'),
                            value: email,
                            type: 'readonly'
                          })

                        ]
                      ),
                      m('div.col-span-6.md:col-span-4',
                        [
                          m(Input, {
                            label: t('settings', 'profileFirstName'),
                            value: profile.firstName,
                            type: 'text',
                            required: true,
                            oninput (val) {
                              profile.firstName = val
                            }
                          })
                        ]
                      ),
                      m('div.col-span-6.md:col-span-4',
                        [
                          m(Input, {
                            label: t('settings', 'profileLastName'),
                            value: profile.lastName,
                            type: 'text',
                            required: true,
                            oninput (val) {
                              profile.lastName = val
                            }
                          })
                        ]
                      ),
                      m('div.col-span-6.md:col-span-4',
                        [
                          m(Input, {
                            label: t('settings', 'profilePhoneNumber'),
                            type: 'tel',
                            value: profile.phoneNumber,
                            oninput (val) {
                              profile.phoneNumber = val
                            }
                          })
                        ]
                      )
                    ])
                  ]))
                ])
              ])
            ]
          )
        )
      ]
    }
  }
}
