import { Breadcrumb } from 'components/Breadcrumb'
import { Input } from 'components/Input'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { getVenueGroup, updateVenueGroup, createVenueGroup } from 'api/venues'
import { notification } from 'utils/notifications'

export const EditVenueGroup = ({ attrs: { id } }) => {
  let ready
  let venueGroup

  if (id) {
    getVenueGroup(id).then(data => {
      venueGroup = data
      ready = true
    })
  } else {
    venueGroup = { name: '' }
    ready = true
  }

  const update = (e) => {
    e.preventDefault()
    if (id) {
      updateVenueGroup(venueGroup).then(() => {
        notification.success({ title: t('venueGroups', 'updated') })
      })
    } else {
      createVenueGroup(venueGroup).then(() => {
        notification.success({ title: t('venueGroups', 'created') })
      })
    }
    m.route.set('/venue-groups')
  }

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Venue Groups',
            href: '/venue-groups'
          }, {
            title: !id ? 'Create' : (ready ? `Edit ${venueGroup.name}` : 'Edit')
          }]
        }),
        ready && m(EditForm, {
          onsubmit: update
        },
        [
          m(EditFormSection, {
            heading: id ? 'Edit Venue Group' : 'Create Venue Group',
            description: !id && 'Please enter the venue group name.'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6',
                [
                  m(Input, {
                    label: 'Name',
                    value: venueGroup.name,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      venueGroup.name = value
                    }
                  })
                ]
              )
            ])
          ])
        ])
      ]
    }
  }
}
