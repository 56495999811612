
import { Select, Input, Dialog2, LoadingFullScreen, TinyMce } from 'components'
import { getMeetingEmail, getDefaultMeetingEmail, getMeetingContacts } from 'api/meetings'

export const EmailDialog = ({ attrs: { meeting, templates, loading, templateType } }) => {
  loading = false
  let editor
  let selectTemplate
  const formData = {
    subject: '',
    emailBody: '',
    templateId: null,
    templateType: templateType || null,
    emailToAddresses: [],
    newEmailToAddresses: [],
    delay: 15
  }

  let contacts
  getMeetingContacts(meeting.id).then(data => {
    contacts = data
  })

  if (templateType) {
    getDefaultMeetingEmail(meeting.id, formData.templateType).then(data => {
      formData.subject = data.subject
      formData.templateId = data.templateId
      formData.email.delay = data.delay
      const value = data.emailBody
      if (value) {
        editor.setContent(value)
      }
      if (selectTemplate) {
        selectTemplate.setChoiceByValue(formData.email.templateId)
      }
    })
  }

  return {
    view ({ attrs: { booking, templates, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          formData.emailToAddresses = formData.emailToAddresses.concat(formData.newEmailToAddresses)

          if (formData.emailToAddresses.length) {
            loading = true
            onsubmit(formData)
          }
        },
        classes: ['lg:max-w-3xl', 'xl:max-w-4xl'],
        heading: 'Send Email',
        saving: loading,
        saveLabel: 'Send'
      }, [
        m('.flex.flex-col.gap-3', [
          m(Select, {
            label: 'Select Template',
            value: formData.templateId,
            choices: templates.map(template => {
              return { label: template.description, value: template.id }
            }),
            onchange (val) {
              formData.templateId = val
              getMeetingEmail(meeting.id, formData.templateId).then(data => {
                formData.subject = data.subject
                formData.templateType = data.templateType
                const value = data.emailBody
                if (value) {
                  editor.setContent(value)
                  formData.emailBody = value
                }
              })
            },
            instance (obj) {
              selectTemplate = obj
            }
          }),
          m('.grid.grid-cols-3.gap-x-3', [
            m('.col-span-2',
              contacts ? m(Select, {
                label: 'To',
                value: formData.emailToAddresses.length ? formData.emailToAddresses : [],
                choices: contacts.map((contact, ix) => {
                  return { disabled: !contact.email, label: contact.name ? `${contact.name} &lt;${contact.email || 'No email set'}&gt; (${contact.type})` : `${contact.email || 'No email set'} (${contact.type})`, value: ix }
                }),
                subtype: 'multiple',
                removeItemButton: true,
                onchange (val) {
                  formData.emailToAddresses = val.map(contactIx => {
                    return contacts[contactIx].email
                  })
                }
              }) : m(LoadingFullScreen)
            ),

            m(Input, {
              label: 'New email address',
              value: formData.newEmailToAddresses.length ? formData.newEmailToAddresses.join(',') : '',
              oninput (value) {
                formData.newEmailToAddresses = value.split(',')
              }
            })

          ]),
          m('.grid.grid-cols-3.gap-x-3', [
            m('.col-span-2',
              m(Input, {
                label: 'Subject',
                value: formData.subject,
                required: true,
                oninput (value) {
                  formData.subject = value
                }
              })),
            m('div',
              m(Input, {
                label: 'Delay',
                value: formData.delay,
                required: true,
                oninput (value) {
                  formData.delay = value
                },
                suffix: 'mins'
              })
            )
          ]),
          m('.flex.flex-col', [
            m(TinyMce, {
              value: formData.emailBody,
              label: 'Body',
              height: 300,
              onchange: (content) => {
                formData.emailBody = content
              },
              editor: (instance) => {
                editor = instance
              }
            })
          ])
        ])
      ])
    }
  }
}
