import { DelegateCard } from '../DelegateCard'
import { DelegateInput } from '../DelegateInput'

export const GuestInformation = () => {
  return {
    view ({ attrs: { booking, currentStep, currentStepValid } }) {
      if (currentStep().toString() === '0') {
        currentStepValid(true)
      }
      return m(DelegateCard, {
        stepNumber: 1,
        heading: t('delegateBooking', 'guestInformation'),
        buttonText: t('delegateBooking', 'next'),

        currentStep,
        onchange: () => {
          currentStep(1)
        }
      }, [
        m('fieldset' + (currentStep() !== 0 ? '[disabled]' : ''), [
          m(DelegateInput, {
            label: t('delegateBooking', 'firstName'),
            required: true,
            value: booking.firstName,
            oninput: (val) => {
              booking.firstName = val
            }
          }),
          m(DelegateInput, {
            label: t('delegateBooking', 'surname'),
            required: true,
            value: booking.surname,
            oninput: (val) => {
              booking.surname = val
            }
          }),
          m(DelegateInput, {
            label: t('delegateBooking', 'email'),
            placeholder: t('delegateBooking', 'emailPlaceholder'),
            required: true,
            value: booking.email,
            type: 'email',
            oninput: (val) => {
              booking.email = val
            }
          }),
          m(DelegateInput, {
            label: t('delegateBooking', 'telephone'),
            value: booking.telephone,
            required: true,
            oninput: (val) => {
              booking.telephone = val
            }
          }),
          m(DelegateInput, {
            label: t('delegateBooking', 'street'),
            value: booking.addressLine1,
            oninput: (val) => {
              booking.addressLine1 = val
            }
          }),
          m(DelegateInput, {
            label: t('delegateBooking', 'townCity'),
            value: booking.townCity,
            oninput: (val) => {
              booking.townCity = val
            }
          }),

          m(DelegateInput, {
            classes: ['sm:border-r'],
            label: t('delegateBooking', 'county'),
            value: booking.county,
            oninput: (val) => {
              booking.county = val
            }
          }),
          m(DelegateInput, {
            label: t('delegateBooking', 'postcode'),
            value: booking.postcode,
            oninput: (val) => {
              booking.postcode = val
            }
          })
        ])

      ])
    }
  }
}
