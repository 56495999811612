import Choices from 'choices.js'

export const Select = () => {
  let choicesEl = {}
  return {
    view ({ attrs: { subtype, name, search, strValue, shouldSort, choices, choicesFetch, onchange, label, errorMessage, value, disabled, required, removeItemButton, instance } }) {
      name = name || label.replaceAll(' ', '_').toLowerCase()
      return m('.flex-grow', {
        class: disabled ? 'pointer-events-none' : '',
        title: disabled ? 'Please select department to load venues' : ''
      }, [
        m(`label.block.text-sm.font-medium[for='${name}']`, {
          class: disabled ? 'text-gray-400' : 'text-gray-700'
        }, label
        ),
        m('select', {
          multiple: subtype === 'multiple',
          name: name,
          required: required,
          oncreate: el => {
            choicesEl = new Choices(el.dom, {
              searchEnabled: search === null ? true : search,
              shouldSort: shouldSort || false,
              removeItemButton: removeItemButton !== false,
              choices: [{ value: null, label: null }],
              itemSelectText: 'Select',
              classNames: {
                containerOuter: 'choices mt-0 mb-0',
                containerInner: 'choices__inner h-10 sm:text-sm pl-2 py-1 bg-white border border-gray-300 mt-1 relative rounded-md shadow-sm w-full overflow-y-auto',
                item: 'choices__item text-base'
              }
            })

            if (instance) { instance(choicesEl) }
            if (choices) {
              choicesEl.setChoices(choices.map(c => {
                return {
                  label: c.label,
                  value: c.value,
                  selected: subtype === 'multiple' ? value.includes(c.value) : c.value === (strValue ? value : parseInt(value)),
                  disabled: c.disabled || false
                }
              }), 'value', 'label', false)
            }
          },
          onchange: evt => {
            if (subtype === 'multiple') {
              const values = choicesEl.getValue()
              onchange(values.map(choice => { return choice.value }), values.map(choice => { return choice.label }))
            } else {
              onchange(evt.target.value, evt.target.textContent)
            }
          }
        }),
        m('.text-red-600.text-sm', errorMessage)
      ])
    }
  }
}
