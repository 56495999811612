import ocrLogo from 'assets/images/ocr-logo.png'
import poweredBy from 'assets/images/poweredby.png'

export const Navbar = () => {
  return {
    view ({ attrs: { departmentInfo } }) {
      return m('.bg-orange-600.flex.justify-between.items-center.h-16.md:h-32', [
        m('.logo-container.w-1/3.bg-white.md:py-4.md:px-12.flex.justify-end.h-full', [
          departmentInfo.name === 'OCR' && m('img.h-full.w-full.object-contain', { src: ocrLogo })
        ]),
        m('img.h-6.md:h-8.mr-8.md:mr-16', { src: poweredBy })
      ])
    }
  }
}
