import { Breadcrumb, Table, Cell, Button, Svg } from 'components'
import { notification } from 'utils/notifications'
import { getEmailTemplates, deleteEmailTemplate } from 'api/emailTemplates'
import addIcon from 'assets/icons/outline/plus.svg'
import cancelIcon from 'assets/icons/outline/x-circle.svg'

const EmailTemplate = ({ attrs: { item, classes, onDelete } }) => {
  let deleteTemplateConfirm = false
  const deleteTemplate = (templateId) => {
    deleteTemplateConfirm = false
    onDelete(templateId)
  }

  return {
    view () {
      return m(m.route.Link, {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'cursor-pointer', 'bg-white', ...classes].join(' '),
        style: {
          height: '40px'
        },
        href: `/email-templates/${item.id}`
      },
      [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.truncate.text-sm.font-medium.text-gray-900',
          item.description
        ),
        m('flex.w-64.h-10.px-6.py-3.whitespace-nowrap.truncate.text-sm.text-gray-500',
          item.templateTypeString
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.truncate.text-sm.font-medium.text-gray-900',
          item.defaultSubject
        ),
        m(Cell, { width: 'w-24', align: 'left', tight: true, noEllipsis: true },
          item.templateType === 6 || item.templateType === 11
            ? m('button.text-red-700.hover:text-red-900.text-xs.flex.items-center.ml-2.focus:bg-red-800.focus:text-red-200', {
              type: 'button',
              onclick (e) {
                e.preventDefault()
                if (deleteTemplateConfirm) { deleteTemplate(item.id) } else {
                  deleteTemplateConfirm = true
                  setTimeout(() => {
                    deleteTemplateConfirm = false
                    m.redraw()
                  }, 3000)
                }
                e.cancelBubble = true
              }
            }, [
              m(Svg, { classes: ['w-6', 'h-6'] }, cancelIcon),
              deleteTemplateConfirm && m('span.ml-1.pr-2', 'Confirm')
            ]
            ) : null
        )
      ]
      )
    }
  }
}

export const EmailTemplates = () => {
  let emailTemplates

  const deleteTemplate = (templateId) => {
    deleteEmailTemplate(templateId).then(() => {
      notification.success({ title: 'Email Template deleted' })
      getEmailTemplates().then(data => {
        emailTemplates = data.data
      })
    }).catch(err => {
      notification.error({ title: err.response })
    })
  }

  getEmailTemplates().then(data => {
    emailTemplates = data.data
  })

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Email Templates'
          }],
          buttons: [m(Button, {
            size: 'sm',
            classes: ['my-2', 'mx-2', 'whitespace-nowrap', 'text-white'],
            type: 'button',
            onclick: () => {
              m.route.set('/email-templates/create')
            }
          }, [m(Svg, {
            classes: ['w-4', 'mx-auto']
          }, [addIcon]), 'Add Email Template'])]
        }),
        m('.p-4', { style: { height: 'calc(100% - 3rem)' } }, [
          m('.h-full.shadow.overflow-hidden.border-b.border-gray-200.sm:rounded-lg',
            emailTemplates && m(Table, {
              cols: [
                {
                  label: 'Name'
                },
                {
                  label: 'Type', classes: ['w-64']
                },
                {
                  label: 'Subject'
                },
                // Delete button
                {
                  classes: ['w-24']
                }
              ]
            },
            [
              m('.overflow-auto',
                emailTemplates.map((v, ix) => {
                  return m(EmailTemplate, { key: v.id, item: v, classes: [ix % 2 ? 'bg-gray-50' : 'bg-white'], onDelete: deleteTemplate })
                })
              )
            ])
          )
        ])
      ]
    }
  }
}
