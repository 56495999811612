import { Breadcrumb } from 'components/Breadcrumb'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { RadioGroup } from 'components/RadioGroup'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Select } from 'components/Select'

// import { TextArea } from 'components/TextArea'
import { Checkbox } from 'components/Checkbox'
import { notification } from 'utils/notifications'

import { getDepartment, getDepartmentBookingFields, createDepartmentBookingField, updateDepartmentBookingField, getClient } from 'api/clients'

export const BookingFields = ({ attrs: { clientId, departmentId } }) => {
  let department
  let bookingFields
  let ready
  let client
  const vis = [{ label: 'Public', value: 3, desc: 'Appear for delegates when booking' }, { label: 'Private', value: 2, desc: 'Appear for admin when booking' }, { label: 'Closed', value: 1, desc: 'Not available for booking' }]

  if (departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
      getDepartmentBookingFields(departmentId).then(bdata => {
        bookingFields = bdata
        ready = true
      })
    })
  } else {
    department = {}
    ready = true
    getClient(clientId).then(data => {
      client = data
    })
  }

  const update = (e) => {
    e.preventDefault()
    const updates = []
    bookingFields.forEach(bookingField => {
      updates.push(updateDepartmentBookingField(departmentId, bookingField))
    })

    Promise.all(updates).then(() => {
      notification.success({ title: t('app', 'updated') })
      m.route.set(`/clients/${clientId}/departments/${departmentId}`)
    })
  }

  return {
    view ({ attrs: { clientId, departmentId } }) {
      return [
        ready && m(Breadcrumb, {
          links: [{
            title: 'Clients',
            href: '/clients'
          }, {
            title: department.clientName || (client && client.name),
            href: `/clients/${clientId}`
          }, {
            title: 'Departments',
            href: `/clients/${clientId}?tab=Departments`
          }, {
            title: department.name,
            href: `/clients/${clientId}/departments/${departmentId}`
          }, {
            title: 'Booking Fields'
          }]
        }),
        ready && m(EditForm, {
          onsubmit: update
        },
        [
          m(EditFormSection, {
            heading: 'Edit Department Booking Fields',
            description: 'Update the custom department booking fields which capture bespoke data required by the department',
            buttons: [
              m(Button, {
                type: 'button',
                onclick: () => {
                  createDepartmentBookingField(departmentId, {
                    name: '',
                    label: '',
                    isMandatory: false,
                    visibilityId: 1,
                    type: 0
                  }).then(newField => {
                    bookingFields.push(newField)
                  })
                }
              }, t('viewDepartment', 'addBookingField')),
              m(Button, {
                type: 'submit'
              }, t('app', 'save'))
            ]
          }, [
            m('.grid.grid-cols-12.gap-x-12.gap-y-6', [
              bookingFields.map((bf, ix) => {
                return [
                  m('div',
                    m('span.inline-block.text-md.rounded.rounded-full.bg-gray-400.text-white.h-6.w-6.flex.items-center.justify-center', ix + 1)
                  ),
                  m('.col-span-12.md:col-span-6.grid.gap-6', [
                    m('div', [
                      m(Input, {
                        label: 'Name',
                        value: bf.name,
                        type: 'text',
                        required: true,
                        oninput (value) {
                          bf.name = value
                        }
                      })
                    ]),
                    m('div', [
                      m(Input, {
                        label: 'Label',
                        value: bf.label,
                        type: 'text',
                        required: true,
                        oninput (value) {
                          bf.label = value
                        }
                      })
                    ]),
                    m('div', [
                      m(Select, {
                        label: 'Type',
                        search: false,
                        removeItemButton: false,
                        required: true,
                        value: bf.type,
                        onchange (e) {
                          bf.type = parseInt(e)
                        },
                        choices: [{ label: 'Text', value: 0 }, { label: 'Checkbox', value: 1 }, { label: 'Lookup', value: 2 }]
                      })
                    ]),
                    bf.type === 2 && m('div', [
                      m(Input, {
                        label: 'Options',
                        value: bf.lookupOptions,
                        type: 'text',
                        required: true,
                        oninput (value) {
                          bf.lookupOptions = value
                        }
                      })
                    ])
                  ]),
                  m('.col-span-12.md:col-span-5', [
                    m('.pt-4', [
                      m(Checkbox, {
                        label: 'Required',
                        checked: bf.isMandatory,
                        onchange () {
                          bf.isMandatory = !bf.isMandatory
                        }
                      })
                    ]),
                    m('div.mt-6', [
                      m(RadioGroup, {
                        label: 'Visibility',
                        value: bf.visibilityId,
                        required: true,
                        options: vis,
                        onchange (value) {
                          bf.visibilityId = value
                        }
                      })
                    ])
                  ]),
                  m('div.col-span-12',
                    m('hr')
                  )
                ]
              })
            ])
          ])
        ])
      ]
    }
  }
}
