import { Breadcrumb } from 'components/Breadcrumb'
import { Input } from 'components/Input'
import { Card } from 'components/Card'
import { Button } from 'components/Button'
import { addAdminUser } from 'api/users'
import { notification } from 'utils/notifications'

export const CreateUser = ({ attrs: { id } }) => {
  const user = {
    roles: ['administrator'],
    isEnabled: true
  }
  let userLoading
  return {
    view () {
      return m('', [
        m(Breadcrumb, {
          links: [{
            title: 'Admin Users',
            href: '/users'
          }, {
            title: 'Add'
          }]
        }),
        m('div.bg-gray-50.px-4.py-5.sm:p-6', [
          m('.p-4.w-full', [
            m('form', {
              onsubmit (e) {
                e.preventDefault()
                userLoading = true
                addAdminUser(user)
                  .then(res => {
                    notification.success({ title: 'User added' })
                    userLoading = false
                    m.route.set('/users')
                  })
                  .catch(() => {
                    notification.error({ title: 'Error. User not saved' })
                    userLoading = false
                  })
              }
            }, [
              m(Card, {
                buttons: [m(Button, {
                  type: 'submit',
                  loading: userLoading
                }, 'Add User')]
              },
              m('.space-y-6.divide-y.divide-gray-200', [
                m('div.grid.grid-cols-6.gap-6', [
                  m('div.col-span-6.md:col-span-4',
                    [
                      m(Input, {
                        label: t('settings', 'profileEmail'),
                        value: user.email,
                        type: 'email',
                        required: true,
                        oninput (val) {
                          user.email = val
                        }
                      })

                    ]
                  ),
                  m('div.col-span-6.md:col-span-4',
                    [
                      m(Input, {
                        label: t('settings', 'profileFirstName'),
                        value: user.firstName,
                        type: 'text',
                        required: true,
                        oninput (val) {
                          user.firstName = val
                        }
                      })
                    ]
                  ),
                  m('div.col-span-6.md:col-span-4',
                    [
                      m(Input, {
                        label: t('settings', 'profileLastName'),
                        value: user.lastName,
                        type: 'text',
                        required: true,
                        oninput (val) {
                          user.lastName = val
                        }
                      })
                    ]
                  ),
                  m('div.col-span-6.md:col-span-4',
                    [
                      m(Input, {
                        label: t('settings', 'profilePhoneNumber'),
                        type: 'tel',
                        value: user.phoneNumber,
                        oninput (val) {
                          user.phoneNumber = val
                        }
                      })
                    ]
                  )
                ])
              ]))
            ])
          ])
        ])
      ])
    }
  }
}
