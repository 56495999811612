import { EmailInvoiceDialog } from './EmailInvoiceDialog'
import { Card, Link, Breadcrumb, LoadingFullScreen, Button, Svg, Heading } from 'components'
import { Activity } from 'components/PastActivityBar/Activity'
import { EmptyState } from 'components/EmptyState'
import { NoteDialog } from './NoteDialog'
import { InvoiceStatusBadge } from 'components/Badge'
import { DateTime } from 'luxon'
import { getInvoice, getInvoiceActivity, unapproveInvoice, approveInvoice, sendInvoiceEmail, addInvoiceNote, getInvoicePdf } from 'api/invoices'
import { formatMoney, timeUntilEmailSent } from 'utils'
import { notification } from 'utils/notifications'
import moreIcon from 'assets/more.svg'
import alertIcon from 'assets/alert-circle.svg'
import addNoteIcon from 'assets/icons/outline/annotation.svg'
import cancelIcon from 'assets/icons/outline/x-circle.svg'
import emailIcon from 'assets/icons/outline/mail.svg'
import { EmailMessageDialog } from 'pages/Emails/EmailMessageDialog'
import { cancelEmailMessage } from 'api/system'

export const ViewInvoice = ({ attrs: { invoiceId } }) => {
  let invoice
  let approveInvoiceLoading
  let invoiceActivity
  let invoiceDownloading

  let savingEmail
  const emailOpen = flyd.stream(false)

  getInvoice(invoiceId).then(data => {
    invoice = data
  })

  const sendEmail = (email) => {
    savingEmail = true
    sendInvoiceEmail(invoice.id, email).then(data => {
      emailOpen(false)
      savingEmail = false
      notification.success({ title: 'Email sent' })
      invoice = data
      getInvoiceActivity(invoiceId).then(data => {
        invoiceActivity = data.data
      })
    })
      .catch(err => {
        console.log(err)
        notification.error({ title: 'unable to send email' })
        savingEmail = false
      })
  }

  const loadActivity = () => {
    getInvoiceActivity(invoiceId).then(data => {
      invoiceActivity = data.data
    })
  }

  loadActivity()

  let activityOpen = true
  let addingNote
  const addNoteOpen = flyd.stream(false)

  const addNote = (note) => {
    addingNote = true
    addInvoiceNote(invoiceId, note).then(data => {
      invoiceActivity = data.data
      addNoteOpen(false)
      addingNote = false
    }).catch(() => {
      addingNote = false
      notification.error({ title: 'Error - unable to add note' })
    })
  }

  let currentEmail = {}
  const emailMessageOpen = flyd.stream(false)
  let cancelEmailConfirm = false

  const cancelEmail = (emailMessageId) => {
    cancelEmailConfirm = false
    cancelEmailMessage(emailMessageId).then(() => {
      loadActivity()
    })
  }

  return {
    view ({ attrs: { invoiceId, tab } }) {
      return [
        m('div', {
          class: activityOpen ? 'pr-64 xl:pr-72 xxl:pr-96' : 'pr-8',
          style: { height: 'calc(100% - 3rem)' }
        }, [
          invoice && m(Breadcrumb, {
            links: [{
              title: 'Invoices',
              href: '/invoices'
            }, {
              title: invoice.departmentVenue.department.name
            }, {
              title: 'Invoice'
            }]
          }),
          invoice && m('.flex.flex-wrap.relative.h-full.overflow-x-hidden.content-start', {
            class: invoice.statusName === 'Confirmed' ? 'bg-green-100'
              : invoice.statusName === 'Cancelled' ? 'bg-red-100'
                : 'bg-gray-100'
          }, [
            m('.p-4.w-full', [
              m('.flex.flex-col.sm:rounded-md.divide-y.divide-gray-200', {
              }, [
                m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
                  m('.flex.justify-between', [
                    m('span.text-2xl', [
                      m('span.mr-3', 'INVOICE'),
                      m(InvoiceStatusBadge, { status: invoice.invoiceStatus.name, size: 'lg' })
                    ]),
                    invoice.invoiceStatusId === 0 && m(Button, {
                      size: 'md',
                      classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                      type: 'button',
                      onclick: () => {
                        m.route.set(`/invoices/${invoiceId}/edit`)
                      }
                    }, [
                      m(Svg, {
                        classes: ['-ml-1', 'w-5', 'h-5', 'mr-2']
                      }),
                      'Edit Invoice'
                    ])

                  ]),
                  m('.grid.sm:grid-cols-4.lg:grid-cols-6', [
                    m('.sm:col-span-2.lg:col-span-3', [
                      m('h5.text-sm.text-gray-500', 'Venue'),
                      m('p.text-md', invoice.venueName),
                      m('h5.text-sm.text-gray-500.mt-3', 'Department'),
                      m('p.text-md', invoice.departmentVenue.department.name),
                      invoice.bookingType === 1
                        ? m('', [
                          m('h5.text-sm.text-gray-500.mt-3', 'Commission'),
                          m('p.text-md', (invoice.commissionRate * 100).toFixed(2))
                        ]) : null
                    ]),
                    m('.sm:col-span-1.lg:col-span-2', [
                      m('h5.text-sm.text-gray-500', 'Invoice Number'),
                      m('p.text-md', invoice.number),
                      invoice.reference && m('h5.text-sm.text-gray-500.mt-3', 'Reference'),
                      invoice.reference && m('p.text-md', invoice.reference),
                      m('h5.text-sm.text-gray-500.mt-3', 'Invoice Total'),
                      m('p.text-md', formatMoney(invoice.grossTotal, invoice.currency.symbol, 2))
                    ]),
                    m('div.text-right', [
                      m('h5.text-sm.text-gray-500', 'Invoice Date'),
                      m('h3.text-lg', DateTime.fromISO(invoice.invoiceDate).toLocaleString()),
                      m('h5.text-sm.text-gray-500.mt-3', 'Due Date'),
                      m('h4.text-lg', DateTime.fromISO(invoice.dueDate).toLocaleString()),
                      invoice.approvedDate && m('h5.text-sm.text-gray-500.mt-3', 'Approved Date'),
                      invoice.approvedDate && m('h4.text-lg', DateTime.fromISO(invoice.approvedDate).toLocaleString()),
                      invoice.sentDate && m('h5.text-sm.text-gray-500.mt-3', 'Sent Date'),
                      invoice.sentDate && m('h4.text-lg', DateTime.fromISO(invoice.sentDate).toLocaleString())
                    ])
                  ]),
                  invoice.notes && m('', [
                    m('h5.text-sm.text-gray-500', 'Invoice Notes'),
                    m('p', m.trust(invoice.notes.replaceAll('\n', '<br>')))
                  ])
                ]),
                m('div', {
                  class: 'bg-gray-50'
                }, [
                  invoice.bookingType === 1
                    ? m(Card, m('.space-y-3.divide-y.divide-gray-200', [
                      m('table.table.w-full', [
                        m('thead.bg-gray-50', [
                          m('tr', [
                            m('th.px-3.py-2.text-sm.text-left', 'Stay Date'),
                            m('th.px-3.py-2.text-sm.text-center', 'Nights'),
                            m('th.px-3.py-2.text-sm.text-left', 'Attendee'),
                            m('th.px-3.py-2.text-sm.text-right', 'Rate'),
                            m('th.px-3.py-2.text-sm', 'Reservation'),
                            m('th.px-3.py-2.text-sm.text-right', 'Total Cost'),
                            m('th.px-3.py-2.text-sm.text-right', 'Net Value'),
                            m('th.px-3.py-2.text-sm.text-right', 'Commission Due'),
                            m('th.px-3.py-2.text-sm.text-right', 'VAT')
                          ])
                        ]),
                        m('tbody', [
                          invoice.lines.map((l, ix) => {
                            return m('tr', {
                              class: ix % 2 === 0 ? 'bg-white' : 'bg-gray-100'
                            }, [
                              m('td.px-3.py-2.text-sm', DateTime.fromISO(l.booking.arrivalDate).toLocaleString()),
                              m('td.px-3.py-2.text-sm.text-center', l.booking.nights),
                              m('td.px-3.py-2.text-sm', m(Link, { href: `/clients/${invoice.departmentVenue.department.clientId}/departments/${invoice.departmentVenue.department.id}/bookings/${l.bookingId}` }, l.booking.name)),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.booking.rate, invoice.currency.symbol, 2)),
                              m('td.px-3.py-2.text-sm.text-center', l.booking.reservationCode),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.bookingTariffGross, invoice.currency.symbol, 2)),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.bookingTariffNet, invoice.currency.symbol, 2)),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.commissionNet, invoice.currency.symbol, 2)),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.commissionVat, invoice.currency.symbol, 2))
                            ])
                          })
                        ]),
                        m('tfoot', [
                          m('tr', [
                            m('th.px-3.py-2.text-sm.text-left', { colspan: 4 }, `${invoice.lines.length} Booking${(invoice.lines.length > 1 ? 's' : '')}`),
                            m('th.px-3.py-2.text-sm.text-right', 'Totals:'),
                            m('th.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(invoice.lines.reduce((a, b) => {
                              return a + parseFloat(b.bookingTariffGross)
                            }, 0), invoice.currency.symbol, 2)),
                            m('th.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(invoice.lines.reduce((a, b) => {
                              return a + parseFloat(b.bookingTariffNet)
                            }, 0), invoice.currency.symbol, 2)),
                            m('th.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(invoice.lines.reduce((a, b) => {
                              return a + parseFloat(b.commissionNet)
                            }, 0), invoice.currency.symbol, 2)),
                            m('th.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(invoice.lines.reduce((a, b) => {
                              return a + parseFloat(b.commissionVat)
                            }, 0), invoice.currency.symbol, 2))
                          ])
                        ])
                      ])
                    ]))
                    // Meetings
                    : m(Card, m('.space-y-3.divide-y.divide-gray-200', [
                      m('table.table.w-full', [
                        m('thead.bg-gray-50', [
                          m('tr', [
                            m('th.px-3.py-2.text-sm.text-left', 'Meeting Date'),
                            m('th.px-3.py-2.text-sm.text-center', 'Days'),
                            m('th.px-3.py-2.text-sm.text-left', 'Description'),
                            m('th.px-3.py-2.text-sm.text-right', 'Total Cost'),
                            m('th.px-3.py-2.text-sm.text-right', 'Net Value'),
                            m('th.px-3.py-2.text-sm.text-right', 'Commission Due'),
                            m('th.px-3.py-2.text-sm.text-right', 'VAT')
                          ])
                        ]),
                        m('tbody', [
                          invoice.meetingLines.map((l, ix) => {
                            return m('tr', {
                              class: ix % 2 === 0 ? 'bg-white' : 'bg-gray-100'
                            }, [
                              m('td.px-3.py-2.text-sm', DateTime.fromISO(l.meeting.arrivalDate).toLocaleString()),
                              m('td.px-3.py-2.text-sm.text-center', l.meeting.days),
                              m('td.px-3.py-2.text-sm', m(Link, { href: `/clients/${invoice.departmentVenue.department.clientId}/departments/${invoice.departmentVenue.department.id}/meetings/${l.meetingId}` }, l.meeting.description)),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.meetingTariffGross, invoice.currency.symbol, 2)),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.meetingTariffNet, invoice.currency.symbol, 2)),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.commissionNet, invoice.currency.symbol, 2)),
                              m('td.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(l.commissionVat, invoice.currency.symbol, 2))
                            ])
                          })
                        ]),
                        m('tfoot', [
                          m('tr', [
                            m('th.px-3.py-2.text-sm.text-left', { colspan: 2 }, `${invoice.meetingLines.length} Meeting${(invoice.meetingLines.length > 1 ? 's' : '')}`),
                            m('th.px-3.py-2.text-sm.text-right', 'Totals:'),
                            m('th.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(invoice.meetingLines.reduce((a, b) => {
                              return a + parseFloat(b.meetingTariffGross)
                            }, 0), invoice.currency.symbol, 2)),
                            m('th.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(invoice.meetingLines.reduce((a, b) => {
                              return a + parseFloat(b.meetingTariffNet)
                            }, 0), invoice.currency.symbol, 2)),
                            m('th.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(invoice.meetingLines.reduce((a, b) => {
                              return a + parseFloat(b.commissionNet)
                            }, 0), invoice.currency.symbol, 2)),
                            m('th.px-3.py-2.text-sm.whitespace-nowrap.text-right', formatMoney(invoice.meetingLines.reduce((a, b) => {
                              return a + parseFloat(b.commissionVat)
                            }, 0), invoice.currency.symbol, 2))
                          ])
                        ])
                      ])
                    ]))
                ]),
                m('div.lg:col-span-1', [
                  m(Card, m('.flex.justify-end.gap-2', [
                    m(Button, {
                      size: 'md',
                      classes: ['whitespace-nowrap', 'text-white', 'disabled'],
                      type: 'button',
                      loading: approveInvoiceLoading,
                      variant: invoice.invoiceStatusId === 1 ? 'warning' : 'positive',
                      onclick: () => {
                        approveInvoiceLoading = true
                        if (invoice.invoiceStatusId === 1) {
                          unapproveInvoice(invoiceId).then(data => {
                            invoice = data
                            approveInvoiceLoading = false
                            loadActivity()
                          }).catch(() => {
                            approveInvoiceLoading = false
                          })
                        } else {
                          approveInvoice(invoiceId).then(data => {
                            invoice = data
                            approveInvoiceLoading = false
                            loadActivity()
                          }).catch(() => {
                            approveInvoiceLoading = false
                          })
                        }
                      }
                    }, [

                      `${invoice.invoiceStatusId === 1 ? 'Unapprove' : 'Approve'} Invoice`
                    ]),
                    invoice.invoiceStatusId === 1
                      ? invoice.legacyPDF ? m('a.rounded.py-3.px-2.whitespace-nowrap.text-white.bg-blue-500.hover:bg-blue-400.border-blue-700.hover:border-blue-500.text-sm', {
                        target: '_blank',
                        href: 'https://app-prev.tobook.co.uk/' + invoice.legacyPDF
                      }, 'Download Invoice')
                        : m(Button, {
                          size: 'md',
                          classes: ['whitespace-nowrap', 'text-white', 'disabled'],
                          type: 'button',
                          loading: invoiceDownloading,
                          onclick: () => {
                            invoiceDownloading = true
                            getInvoicePdf(invoiceId).then(() => {
                              invoiceDownloading = false
                              loadActivity()
                            })
                          }
                        },
                        'Download Invoice') : '',
                    invoice.invoiceStatusId === 1 && !invoice.legacyPDF ? m(Button, {
                      size: 'md',
                      classes: ['whitespace-nowrap', 'text-white', invoice.invoiceStatusId === 1 ? 'disabled' : ''],
                      type: 'button',
                      onclick: () => {
                        emailOpen(true)
                      }
                    }, 'Email Invoice') : ''
                  ]))
                ])

              ])
            ])
          ])
        ]),

        m('.fixed.right-0.bottom-0.z-30.bg-white.flex.shadow-lg.activity-initial', {
          style: 'top: 63px',
          class: activityOpen ? 'w-64 xl:w-72 xxl:w-96 activity-slide-in' : 'w-8 activity-slide-in'
        }, [
          m('.w-8.flex.h-100.items-center.justify-center.cursor-pointer.flex-shrink-0.border-r', {
            onclick () {
              activityOpen = !activityOpen
            }
          }, [
            m(Svg, {
              classes: ['w-5', 'transform', 'rotate-90']
            }, moreIcon)
          ]),
          activityOpen && m('.w-full.flex.flex-col', [
            m('.flex.justify-between.items-end.p-3', [
              m(Heading, { level: 3 }, 'Activity'),
              m(Button, {
                type: 'button',
                size: 'xs',
                icon: addNoteIcon,
                onclick () {
                  addNoteOpen(true)
                }
              }, 'Add Note')
            ]),
            invoiceActivity
              ? invoiceActivity.length
                ? m('.flex.flex-col.border-t.h-full.overflow-auto', invoiceActivity.map(ea => {
                  return [
                    ea.emailMessage && m('div.p-1.flex.justify-start.items-center', {
                      class: ea.emailMessage.emailMessageStatusId === 0 ? 'bg-orange-300 text-orange-800' : ea.emailMessage.emailMessageStatusId === 1 ? 'bg-green-300 text-green-800' : 'bg-red-300 text-red-800'
                    }, [
                      m('button.hover:text-black', {
                        type: 'button',
                        onclick () {
                          currentEmail = ea.emailMessage
                          emailMessageOpen(true)
                        }
                      }, m(Svg, { classes: ['w-6', 'h-6', 'mx-1'] }, emailIcon)
                      ),
                      m('span.text-sm', ea.emailMessage.statusName),
                      ea.emailMessage.emailMessageStatusId !== 3 && m('span.text-xs.flex-grow.text-right', [
                        ea.emailMessage.sentDate && DateTime.fromISO(ea.emailMessage.sentDate).toLocaleString(DateTime.DATETIME_MED),
                        !ea.emailMessage.sentDate && timeUntilEmailSent(ea.emailMessage.createdDate)
                      ]),
                      ea.emailMessage.emailMessageStatusId === 0 && m('button.text-red-700.hover:text-red-900.text-xs.flex.items-center.ml-2.focus:bg-red-800.focus:text-red-200', {
                        type: 'button',
                        onclick () {
                          if (cancelEmailConfirm) { cancelEmail(ea.emailMessage.id) } else {
                            cancelEmailConfirm = true
                            setTimeout(() => {
                              cancelEmailConfirm = false
                              m.redraw()
                            }, 3000)
                          }
                        }
                      }, [
                        m(Svg, { classes: ['w-6', 'h-6'] }, cancelIcon),
                        cancelEmailConfirm && m('span.ml-1.px-1', 'Click again to confirm')
                      ]
                      )
                    ]),
                    ea.isModelUpdate ? '' : m(Activity, { activity: ea })
                  ]
                }))
                : m(EmptyState, { icon: alertIcon, message: t('activityBar', 'noActivity') })
              : m(LoadingFullScreen)
          ])
        ]),
        emailOpen() &&
        m(EmailInvoiceDialog, {
          open: emailOpen,
          onsubmit: sendEmail,
          loading: savingEmail,
          invoice
        }),
        addNoteOpen() &&
          m(NoteDialog, {
            open: addNoteOpen,
            onsubmit: addNote,
            loading: addingNote,
            invoice
          }),
        emailMessageOpen() &&
          m(EmailMessageDialog, {
            open: emailMessageOpen,
            email: currentEmail
          })
      ]
    }
  }
}
