import { Card } from 'components/Card'
import { Dialog } from 'components/Dialog'
import { Heading } from 'components/Heading'
import { Svg } from 'components/Svg'
import { DelegateRadioGroup } from '../DelegateRadioGroup'
import { DelegateTextArea } from '../DelegateTextArea'
import { LoadingFullScreen } from 'components/Loading/FullScreen'
import xIcon from 'assets/icons/solid/x.svg'
import locationIcon from 'assets/icons/solid/location-marker.svg'

import closeIcon from 'assets/close.svg'

const Venue = ({ attrs: { venue, index, selectedVenue, selectedRate, listDom, preferenceInfo, alt, setPreferenceInfo, altInfoAlert } }) => {
  if (selectedVenue() === venue.id) {
    selectedVenue(venue)
    selectedRate()
  }
  return {
    oncreate ({ dom }) {
      if (selectedVenue() === venue) {
        listDom().scrollTop = dom.offsetTop - 101 - 8
        // 101 for the modal header
        // 8px off the top so it doesn't look like there's nothing above it :)
      }
    },
    view () {
      return m('.flex.w-full.flex-col.border-b.delegate-venue', {
        class: [
          selectedVenue() === venue ? 'active' : '',
          alt ? '' : ''
        ].join(' ')
      }, [
        m('.flex.w-full.flex-wrap.items-center.py-4.px-3.md:px-6[role="button"].hover:bg-teal-100', {
          onclick () {
            if (selectedVenue() === venue) {
              selectedVenue(false)
              selectedRate(false)
            } else {
              selectedVenue(venue)
              if (venue.name === 'Alternative Location / Hotel' || venue.rates.length === 1) { selectedRate(venue.rates[0].id) } else { selectedRate(false) }
            }
          }
        }, [
          m('.flex.items-center.md:w-1/3.pr-2', [
            m('.rounded-full.h-6.w-6.flex.text-center.items-center.justify-center.text-xs.mr-6.flex-shrink-0.user-select-none', {
              class: selectedVenue() === venue ? 'bg-cyan' : 'bg-gray-300'
            }, alt ? '-' : (index + 1)),
            m('.text-xs', venue.townCity)
          ]),
          m('div.w-full.mt-4.md:mt-0.md:w-auto', venue.name)
        ]),
        selectedVenue() === venue && m('.flex.flex-wrap.w-full.md:pb-4.px-3', [
          venue.addressLine1 && m('p.w-full.flex.text-sm.mb-4', {
            style: 'padding-left: 33.333%'
          }, [
            m(Svg, { classes: ['h-5', 'w-5', 'mr-2'] }, locationIcon),
            venue.addressLine1,
            venue.addressLine2 && `, ${venue.addressLine2}`,
            venue.postCode && `, ${venue.postCode}`
          ]),
          m('.w-full.md:w-1/3', [
            m('.mr-6.text-sm.md:text-right.md:mt-2', `${t('delegateBooking', 'options')}:`)
          ]),
          m('.flex.flex-col.grow', [
            m(DelegateRadioGroup, {
              value: selectedRate(),
              options: venue.rates.map(v => {
                return {
                  label: v.label,
                  value: v.id,
                  price: (v.tariff > 1 ? `${venue.currency.symbol}` + v.tariff : '')
                }
              }),
              onchange (val) {
                selectedRate(val)
              }
            })
          ]),
          alt && m('div.w-full'),
          alt && m('.w-full.md:w-1/3', [
            m('.mr-6.text-sm.md:text-right.md:mt-2', 'Alternative Hotel Notes')
          ]),
          alt && m('.flex.flex-col.flex-grow', [
            m(DelegateTextArea, {
              label: '',
              placeholder: 'Please provide further details...',
              value: preferenceInfo,
              errorMessage: altInfoAlert() ? 'Please give further details' : null,
              oninput: (val) => {
                preferenceInfo = val
                setPreferenceInfo(val)
              }
            })
          ])
        ])
      ])
    }
  }
}

const VenueSelectDialog = ({ attrs: { open, dialogTitle, buttonText, venues, value, onchange, preferenceInfo, location, other } }) => {
  const selectedVenue = flyd.stream(value ? value.venue : false)
  const selectedRate = flyd.stream(value ? value.rate : false)
  const listDom = flyd.stream()
  const setPreferenceInfo = (val) => {
    preferenceInfo = val
  }
  const altInfoAlert = flyd.stream(false)
  return {
    view () {
      return m(Dialog, { open, maxScreen: true },
        m(Card, {
          classes: ['w-192', 'font-apercu', 'overflow-hidden', 'max-h-full']
        }, [
          venues
            ? m('.flex.flex-col.h-full.max-h-full.relative', {
              style: {
                maxHeight: 'calc(100vh - 5rem)'
              }
            }, [
              m('.py-5.px-3.md:py-8.md:px-5.border-b', [
                m(Heading, { level: 'delegate' }, dialogTitle)
              ]),
              m('div.hidden.sm:block.absolute.top-0.right-0.pt-4.pr-4',
                m('button.bg-white.rounded-md.text-gray-400.hover:text-gray-500.focus:outline-none.focus:ring-2.focus:ring-offset-2.focus:ring-indigo-500', {
                  type: 'button',
                  onclick () {
                    open(false)
                  }
                },
                [
                  m('span.sr-only',
                    'Close'
                  ),
                  m(Svg, { classes: ['h-6', 'w-6'] }, xIcon)
                ])
              ),
              m('.overflow-auto.flex-1', {
                oncreate ({ dom }) {
                  listDom(dom)
                }
              }, [
                m('.flex.flex-col.flex-1.overflow-auto', [
                  venues.filter(v => {
                    return v.townCity.toLowerCase() === location.toLowerCase() && v.id !== (other ? other.venue : 0)
                  }).map((venue, index) => {
                    return m(Venue, { venue, index, selectedVenue, selectedRate, listDom, alt: false, altInfoAlert })
                  }),
                  venues.filter(v => {
                    return v.name === 'Alternative Location / Hotel'
                  }).map((venue, index) => {
                    return m(Venue, { venue, index: 0, selectedVenue, selectedRate, preferenceInfo, listDom, alt: true, setPreferenceInfo, altInfoAlert })
                  })
                  // venues().filter(v => {
                  //   return v.townCity !== location && v.id !== (other ? other.venue : 0)
                  // }).sort((a, b) => (a.townCity > b.townCity) ? 1 : ((b.townCity > a.townCity) ? -1 : 0)).map((venue, index) => {
                  //   return m(Venue, { venue, index: index + 1, selectedVenue, selectedRate, listDom })
                  // })
                ])
              ]),
              m('.w-full.h-16.mt-4', [
                m('button.text-white.h-16.w-full', {
                  type: 'button',
                  disabled: !selectedVenue() && !selectedRate(),
                  class: selectedVenue() && selectedRate() ? 'bg-cyan' : 'bg-gray-300',
                  onclick (e) {
                    e.preventDefault()
                    if (selectedVenue().name === 'Alternative Location / Hotel' && !preferenceInfo) {
                      altInfoAlert(true)
                    } else {
                      onchange({
                        venue: selectedVenue().id,
                        rate: selectedRate(),
                        alt: selectedVenue().name === 'Alternative Location / Hotel'
                      }, preferenceInfo)
                      open(false)
                    }
                  }
                }, buttonText)
              ])
            ])
            : m(LoadingFullScreen)
        ])
      )
    }
  }
}

export const VenueSelect = () => {
  const isOpen = flyd.stream(false)
  return {
    view ({ attrs: { value, venues, label, dialogTitle, buttonText, changeText, disabled, onchange, location, other, preferenceInfo } }) {
      return m('', [
        m('.flex.border-t.flex-wrap', {
          style: {
            minHeight: '60px'
          }
        },
        value
          ? [
            m('.text-sm.pl-5.pr-2.w-full.flex.items-center', {
              style: {
                minHeight: '60px'
              }
            }, label),
            venues && m('.pl-10.flex.w-full.pb-5.pr-5.justify-between.items-center', [
              m('div', [
                m('div', venues.find(v => {
                  return v.id === value.venue
                }).name),
                m('.text-sm', venues.find(v => {
                  return v.id === value.venue
                }).rates.find(r => {
                  return r.id === value.rate
                }).label),
                m('button.text-cyan.text-sm', {
                  onclick (e) {
                    e.preventDefault()
                    isOpen(true)
                  }
                }, changeText)
              ]),
              m('button.rounded-full.bg-cyan.h-8.w-8.text-white.flex.items-center.justify-center.flex-shrink-0', {
                onclick (e) {
                  e.preventDefault()
                  onchange(false)
                }
              }, m(Svg, {
                classes: ['w-3']
              }, closeIcon))
            ])

          ]
          : [
            m('.w-1/2.border-r.flex.items-center.text-sm.pl-5.pr-2.', label),
            m('.w-1/2', [
              m('button.h-full.w-full.text-white', {
                class: disabled ? 'bg-gray-300' : 'bg-cyan',
                disabled,
                onclick (e) {
                  e.preventDefault()
                  isOpen(true)
                }
              }, t('delegateBooking', 'chooseHotel'))
            ])
          ]),
        isOpen() && [m(VenueSelectDialog, {
          key: venues ? venues.length : 'v',
          open: isOpen,
          dialogTitle,
          buttonText,
          venues,
          value,
          onchange,
          location,
          other,
          preferenceInfo
        })]
      ])
    }
  }
}
