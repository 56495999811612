import { Card } from 'components/Card'
import { Button } from 'components/Button'

export const EditFormSection = () => {
  return {
    view ({ children, attrs: { heading, description, extra, buttons, loading } }) {
      return [m('.md:col-span-1',
        m('div.px-4.sm:px-0',
          [
            m('h3.text-lg.font-medium.leading-6.text-gray-900',
              heading
            ),
            description && m('p.mt-1.text-sm.text-gray-600',
              description
            ),
            extra && m('p.mt-1.text-xs.text-gray-500',
              extra
            )
          ]
        )
      ),
      m('.mt-5.md:mt-0.md:col-span-2.lg:col-span-3', [
        m(Card, {
          buttons: buttons || [m(Button, {
            type: 'button',
            variant: 'warning',
            onclick () {
              history.back()
            }
          }, t('app', 'cancel')), m(Button, {
            type: 'submit',
            variant: 'positive',
            loading
          }, t('app', 'save'))],
          buttonJustify: 'justify-between'
        }, m('.space-y-6.divide-y.divide-gray-200', [
          children
        ]))
      ])
      ]
    }
  }
}
