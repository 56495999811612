
import { Svg } from 'components'

import { DateTime } from 'luxon'
import arrowRight from 'assets/arrow-right.svg'
import important from 'assets/icons/solid/exclamation-circle.svg'

const ActivityModelUpdate = ({ attrs: { activityString } }) => {
  const activity = JSON.parse(activityString)
  return {
    view () {
      return m('.outer', activity.map(a => {
        return m('.activity', [
          m('strong.block', a.Id + ':'),
          m('.flex.items-center.mb-2', [
            m('span.line-through.text-gray-600', a.OldValue),
            m(Svg, {
              classes: ['w-3', 'mx-2']
            }, arrowRight),
            m('span', a.NewValue)
          ])
        ])
      }))
    }
  }
}

export const Activity = () => {
  return {
    view ({ attrs: { activity } }) {
      return m('.flex.border-b.py-2.px-1', {
        class: activity.emailMessage && activity.emailMessage.emailMessageStatusId === 0 ? 'bg-orange-100' : activity.emailMessage && activity.emailMessage.emailMessageStatusId === 1 ? 'bg-green-100' : activity.emailMessage ? 'bg-red-50' : activity.isImportant ? 'bg-red-100 text-red-900 border-red-900' : 'bg-white'
      }, [
        // m(UserAvatar, { user: activity.userFullName, classes: ['mt-1'] }),
        m('.text-sm.ml-2.flex.flex-col.w-full', [
          m('.mb-2', [
            m('.flex.justify-between', [
              activity.type && m('p.text-gray-400.uppercase.text-xs', activity.type),
              activity.isImportant && m(Svg, { classes: ['w-4'] }, important)
            ]),
            m('div', [
              activity.isModelUpdate ? m(ActivityModelUpdate, { activityString: activity.description }) : m.trust(activity.description)
            ])
          ]),
          m('p.text-gray-500.text-xs.flex.justify-between', [
            m('span.font-bold', (activity.userFullName
              ? activity.userFullName
              : 'N/A')),
            DateTime.fromISO(activity.activityDate).toLocaleString(DateTime.DATETIME_MED)
          ])
        ])
      ])
    }
  }
}
