import { Layout } from 'components/Layout'
import { Register } from 'pages/Register'
import { Login } from 'pages/Login'
import { ForgottenPassword } from 'pages/ForgottenPassword'
import { ResetPassword } from 'pages/ResetPassword'
import { SetPassword } from 'pages/SetPassword'
import { AccommodationDashboard } from 'pages/AccommodationDashboard'
import { Venues } from 'pages/Venues'
import { CreateVenue } from 'pages/Venues/CreateVenue'
import { ViewVenue } from 'pages/Venues/ViewVenue'
import { EditVenue } from 'pages/Venues/EditVenue'
import { EditVenueContact } from 'pages/Venues/EditContact'
import { Rates } from 'pages/Rates'
import { EditRate } from 'pages/Rates/EditRate'
import { Clients } from 'pages/Clients'
import { ViewClient } from 'pages/Clients/ViewClient'
import { EditClient } from 'pages/Clients/EditClient'
import { Departments } from 'pages/Departments'
import { ViewDepartment } from 'pages/Departments/ViewDepartment'
import { EditDepartment } from 'pages/Departments/EditDepartment'
import { BookingFields } from 'pages/Departments/BookingFields'
import { EditDepartmentVenue } from 'pages/Departments/EditDepartmentVenue'
import { EditDepartmentVenueRate } from 'pages/Departments/EditDepartmentVenueRate'
import { EditDepartmentVenueMeetingRate } from 'pages/Departments/EditDepartmentVenueMeetingRate'
import { EditDepartmentVenueNote } from 'pages/Departments/EditDepartmentVenueNote'
import { Settings } from 'pages/Settings'
import { Invoices } from 'pages/Invoices'
import { ViewInvoice } from 'pages/Invoices/ViewInvoice'
import { EditInvoice } from 'pages/Invoices/EditInvoice'
import { EmailTemplates } from 'pages/EmailTemplates'
import { EditEmailTemplate } from 'pages/EmailTemplates/EditEmailTemplate'
import { VenueGroups } from 'pages/VenueGroups'
import { EditVenueGroup } from 'pages/VenueGroups/EditVenueGroup'
import { Users } from 'pages/Users'
import { ViewUser } from 'pages/Users/ViewUser'
import { CreateUser } from 'pages/Users/CreateUser'
import { Logout } from 'pages/Logout'
import { DelegateBooking } from 'pages/DelegateBooking'
import { ViewBooking } from 'pages/Bookings/ViewBooking'
import { CreateBooking } from 'pages/Bookings/CreateBooking'
import { EditBooking } from 'pages/Bookings/EditBooking'
import { EditMeeting } from 'pages/Meetings/EditMeeting'
import { EditMeetingRates } from 'pages/Meetings/EditMeetingRates'
import { ViewMeeting } from 'pages/Meetings/ViewMeeting'
import { EditDelegateUser } from 'pages/Departments/EditDelegateUser'
import { EditDepartmentUser } from 'pages/Departments/EditDepartmentUser'
import { EditClientUser } from 'pages/Clients/EditClientUser'
import { EditDepartmentBillingInstruction } from 'pages/Departments/EditDepartmentBillingInstruction'
import { CreateInvoiceRun } from 'pages/Invoices/CreateInvoiceRun'
import { Search } from 'pages/Search'
import { SearchMeetings } from 'pages/SearchMeetings'
import { ClientHome } from 'pages/ClientHome'
import { ClientSearch } from 'pages/ClientSearch'
import { Emails } from 'pages/Emails'

import { getBooking } from 'api/bookings'
import { getMeeting } from 'api/meetings'
import { MeetingsDashboard } from './pages/MeetingsDashboard'

m.route.prefix = ''

const Routes = {
  '/register': {
    render (vnode) {
      return m(Layout, {
        hideNav: true
      }, [
        m(Register, vnode.attrs)
      ])
    }
  },
  '/login': {
    render (vnode) {
      return m(Layout, {
        hideNav: true,
        allowDelegate: true
      }, [
        m(Login, vnode.attrs)
      ])
    }
  },
  '/forgotten-password': {
    render (vnode) {
      return m(Layout, {
        hideNav: true
      }, [
        m(ForgottenPassword, vnode.attrs)
      ])
    }
  },
  '/reset-password': {
    render (vnode) {
      return m(Layout, {
        hideNav: true
      }, [
        m(ResetPassword, vnode.attrs)
      ])
    }
  },
  '/set-password': {
    render (vnode) {
      return m(Layout, {
        hideNav: true
      }, [
        m(SetPassword, vnode.attrs)
      ])
    }
  },
  '/': {
    render (vnode) {
      return m(Layout, m(AccommodationDashboard, {
        ...vnode.attrs
      }))
    }
  },
  '/searchmeetings': {
    render (vnode) {
      return m(Layout, m(SearchMeetings, {
        ...vnode.attrs
      }))
    }
  },
  '/searchaccommodation': {
    render (vnode) {
      return m(Layout, m(Search, {
        ...vnode.attrs
      }))
    }
  },
  '/emails': {
    render (vnode) {
      return m(Layout, m(Emails, {
        ...vnode.attrs
      }))
    }
  },
  '/venues': {
    render (vnode) {
      return m(Layout, m(Venues, {
        ...vnode.attrs
      }))
    }
  },
  '/venues/create': {
    render (vnode) {
      return m(Layout, m(CreateVenue, {
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId': {
    render (vnode) {
      return m(Layout, m(ViewVenue, {
        key: vnode.attrs.venueId,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/edit': {
    render (vnode) {
      return m(Layout, m(EditVenue, {
        key: vnode.attrs.venueId,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/contacts/create': {
    render (vnode) {
      return m(Layout, m(EditVenueContact, {
        key: 0,
        contactId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/contacts/:contactId': {
    render (vnode) {
      return m(Layout, m(EditVenueContact, {
        key: vnode.attrs.contactId,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/departments/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenue, {
        key: 0,
        departmentVenueId: 0,
        clientId: 0,
        departmentId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/departments/:departmentId/:departmentVenueId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenue, {
        key: vnode.attrs.departmentVenueId,
        clientId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/departments/:departmentId/:departmentVenueId/rates/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueRate, {
        key: 0,
        departmentVenueRateId: 0,
        clientId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/departments/:departmentId/:departmentVenueId/rates/:departmentVenueRateId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueRate, {
        key: vnode.attrs.departmentVenueRateId,
        clientId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/departments/:departmentId/:departmentVenueId/meetingrates/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueMeetingRate, {
        key: 0,
        departmentVenueRateId: 0,
        clientId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/departments/:departmentId/:departmentVenueId/meetingrates/:departmentVenueRateId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueMeetingRate, {
        key: vnode.attrs.departmentVenueRateId,
        clientId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/departments/:departmentId/:departmentVenueId/notes/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueNote, {
        key: 0,
        departmentVenueNoteId: 0,
        clientId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venues/:venueId/departments/:departmentId/:departmentVenueId/notes/:departmentVenueNoteId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueNote, {
        key: vnode.attrs.departmentVenueNoteId,
        clientId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/rate-types': {
    render (vnode) {
      return m(Layout, m(Rates, {
        ...vnode.attrs
      }))
    }
  },
  '/rate-types/createaccommodation': {
    render (vnode) {
      return m(Layout, m(EditRate, {
        key: 0,
        bookingType: 1,
        ...vnode.attrs
      }))
    }
  },
  '/rate-types/createmeeting': {
    render (vnode) {
      return m(Layout, m(EditRate, {
        key: 0,
        bookingType: 2,
        ...vnode.attrs
      }))
    }
  },
  '/rate-types/:id': {
    render (vnode) {
      return m(Layout, m(EditRate, {
        key: vnode.attrs.id,
        ...vnode.attrs
      }))
    }
  },
  '/clients': {
    render (vnode) {
      return m(Layout, m(Clients, {
        ...vnode.attrs
      }))
    }
  },
  '/clients/create': {
    render (vnode) {
      return m(Layout, m(EditClient, {
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId': {
    render (vnode) {
      return m(Layout, m(ViewClient, {
        key: vnode.attrs.clientId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/edit': {
    render (vnode) {
      return m(Layout, m(EditClient, {
        key: vnode.attrs.clientId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/users/create': {
    render (vnode) {
      return m(Layout, m(EditClientUser, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/users/:userId': {
    render (vnode) {
      return m(Layout, m(EditClientUser, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/create': {
    render (vnode) {
      return m(Layout, m(EditDepartment, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId': {
    render (vnode) {
      return m(Layout, m(ViewDepartment, {
        key: vnode.attrs.departmentId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/edit': {
    render (vnode) {
      return m(Layout, m(EditDepartment, {
        key: vnode.attrs.departmentId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/bookingfields': {
    render (vnode) {
      return m(Layout, m(BookingFields, {
        key: vnode.attrs.departmentId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/delegate-users/create': {
    render (vnode) {
      return m(Layout, m(EditDelegateUser, {
        key: 0,
        userId: '',
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/delegate-users/:userId': {
    render (vnode) {
      return m(Layout, m(EditDelegateUser, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/users/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentUser, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/users/:userId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentUser, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/billing/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentBillingInstruction, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/billing/:billingInstructionId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentBillingInstruction, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },

  '/clients/:clientId/departments/:departmentId/venues/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenue, {
        key: 0,
        departmentVenueId: 0,
        venueId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/venues/:departmentVenueId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenue, {
        key: vnode.attrs.departmentVenueId,
        venueId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/venues/:departmentVenueId/rates/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueRate, {
        key: 0,
        venueId: 0,
        departmentVenueRateId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/venues/:departmentVenueId/rates/:departmentVenueRateId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueRate, {
        key: vnode.attrs.departmentVenueRateId,
        venueId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/venues/:departmentVenueId/meetingrates/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueMeetingRate, {
        key: 0,
        venueId: 0,
        departmentVenueMeetingRateId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/venues/:departmentVenueId/meetingrates/:departmentVenueMeetingRateId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueMeetingRate, {
        key: vnode.attrs.departmentVenueMeetingRateId,
        venueId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/venues/:departmentVenueId/notes/create': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueNote, {
        key: 0,
        venueId: 0,
        departmentVenueNoteId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/venues/:departmentVenueId/notes/:departmentVenueNoteId': {
    render (vnode) {
      return m(Layout, m(EditDepartmentVenueNote, {
        key: vnode.attrs.departmentVenueNoteId,
        venueId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/bookings/create': {
    render (vnode) {
      return m(Layout, m(CreateBooking, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/bookings/:bookingId/edit': {
    render (vnode) {
      return m(Layout, m(EditBooking, {
        key: vnode.attrs.bookingId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/bookings/:bookingId/copy': {
    render (vnode) {
      return m(Layout, m(EditBooking, {
        key: vnode.attrs.bookingId,
        copy: true,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/bookings/:bookingId': {
    render (vnode) {
      return m(Layout, m(ViewBooking, {
        key: vnode.attrs.bookingId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/meetings/create': {
    render (vnode) {
      return m(Layout, m(EditMeeting, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/meetings/:meetingId': {
    render (vnode) {
      return m(Layout, m(ViewMeeting, {
        key: vnode.attrs.meetingId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/meetings/:meetingId/copy': {
    render (vnode) {
      return m(Layout, m(EditMeeting, {
        key: vnode.attrs.meetingId,
        copy: true,
        ...vnode.attrs
      }))
    }
  },
  '/bookings/:bookingId': {
    render (vnode) {
      getBooking(vnode.attrs.bookingId).then(data => {
        m.route.set(`/clients/${data.department.clientId}/departments/${data.departmentId}/bookings/${data.id}`, {}, {
          replace: true
        })
      })
    }
  },
  '/meetings/:meetingId': {
    render (vnode) {
      getMeeting(vnode.attrs.meetingId).then(data => {
        m.route.set(`/clients/${data.department.clientId}/departments/${data.departmentId}/meetings/${data.id}`, {}, {
          replace: true
        })
      })
    }
  },
  '/clients/:clientId/departments/:departmentId/meetings/:meetingId/edit': {
    render (vnode) {
      return m(Layout, m(EditMeeting, {
        key: vnode.attrs.meetingId,
        ...vnode.attrs
      }))
    }
  },
  '/clients/:clientId/departments/:departmentId/meetings/:meetingId/editrates': {
    render (vnode) {
      return m(Layout, m(EditMeetingRates, {
        key: vnode.attrs.meetingId,
        ...vnode.attrs
      }))
    }
  },
  '/meetings': {
    render (vnode) {
      return m(Layout, m(MeetingsDashboard, {
        ...vnode.attrs
      }))
    }
  },
  '/departments': {
    render (vnode) {
      return m(Layout, m(Departments, {
        ...vnode.attrs
      }))
    }
  },
  '/invoices/create-run': {
    render (vnode) {
      return m(Layout, m(CreateInvoiceRun, {
        key: 0,
        invoiceRunId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/invoices/create': {
    render (vnode) {
      return m(Layout, m(EditInvoice, {
        key: 0,
        invoiceId: 0,
        ...vnode.attrs
      }))
    }
  },
  '/invoices/:invoiceId/edit': {
    render (vnode) {
      return m(Layout, m(EditInvoice, {
        key: vnode.attrs.invoiceId,
        ...vnode.attrs
      }))
    }
  },
  '/invoices/:invoiceId': {
    render (vnode) {
      return m(Layout, m(ViewInvoice, {
        key: vnode.attrs.invoiceId,
        ...vnode.attrs
      }))
    }
  },
  '/invoices': {
    render (vnode) {
      return m(Layout, m(Invoices, {
        ...vnode.attrs
      }))
    }
  },
  '/email-templates': {
    render (vnode) {
      return m(Layout, m(EmailTemplates, {
        ...vnode.attrs
      }))
    }
  },
  '/email-templates/create': {
    render (vnode) {
      return m(Layout, m(EditEmailTemplate, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/email-templates/:id': {
    render (vnode) {
      return m(Layout, m(EditEmailTemplate, {
        key: vnode.attrs.id,
        ...vnode.attrs
      }))
    }
  },
  '/venue-groups': {
    render (vnode) {
      return m(Layout, m(VenueGroups, {
        ...vnode.attrs
      }))
    }
  },
  '/venue-groups/create': {
    render (vnode) {
      return m(Layout, m(EditVenueGroup, {
        key: 0,
        ...vnode.attrs
      }))
    }
  },
  '/venue-groups/:id': {
    render (vnode) {
      return m(Layout, m(EditVenueGroup, {
        key: vnode.attrs.id,
        ...vnode.attrs
      }))
    }
  },
  '/users': {
    render (vnode) {
      return m(Layout, m(Users, {
        ...vnode.attrs
      }))
    }
  },
  '/users/create': {
    render (vnode) {
      return m(Layout, m(CreateUser, {
        ...vnode.attrs
      }))
    }
  },
  '/users/:id': {
    render (vnode) {
      return m(Layout, m(ViewUser, {
        ...vnode.attrs
      }))
    }
  },
  '/settings': {
    render (vnode) {
      return m(Layout, m(Settings, {
        ...vnode.attrs
      }))
    }
  },
  '/booking': {
    render (vnode) {
      return m(Layout, {
        hideNav: true,
        allowDelegate: true
      }, m(DelegateBooking, {
        ...vnode.attrs
      }))
    }
  },
  '/client/dashboard': {
    render (vnode) {
      return m(Layout, m(ClientHome, {
        ...vnode.attrs
      }))
    }
  },
  '/client/search': {
    render (vnode) {
      return m(Layout, m(ClientSearch, {
        ...vnode.attrs
      }))
    }
  },
  '/logout': Logout
}

const DefaultRoute = '/'

export { Routes, DefaultRoute }
