import { Breadcrumb } from 'components/Breadcrumb'
import { Input } from 'components/Input'
// import { RadioGroup } from 'components/RadioGroup'
import { Select } from 'components'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { getRate, updateRate, createRate } from 'api/rates'
import { notification } from 'utils/notifications'

export const EditRate = ({ attrs: { id, bookingType } }) => {
  let ready
  let rate
  if (id) {
    getRate(id).then(data => {
      rate = data
      ready = true
    })
  } else {
    rate = { bookingType: bookingType, bookingTypeDescription: bookingType === 1 ? t('app', 'accommodation') : t('app', 'meetings'), name: '' }
    ready = true
  }

  const update = (e) => {
    e.preventDefault()
    if (id) {
      updateRate(rate.id, rate).then(() => {
        notification.success({ title: t('rates', 'updated') })
        m.route.set('/rate-types')
      })
    } else {
      createRate(rate).then(() => {
        notification.success({ title: t('rates', 'created') })
        m.route.set('/rate-types')
      })
    }
  }

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Rates',
            href: '/rate-types'
          }, {
            title: !id ? 'Create' : (ready ? `Edit ${rate.name}` : 'Edit')
          }]
        }),
        ready && m(EditForm, {
          onsubmit: update
        },
        [
          m(EditFormSection, {
            heading: id ? 'Edit Rate' : 'Create Rate',
            description: !id && 'Please enter the rate name and scope.'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6',
                [
                  m(Input, {
                    label: 'Booking type',
                    value: rate.bookingTypeDescription,
                    type: 'readonly'
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(Input, {
                    label: 'Name',
                    value: rate.name,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      rate.name = value
                    }
                  })
                ]
              ),
              rate.bookingType === 1 ? null
                : m('div.col-span-2',
                  [
                    m(Select, {
                      label: 'Type',
                      removeItemButton: false,
                      search: false,
                      required: true,
                      value: rate.rateMode,
                      onchange (e) {
                        rate.rateMode = parseInt(e)
                      },
                      choices: [
                        { label: 'Day Rate', value: 0 },
                        { label: 'Delegate Rate', value: 1 },
                        { label: 'Delegate Day Rate', value: 2 },
                        { label: 'Unit Rate', value: 3 },
                        { label: 'Flat Rate', value: 4 }
                      ]
                    })
                  ]
                )
            ])
          ])
        ])
      ]
    }
  }
}
