
// import { Select } from 'components/Select'
import { Input, CurrencyInput, Checkbox, Select, Dialog2 } from 'components'
import { updateMeetingRate, addMeetingRate } from 'api/meetings'
import { notification } from 'utils/notifications'
import { getDepartmentVenueMeetingRates } from 'api/venues'
import { getRates } from 'api/rates'
import { formatCurrency } from 'utils'
// import ratesIcon from 'assets/icons/outline/currency-pound.svg'

export const EditRateDialog = ({ attrs: { meeting, meetingRate, loading, onsubmit } }) => {
  loading = false

  let venueRates = []
  let baseRates = []

  let selectRateType

  let defaultRateOptionOpen = false
  let venueRateOptionOpen = false

  const formData = {
    label: meetingRate ? meetingRate.label : '',
    tariff: meetingRate ? meetingRate.tariff : null,
    rateMode: meetingRate ? meetingRate.rateMode : 0,
    rateUnitQuantity: meetingRate ? meetingRate.rateUnitQuantity : 0,
    overrideCommission: meetingRate ? meetingRate.overrideCommission : false,
    commissionRate: meetingRate ? meetingRate.commissionRate : 0,
    commissionRatePercent: meetingRate ? (meetingRate.commissionRate * 100).toFixed(2) : 0
  }

  const saveMeetingRate = (btnsubmit, reopen) => {
    loading = true
    if (meetingRate) {
      updateMeetingRate(meeting.id, meetingRate.id, formData).then(data => {
        notification.success({ title: 'Rate saved' })
        if (btnsubmit) {
          btnsubmit(reopen)
        }
      }).catch(err => {
        notification.error({ title: err.response })
        loading = false
      })
    } else {
      addMeetingRate(meeting.id, formData).then((data) => {
        notification.success({ title: 'Rate created' })
        if (btnsubmit) {
          btnsubmit(reopen)
        }
      }).catch(err => {
        notification.error({ title: err.response })
        loading = false
      })
    }
  }
  // getMeetingRate(meetingRateId).then(data => {
  //   meetingRate = data
  // })

  if (meeting.departmentVenue) {
    getDepartmentVenueMeetingRates(meeting.departmentId, meeting.departmentVenue.id).then(data => {
      venueRates = data
    })
  }

  const ratesFilters = { bookingType: 2, pagesize: 999 }
  getRates(ratesFilters).then(data => {
    baseRates = data.data
  })

  return {
    view ({ attrs: { meeting, meetingRate, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        action: meetingRate ? null : () => {
          saveMeetingRate(onsubmit, true)
        },
        actionLabel: 'Add',
        save () {
          saveMeetingRate(onsubmit, false)
        },
        saveLabel: meetingRate ? 'Save' : 'Add and Close',
        heading: meetingRate ? 'Edit Meeting Rate' : 'Add Meeting Rate',
        saving: loading
      }, [
        m('.grid.grid-cols-4.gap-x-3', [
          m('.col-span-4', [
            m(Input, {
              label: 'Venue',
              value: meeting.departmentVenue ? meeting.departmentVenue.venue.name : '',
              type: 'readonly'
            })
          ]),
          // Venue Rate Select
          venueRates && venueRates.length > 0
            ? m('.col-span-2.pt-4', [
              m('div.relative.inline-block.text-left',
                [
                  m('div',
                    m("button.inline-flex.justify-center.w-full.rounded-md.border.border-gray-300.shadow-sm.px-4.py-2.bg-white.text-sm.font-medium.text-gray-700.hover:bg-gray-50.focus:outline-none.focus:ring-2.focus:ring-offset-2.focus:ring-offset-gray-100.focus:ring-indigo-500[type='button'][id='menu-button'][aria-expanded='true'][aria-haspopup='true']", {
                      onclick () {
                        venueRateOptionOpen = !venueRateOptionOpen
                      }
                    },
                    [
                      'Select Venue Rate',
                      m("svg.-mr-1.ml-2.h-5.w-5[xmlns='http://www.w3.org/2000/svg'][viewBox='0 0 20 20'][fill='currentColor'][aria-hidden='true']",
                        m("path[fill-rule='evenodd'][d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'][clip-rule='evenodd']")
                      )
                    ]
                    )
                  ),
                  venueRateOptionOpen && m(".origin-top-right.absolute.z-50.right-0.mt-2.w-56.rounded-md.shadow-lg.bg-white.ring-1.ring-gray-400.ring-opacity-5.divide-y.divide-gray-100.focus:outline-none[role='menu'][aria-orientation='vertical'][aria-labelledby='menu-button'][tabindex='-1']",
                    [

                      m(".py-1[role='none']",
                        [
                          venueRates.map(dvr => {
                            return m("a.text-gray-700.block.px-4.py-2.text-sm.hover:bg-gray-200[href='#'][role='menuitem'][tabindex='-1'][id='menu-item-0']", {
                              onclick (e) {
                                e.preventDefault()
                                formData.label = dvr.label
                                formData.tariff = dvr.tariff
                                formData.overrideCommission = dvr.overrideCommission
                                if (formData.overrideCommission) {
                                  formData.commissionRate = dvr.commissionRate
                                  formData.commissionRatePercent = formData.commissionRate * 100
                                }
                                formData.rateMode = dvr.rateMode
                                if (selectRateType) {
                                  selectRateType.setChoiceByValue(dvr.rateMode)
                                }
                                venueRateOptionOpen = false
                              }
                            }, [
                              m('', dvr.label),
                              m('', formatCurrency(dvr.tariff, dvr.currency))
                            ])
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]) : null,
          // Basic Rate Select
          baseRates && baseRates.length > 0
            ? m('.col-span-2.pt-4', [
              m('div.relative.inline-block.text-left',
                [
                  m('div',
                    m("button.inline-flex.justify-center.w-full.rounded-md.border.border-gray-300.shadow-sm.px-4.py-2.bg-white.text-sm.font-medium.text-gray-700.hover:bg-gray-50.focus:outline-none.focus:ring-2.focus:ring-offset-2.focus:ring-offset-gray-100.focus:ring-indigo-500[type='button'][id='menu-button'][aria-expanded='true'][aria-haspopup='true']", {
                      onclick () {
                        defaultRateOptionOpen = !defaultRateOptionOpen
                      }
                    },
                    [
                      'Select Default Rate',
                      m("svg.-mr-1.ml-2.h-5.w-5[xmlns='http://www.w3.org/2000/svg'][viewBox='0 0 20 20'][fill='currentColor'][aria-hidden='true']",
                        m("path[fill-rule='evenodd'][d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'][clip-rule='evenodd']")
                      )
                    ]
                    )
                  ),
                  defaultRateOptionOpen && m(".origin-top-right.absolute.z-50.right-0.mt-2.w-56.rounded-md.shadow-lg.bg-white.ring-1.ring-gray-400.ring-opacity-5.divide-y.divide-gray-100.focus:outline-none[role='menu'][aria-orientation='vertical'][aria-labelledby='menu-button'][tabindex='-1']",
                    [

                      m(".py-1[role='none']",
                        [
                          baseRates.map(r => {
                            return m("a.text-gray-700.block.px-4.py-2.text-sm.hover:bg-gray-200[href='#'][role='menuitem'][tabindex='-1'][id='menu-item-0']", {
                              onclick (e) {
                                e.preventDefault()
                                formData.label = r.name
                                formData.rateMode = r.rateMode
                                if (selectRateType) {
                                  selectRateType.setChoiceByValue(r.rateMode)
                                }
                                defaultRateOptionOpen = false
                              }
                            }, [
                              m('', r.name)
                            ])
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]) : null,
          m('.col-span-4.pt-4', [
            m(Input, {
              label: 'Rate',
              value: formData.label,
              type: 'text',
              required: true,
              oninput (value) {
                formData.label = value
              }
            })
          ]),
          m('.col-span-4.pt-4', [
            m(Select, {
              label: 'Type',
              removeItemButton: false,
              search: false,
              required: true,
              value: formData.rateMode,
              onchange (e) {
                formData.rateMode = parseInt(e)
              },
              instance (obj) {
                selectRateType = obj
              },
              choices: [
                { label: 'Day Rate', value: 0 },
                { label: 'Delegate Rate', value: 1 },
                { label: 'Delegate Day Rate', value: 2 },
                { label: 'Unit Rate', value: 3 },
                { label: 'Flat Rate', value: 4 }
              ]
            })
          ]),
          formData.rateMode === 3
            ? m('.col-span-4.pt-4', [m(Input, {
              label: 'Quantity',
              value: formData.rateUnitQuantity,
              type: 'number',
              min: 0,
              step: '1',
              required: true,
              oninput (value) {
                formData.rateUnitQuantity = value
              }
            })
            ]) : null,
          m('.col-span-2.pt-4', [
            m(CurrencyInput, {
              label: 'Tariff',
              value: formData.tariff,
              type: 'number',
              min: 0,
              step: '.01',
              required: true,
              currency: meeting.departmentVenue && meeting.departmentVenue.venue ? meeting.departmentVenue.venue.currency : null,
              oninput (value) {
                formData.tariff = value
              }
            })
          ]),
          m('.col-span-2'),
          m('.col-span-4.pt-4', [
            m(Checkbox, {
              label: 'Override Commission Rate?',
              checked: formData.overrideCommission,
              onchange () {
                formData.overrideCommission = !formData.overrideCommission
              }
            })
          ]),
          formData.overrideCommission
            ? m('.col-span-2.pt-4.flex.items-end.gap-2', [
              m(Input, {
                label: 'Commision Rate',
                value: formData.commissionRatePercent,
                type: 'number',
                min: 0,
                step: '.01',
                required: true,
                oninput (value) {
                  formData.commissionRatePercent = value
                  formData.commissionRate = (value / 100).toFixed(4)
                }
              }),
              m('.mb-2', '%')
            ]) : null,
          formData.overrideCommission
            ? m('.col-span-2') : null,
          m('.col-span-4.pt-4', [
            m(Input, {
              label: 'Department Venue Commision Rate',
              value: meeting.departmentVenue ? `${(meeting.departmentVenue.commission * 100).toFixed(2)} %` : '0.00 %',
              type: 'readonly'
            })
          ])
        ])
        // m('.flex.flex-col.gap-3', [
        //   m(Select, {
        //     label: 'Venue',
        //     value: meeting.departmentVenue ? meeting.departmentVenue.id : '',
        //     choices: venues.map(dv => {
        //       return { label: dv.venueName, value: dv.id }
        //     }),
        //     onchange (val, label) {
        //       formData.departmentVenueId = val
        //       formData.departmentVenueLabel = label
        //       rates = null

        //       getDepartmentVenueRates(meeting.departmentId, formData.departmentVenueId).then(data => {
        //         rates = data
        //       })
        //     }
        //   }),
        //   formData.departmentVenueId && rates && rates.length ? m(Select, {
        //     label: 'Rate',
        //     value: '',
        //     choices: rates.map(dvr => {
        //       return { label: `${dvr.label} <br> ${formatMoney(dvr.tariff, dvr.currencySymbol)}`, value: dvr.id, disabled: dvr.visibilityId === 1 }
        //     }),
        //     onchange (val, label) {
        //       formData.departmentVenueRateId = val
        //     }
        //   }) : null
        // ])
      ])
    }
  }
}
