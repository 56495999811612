import { Tooltip } from 'components/Tooltip'

export const DelegateInput = ({ attrs: { autofocus } }) => {
  let isFocused = false
  return {
    oncreate ({ dom }) {
      if (autofocus) {
        dom.querySelector('input').focus()
      }
    },
    view ({ attrs }) {
      return m('.delegate-input.border-t.w-full' + (isFocused ? '.active' : ''), {
        onclick (e) {
          this.querySelector('input').focus()
        }
      }, [
        m('.flex.items-center.py-3.px-1', {
          class: attrs.classes && [...attrs.classes]
        }, [
          m('label.block.text-sm.ml-5.mr-2.flex-shrink-0', {
            class: attrs.labelClasses ? [...attrs.labelClasses] : 'w-1/4'
          }, [attrs.label, attrs.required && m('span.text-cyan.ml-1', '*')]),
          attrs.type === 'readonly'
            ? m('.h-12.mt-2.rounded.p-2.w-full.leading-loose', attrs.value)
            : [
              m('input.h-12.p-2.w-full.h-full.focus:outline-none.bg-transparent', {
                style: {
                  marginTop: attrs.type === 'date' && '2px'
                },
                value: attrs.value,
                type: attrs.type || 'text',
                max: attrs.max,
                min: attrs.min,
                placeholder: attrs.placeholder,
                required: attrs.required ? 'required' : null,
                onfocus () {
                  isFocused = true
                },
                onblur () {
                  isFocused = false
                },
                oninput (e) {
                  attrs.oninput(e.target.value)
                }
              }),
              attrs.tooltip && m(Tooltip, { tipContent: attrs.tooltip, mr: 'mr-5' })
            ]
        ]),
        m('.text-red-600.text-sm', attrs.errorMessage)
      ])
    }
  }
}
