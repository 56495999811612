import { TextArea, Checkbox } from 'components'
import { Breadcrumb } from 'components/Breadcrumb'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { getVenue, getDepartmentVenue, getDepartmentVenueNote, updateDepartmentVenueNote, createDepartmentVenueNote } from 'api/venues'
import { getDepartment } from 'api/clients'
import { notification } from 'utils/notifications'

export const EditDepartmentVenueNote = ({ attrs: { clientId, departmentId, venueId, departmentVenueId, departmentVenueNoteId } }) => {
  let ready = false
  let departmentVenueNote = {}
  let venue
  let department
  let departmentVenue

  // This screen can be called from the department or from the Venue.
  // When routed through the department the venueId will be zero
  // When routed through the venue the clientId will be zero

  if (venueId) {
    getVenue(venueId).then(data => {
      venue = data
    })
  }
  if (clientId && departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
    })
  }

  getDepartmentVenue(departmentId, departmentVenueId).then(dv => {
    departmentVenue = dv
  })

  if (departmentVenueNoteId > 0) {
    getDepartmentVenueNote(departmentId, departmentVenueId, departmentVenueNoteId).then(dvr => {
      departmentVenueNote = dvr
      ready = true
    })
  } else {
    departmentVenueNote = { departmentVenueId: departmentVenueId }
    ready = true
  }

  const createUpdateDepartmentVenueNote = (e) => {
    e.preventDefault()
    if (departmentVenueNote.id) {
      updateDepartmentVenueNote(departmentId, departmentVenueId, departmentVenueNote.id, departmentVenueNote).then(() => {
        notification.success({ title: 'Updated' })
        if (venueId) {
          m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`)
        } else {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`)
        }
        m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`)
      }).catch(() => {
        notification.error({ title: 'Error - unable to update department venue note' })
      })
    } else {
      createDepartmentVenueNote(departmentId, departmentVenueId, departmentVenueNote).then(() => {
        notification.success({ title: 'Created' })
        if (venueId) {
          m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`)
        } else {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`)
        }
      }).catch(err => {
        console.log(err)
        notification.error({ title: 'Error - unable to create department venue note' })
      })
    }
  }
  return {
    view ({ attrs: { clientId, departmentId, venueId, departmentVenueId, departmentVenueNoteId } }) {
      return m('div', [
        department && departmentVenue && m(Breadcrumb, {
          links: [{
            title: 'Clients',
            href: '/clients'
          }, {
            title: department.clientName,
            href: `/clients/${clientId}`
          }, {
            title: 'Departments',
            href: `/clients/${clientId}?tab=Departments`
          }, {
            title: department.name,
            href: `/clients/${clientId}/departments/${departmentId}`
          }, {
            title: 'Venues',
            href: `/clients/${clientId}/departments/${departmentId}?tab=Venues`
          }, {
            title: departmentVenue.venue.name,
            href: `/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`
          }, {
            title: 'Notes'
          }, {
            title: departmentVenueNoteId ? 'Edit' : 'Add'
          }]
        }),
        venue && departmentVenue && m(Breadcrumb, {
          links: [{
            title: 'Venues',
            href: '/venues'
          }, {
            title: venue.name,
            href: `/venues/${venueId}`
          }, {
            title: 'Departments',
            href: `/venues/${venueId}?tab=Departments`
          }, {
            title: departmentVenue.department.name,
            href: `/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`
          }, {
            title: 'Notes'
          }, {
            title: departmentVenueNoteId ? 'Edit' : 'Add'
          }]
        }),
        ready && m(EditForm, {
          onsubmit: createUpdateDepartmentVenueNote
        },
        [
          m(EditFormSection, {
            heading: departmentVenueNoteId ? 'Edit Venue Note' : 'Add Venue Note',
            description: 'Please enter a note for the venue at this department'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6',
                [
                  m(TextArea, {
                    label: 'Note',
                    value: departmentVenueNote.body,
                    rows: 4,
                    required: true,
                    oninput (value) {
                      departmentVenueNote.body = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.lg:col-span-3',
                m(Checkbox, {
                  label: 'Accommodation?',
                  checked: departmentVenueNote.isAccommodation,
                  onchange () {
                    departmentVenueNote.isAccommodation = !departmentVenueNote.isAccommodation
                  }
                }),
                m(Checkbox, {
                  label: 'Meetings?',
                  checked: departmentVenueNote.isMeetings,
                  onchange () {
                    departmentVenueNote.isMeetings = !departmentVenueNote.isMeetings
                  }
                })
              )
            ])
          ])
        ])
      ])
    }
  }
}
