
import { Breadcrumb, Input, Checkbox, TextArea, Select } from 'components'
import { VisibilityBadge } from 'components/Badge'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { DateTime } from 'luxon'
import { getDepartmentBookingFields, getDepartment } from 'api/clients'
import { getCountries, getCurrencies } from 'api/system'
import { addBooking } from 'api/bookings'
import { notification } from 'utils/notifications'

export const CreateBooking = ({ attrs: { clientId, departmentId } }) => {
  let ready
  let booking
  let bookingFields
  let department
  let countries = []

  Promise.all([
    getDepartment(clientId, departmentId),
    getCountries(),
    getCurrencies()
  ])
    .then(([d, cs, cu]) => {
      department = d
      countries = cs
      ready = true
    })

  getDepartmentBookingFields(departmentId).then(bdata => {
    bookingFields = bdata

    booking = {
      bookingType: 1,
      departmentId: departmentId,
      bookingFields: bookingFields.map(bf => {
        return {
          departmentBookingFieldId: bf.id
        }
      })
    }
  })

  const createBooking = (e) => {
    e.preventDefault()
    addBooking(booking).then((data) => {
      notification.success({ title: 'Created' })
      m.route.set(`/clients/${clientId}/departments/${departmentId}/bookings/${data.id}`)
    }).catch(err => {
      console.log(err)
      notification.error({ title: 'Error - unable to create booking' })
    })
  }

  return {
    view ({ attrs: { bookingId, clientId, departmentId, tab } }) {
      return [
        ready && m('.pr-8', [
          m(Breadcrumb, {
            links: [{
              title: 'Clients',
              href: '/clients'
            }, {
              title: department.clientName,
              href: `/clients/${clientId}`
            }, {
              title: 'Departments',
              href: `/clients/${clientId}?tab=Departments`
            }, {
              title: department.name,
              href: `/clients/${clientId}/departments/${departmentId}`
            }, {
              title: 'Bookings',
              href: `/clients/${clientId}/departments/${departmentId}?tab=Bookings`
            }, {
              title: booking && booking.id > 0 ? `Edit ${booking.name}: ${DateTime.fromISO(booking.arrivalDate).toLocaleString()}` : 'New'
            }]
          }),
          m(EditForm, {
            onsubmit: createBooking
          },
          [
            m(EditFormSection, {
              heading: 'Attendee',
              description: 'Add the attendee details',
              buttons: []
            }, [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: 'Name',
                      value: booking.name,
                      type: 'text',
                      required: true,
                      oninput (value) {
                        booking.name = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: 'Email',
                      value: booking.email,
                      type: 'email',
                      required: true,
                      oninput (value) {
                        booking.email = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('createVenue', 'addressLine1'),
                      value: booking.addressLine1,
                      type: 'text',
                      oninput (value) {
                        booking.addressLine1 = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('createVenue', 'addressLine2'),
                      value: booking.addressLine2,
                      type: 'text',
                      oninput (value) {
                        booking.addressLine2 = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: t('createVenue', 'town'),
                      value: booking.townCity,
                      type: 'text',
                      oninput (value) {
                        booking.townCity = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'county'),
                      value: booking.county,
                      type: 'text',
                      oninput (value) {
                        booking.county = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'postCode'),
                      value: booking.postCode,
                      type: 'text',
                      oninput (value) {
                        booking.postCode = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Select, {
                      label: t('createVenue', 'country'),
                      search: true,
                      value: booking.countryCode,
                      strValue: true,
                      onchange (e) {
                        booking.countryCode = e
                      },
                      choices: countries
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: 'Telephone',
                      value: booking.telephone,
                      type: 'text',
                      // required: true,
                      oninput (value) {
                        booking.telephone = value
                      }
                    })
                  ]
                )
              ])
            ]),
            m(EditFormSection, {
              heading: 'Booking Details',
              description: 'Update the booking details'
            }, [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'Arrival Date',
                      value: booking.arrivalDate,
                      type: 'date',
                      required: true,
                      oninput (value) {
                        booking.arrivalDate = value
                      }
                    })

                  ]
                ),
                m('div.col-span-6.md:col-span-4'),
                m('div.col-span-6.md:col-span-1',
                  [
                    m(Input, {
                      label: 'Nights',
                      value: booking.nights,
                      type: 'number',
                      required: true,
                      min: 1,
                      oninput (value) {
                        booking.nights = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-5'),
                bookingFields && bookingFields.map(bf => {
                  return bf.visibilityId !== 1 && [
                    m('div.col-span-6.md:col-span-4', [
                      bf.type === 0 ? m(Input, {
                        label: bf.label,
                        value: booking.bookingFields && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id) && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id).value,
                        requiredWarning: bf.isMandatory,
                        oninput (value) {
                          if (!booking.bookingFields) {
                            booking.bookingFields = []
                          }
                          let found = false
                          for (let index = 0; index < booking.bookingFields.length; index++) {
                            const element = booking.bookingFields[index]
                            if (element.departmentBookingFieldId === bf.id) {
                              element.value = value
                              found = true
                            }
                          }
                          if (!found) {
                            booking.bookingFields.push({ departmentBookingFieldId: bf.id, value: value })
                          }
                        }
                      }) : m(Checkbox, {
                        label: bf.label,
                        checked: booking.bookingFields && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id) && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id).value,
                        requiredWarning: bf.isMandatory,
                        onchange (value) {
                          if (!booking.bookingFields) {
                            booking.bookingFields = []
                          }
                          let found = false
                          for (let index = 0; index < booking.bookingFields.length; index++) {
                            const element = booking.bookingFields[index]
                            if (element.departmentBookingFieldId === bf.id) {
                              element.value = !element.value
                              found = true
                            }
                          }
                          if (!found) {
                            booking.bookingFields.push({ departmentBookingFieldId: bf.id, value: !value })
                          }
                        }
                      })
                    ]),
                    m('div.col-span-6.md:col-span-1.pt-8', [
                      m(VisibilityBadge, { visibility: bf.visibilityName })
                    ])
                  ]
                }),
                m('div.col-span-3',
                  [
                    m(TextArea, {
                      label: 'Additional Info',
                      value: booking.additionalInfo,
                      rows: 4,
                      oninput (value) {
                        booking.additionalInfo = value
                      }
                    })
                  ]
                ),
                m('div.col-span-3',
                  [
                    m(TextArea, {
                      label: 'Preference Info',
                      value: booking.preferenceInfo,
                      rows: 4,
                      oninput (value) {
                        booking.preferenceInfo = value
                      }
                    })
                  ]),
                m('div.col-span-6',
                  [
                    m(TextArea, {
                      label: 'Admin Notes',
                      value: booking.notes,
                      rows: 4,
                      oninput (value) {
                        booking.notes = value
                      }
                    })
                  ]
                )
              ])
            ])
          ])
        ])
      ]
    }
  }
}
