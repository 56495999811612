import Choices from 'choices.js'

export const DelegateSelect = () => {
  return {
    view ({ attrs: { subtype, name, search, position, strValue, shouldSort, choices, onchange, label, errorMessage, value, required, removeItemButton } }) {
      name = name || label.replaceAll(' ', '_').toLowerCase()
      return m('.flex.flex-col.py-3', [
        m(`label.ml-5.mr-2.w-1/4.block.text-sm.flex-shrink-0[for='${name}']`,
          [label, required && m('span.text-cyan.ml-1', '*')]
        ),
        m('.w-full', [
          m('select', {
          // multiple: subtype === 'multiple',
            name: name,
            required: required,
            oncreate: el => {
              const choicesEl = new Choices(el.dom, {
                searchEnabled: search === null ? true : search,
                shouldSort: shouldSort || false,
                removeItemButton: removeItemButton !== false,
                itemSelectText: 'Select',
                allowHTML: true,
                position: position || 'auto',
                choices: [{ value: null, label: null }],
                classNames: {
                  containerOuter: 'choices mt-0 mb-0',
                  containerInner: 'choices__inner h-10 sm:text-sm pl-2 py-1 bg-white border border-gray-800 mt-1 relative shadow-sm w-full',
                  item: 'choices__item text-base'
                }
              })
              if (choices) {
                choicesEl.setChoices(choices.map(c => {
                  return { label: c.label, value: c.value, selected: c.value === (strValue ? value : parseInt(value)) }
                }), 'value', 'label', false)
              }
            },
            onchange: evt => {
              onchange(evt.target.value, evt.target.textContent)
            }
          }),
          m('.text-red-600.text-sm', errorMessage)
        ])
      ])
    }
  }
}
