
import { Breadcrumb } from 'components/Breadcrumb'
// import { Input, TextArea } from 'components'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { DateTime } from 'luxon'
import { getDepartment } from 'api/clients'
import { getMeeting } from 'api/meetings'
import { Table } from 'components/Table'
// import { notification } from 'utils/notifications'

export const EditMeetingRates = ({ attrs: { clientId, departmentId, meetingId } }) => {
  let ready
  let meeting
  let department

  // let editingRate
  // const editRateOpen = flyd.stream(false)

  Promise.all([
    getDepartment(clientId, departmentId),
    getMeeting(meetingId)
  ])
    .then(([d, m]) => {
      department = d
      meeting = m
      ready = true
    })

  return {
    view ({ attrs: { meetingId, clientId, departmentId } }) {
      return [
        ready && m('.pr-8', [
          m(Breadcrumb, {
            links: [{
              title: department.name,
              href: `/clients/${clientId}/departments/${departmentId}`
            }, {
              title: t('app', 'meetings'),
              href: `/clients/${clientId}/departments/${departmentId}?tab=Meetings`
            }, {
              title: `${meeting.description}: ${DateTime.fromISO(meeting.arrivalDate).toLocaleString()}`,
              href: `/clients/${clientId}/departments/${departmentId}/meetings/${meetingId}`
            }, {
              title: 'Edit Rates'
            }]
          }),
          m(EditForm, {
            // onsubmit: update
          },
          [
            m(EditFormSection, {
              heading: 'Meeting Rates',
              description: 'Add, delete or update the meeting rates'
            }, [
              m(Table, {
                cols: [
                  {
                    label: 'Rate'
                  },
                  {
                    label: 'Mode'
                  },
                  {
                    label: 'Tariff'
                  },
                  {
                    label: 'Quantity'
                  },
                  {
                    label: 'Total'
                  },
                  {
                    label: 'Commission %'
                  },
                  {
                    label: 'Commission'
                  }
                ],
                filters: false
              },
              [
                m('.overflow-auto',
                  meeting.meetingRates.map((v, ix) => {
                    return m(RateListItem, { item: v, clientId: clientId, departmentId: departmentId, meetingId: meetingId, currencySymbol: meeting.departmentVenue.venue.currency.symbol })
                  })
                )
              ])
            ])
          ])
        ])
      ]
    }
  }
}

const RateListItem = ({ attrs: { item, classes, clientId, departmentId, meetingId, currencySymbol } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/meetings/${meetingId}/rates/${item.id}`)
        }
      }, [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.label)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', `${currencySymbol}${item.tariff}`)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.visibilityName)
        )
      ])
    }
  }
}
