import { createPopper } from '@popperjs/core'
import { Svg } from 'components'

export const Tooltip = ({ attrs: { tipContent, icon, colorClass, mr } }) => {
  let isShowing = false
  let popperInstance
  return {
    oncreate ({ dom }) {
      popperInstance = createPopper(dom.querySelector('.tt-hover'), dom.querySelector('.tt-content'), {
        placement: 'left',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]
      })
    },
    onbeforeremove () {
      popperInstance.destroy()
    },
    view () {
      return m('.tooltip.position-relative.z-40', [
        m('.tt-hover.rounded-full.h-5.w-5.flex.justify-center.items-center.select-none', {
          class: [
            colorClass || 'bg-cyan',
            mr || ''].join(' '),
          onmouseover () {
            isShowing = true
          },
          onmouseleave () {
            isShowing = false
          }
        }, icon ? m(Svg, { classes: ['w-5', 'h-5'] }, icon) : 'i'),
        m('.tt-content.pointer-events-none', {
          style: {
            opacity: isShowing ? 1 : 0
          }
        }, m('.font-apercu.text-sm.bg-white.rounded.shadow-lg.py-2.px-4', tipContent))
      ])
    }
  }
}
