import { request } from './request'

export const getInvoiceRuns = params => {
  return request({
    url: 'invoices/runs',
    params: {
      pagesize: 25,
      ...params
    },
    method: 'GET'
  })
}
export const getInvoices = params => {
  return request({
    url: 'invoices',
    params: {
      pagesize: 25,
      ...params
    },
    method: 'GET'
  })
}

export const exportInvoices = params => {
  return request({
    url: 'invoices/export',
    params: {
      pagesize: 25,
      ...params
    },
    method: 'GET',
    responseType: 'blob',
    extract (val) {
      return val.response
    }
  })
    .then(res => {
      const date = new Date()
      var anchor = document.createElement('a')
      anchor.download = `tobook_invoices_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
      anchor.href = window.URL.createObjectURL(res)
      anchor.click()
    })
}

export const getInvoiceRun = (invoiceRunId) => {
  return request({
    url: `invoices/runs/${invoiceRunId}`,
    method: 'GET'
  })
}

export const getInvoice = (invoiceId) => {
  return request({
    url: `invoices/${invoiceId}`,
    method: 'GET'
  })
}

export const getInvoiceActivity = (invoiceId) => {
  return request({
    url: `invoices/${invoiceId}/activity`,
    method: 'GET'
  })
}

export const getInvoiceEmail = (invoiceId) => {
  return request({
    url: `invoices/${invoiceId}/email`,
    method: 'GET'
  })
}

export const sendInvoiceEmail = (invoiceId, email) => {
  return request({
    url: `invoices/${invoiceId}/email`,
    method: 'POST',
    body: email
  })
}

export const createInvoice = (invoice) => {
  return request({
    url: 'invoices',
    method: 'POST',
    body: invoice
  })
}

export const updateInvoice = (invoice) => {
  return request({
    url: `invoices/${invoice.id}`,
    method: 'PUT',
    body: invoice
  })
}

export const approveInvoice = (invoiceId) => {
  return request({
    url: `invoices/${invoiceId}/approve`,
    method: 'PUT'
  })
}

export const unapproveInvoice = (invoiceId) => {
  return request({
    url: `invoices/${invoiceId}/unapprove`,
    method: 'PUT'
  })
}

export const deleteInvoice = (invoiceId) => {
  return request({
    url: `invoices/${invoiceId}`,
    method: 'DELETE'
  })
}

export const printInvoice = (invoiceId) => {
  return request({
    url: `invoices/${invoiceId}/print`,
    method: 'GET'
  })
}

export const createInvoiceRun = (params) => {
  return request({
    url: 'invoices/runs',
    method: 'POST',
    params
  })
}

export const getInvoiceRunSummary = (params) => {
  return request({
    url: 'invoices/runsummary',
    method: 'GET',
    params
  })
}

export const addInvoiceNote = (invoiceId, body) => {
  return request({
    url: `invoices/${invoiceId}/note`,
    method: 'POST',
    body
  })
}

export const getInvoiceActivityTypes = (params) => {
  return request({
    url: 'invoices/activitytypes',
    method: 'GET',
    params
  })
}

export const getInvoicePdf = (id) => {
  return request({
    url: `invoices/${id}/print`,
    method: 'GET',
    responseType: 'blob',
    extract (val) {
      return val.response
    }
  })
    .then(res => {
      var anchor = document.createElement('a')
      anchor.download = `tobook_invoice_${id}.pdf`
      anchor.href = window.URL.createObjectURL(res)
      anchor.click()
    })
}
