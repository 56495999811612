import { Breadcrumb } from 'components/Breadcrumb'
import { Button } from 'components/Button'
import { Table } from 'components/Table'
import { Svg } from 'components/Svg'

import { getRates } from 'api/rates'

import addIcon from 'assets/icons/outline/plus.svg'
// import tickIcon from 'assets/icons/outline/check-circle.svg'

const Rate = ({ attrs: { item, classes } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/rate-types/${item.id}`)
        }
      },
      [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.truncate.text-sm.font-medium.text-gray-900',
          m(m.route.Link, { href: `/rate-types/${item.id}` }, item.name)
        ),
        m('flex.w-1/6.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500.justify-center',
          m('span', item.bookingTypeDescription)
        ),
        m('flex.w-1/6.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500.justify-center',
          m('span', item.rateModeString)
        )
      ]
      )
    }
  }
}

export const Rates = () => {
  let rateTypes

  const filters = { bookingType: null }

  const filterBookingType = (val) => {
    filters.bookingType = val ? parseInt(val) : null
    getRates(filters).then(data => {
      rateTypes = data.data
    })
  }

  getRates().then(data => {
    rateTypes = data.data
  }
  )

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: t('rates', 'title')
          }],
          buttons: [
            m(Button, {
              size: 'sm',
              classes: ['my-2', 'mx-2', 'whitespace-nowrap', 'text-white'],
              type: 'button',
              onclick: () => {
                m.route.set('/rate-types/createaccommodation')
              }
            }, [m(Svg, {
              classes: ['w-4', 'mx-auto']
            }, [addIcon]), 'Add Accommodation Rate']),
            m(Button, {
              size: 'sm',
              classes: ['my-2', 'mx-auto', 'whitespace-nowrap', 'text-white'],
              type: 'button',
              onclick: () => {
                m.route.set('/rate-types/createmeeting')
              }
            }, [m(Svg, {
              classes: ['w-4', 'mx-auto']
            }, [addIcon]), 'Add Meeting Rate'])]
        }),
        m('.p-4', { style: { height: 'calc(100% - 3rem)' } }, [
          m('.h-full.shadow.overflow-hidden.border-b.border-gray-200.sm:rounded-lg',
            rateTypes && m(Table, {
              cols: [
                {
                  label: t('rates', 'rateName')
                },
                {
                  label: 'Type', classes: ['w-1/6']
                },
                {
                  label: 'Rate Type',
                  classes: ['w-1/6'],
                  filter: true,
                  filterOptions: [
                    { name: '', id: null, selected: filters.bookingType === null },
                    { name: t('app', 'accommodation'), id: 1, selected: filters.bookingType === 1 },
                    { name: t('app', 'meetings'), id: 2, selected: filters.bookingType === 2 }],
                  filterOnChange: (val) => {
                    filterBookingType(val)
                  }
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto',
                rateTypes.map((v, ix) => {
                  return m(Rate, { key: v.id, item: v, classes: [ix % 2 ? 'bg-gray-50' : 'bg-white'] })
                })
              )
            ])
          )
        ])
      ]
    }
  }
}
