import { Heading } from 'components/Heading'
import { Svg } from 'components/Svg'
export const EmptyState = () => {
  return {
    view ({ children, attrs: { icon, message } }) {
      return m('.flex.flex-col.justify-center.items-center.flex-grow', [
        m(Svg, {
          classes: ['w-40', 'text-gray-200']
        }, icon),
        m(Heading, {
          level: 2,
          classes: ['text-center', 'mt-6', 'w-4/5']
        }, message),
        children
      ])
    }
  }
}
