import { Card } from 'components/Card'
import { Heading } from 'components/Heading'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { recoverPassword } from 'api/auth'
import { schema } from 'validators/forgotten-password'
import { validate, getError, displayErrors } from 'validators/utils'
import logo from 'assets/images/tobook-logo.png'

const formData = {

}

export const ForgottenPassword = () => {
  let errors = []
  let serverErrors
  let loading
  let success = false
  const triggerPasswordReset = (data) => {
    const validation = validate(schema, data)
    if (validation) {
      errors = validation
    } else {
      errors = []
      loading = true
      recoverPassword(data)
        .then(res => {
          loading = false
          success = true
        })
        .catch(err => {
          loading = false
          serverErrors = err.response.Errors
        })
    }
  }
  return {
    view () {
      return m('.h-full.flex.flex-col.justify-center.items-center.bg-orange-500.h-screen',
        [
          m('img.my-10.w-64', { src: logo }),
          m(Card, {
            classes: ['w-4/5', 'max-w-md']
          }, [
            m('.p-6', [
              m(Heading, t('forgottenPassword', 'title')),
              m('form.grid.gap-6', {
                onsubmit (e) {
                  e.preventDefault()
                  triggerPasswordReset(formData)
                }
              }, success ? m('p', 'Please check your email for a link to reset your password.') : [
                m(Input, {
                  label: t('forgottenPassword', 'email'),
                  value: formData.email,
                  type: 'email',
                  errorMessage: getError(errors, 'email'),
                  oninput (val) {
                    formData.email = val
                  }
                }),
                displayErrors(serverErrors),
                m('.flex.justify-between.items-center', [
                  m(Link, {
                    classes: ['text-orange-900', 'underline'],
                    href: '/login'
                  }, t('forgottenPassword', 'logInLink')),
                  m(Button, {
                    variant: 'positive',
                    loading
                  }, t('forgottenPassword', 'action'))
                ])
              ])
            ])
          ])
        ])
    }
  }
}
