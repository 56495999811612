export const register = (data) => {
  return m.request({
    url: process.env.API_URL + 'account/public/users',
    method: 'POST',
    body: {
      email: data.email,
      newPassword: data.password
    }
  })
}

export const recoverPassword = (data) => {
  return m.request({
    method: 'POST',
    url: process.env.API_URL + 'account/public/recoverpassword',
    body: {
      usernameOrEmail: data.email
    }
  })
}
export const resetPassword = (body) => {
  return m.request({
    method: 'POST',
    url: process.env.API_URL + 'account/public/resetpassword',
    body
  })
}
