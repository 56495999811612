import { Card } from 'components/Card'
import { Input } from 'components/Input'
import { TextArea } from 'components/TextArea'
import { Button } from 'components/Button'
import { Breadcrumb } from 'components/Breadcrumb'
import { getVenue, getContact, createContact, updateContact, deleteContact } from 'api/venues'

import { notification } from 'utils/notifications'

export const EditVenueContact = ({ attrs: { venueId, contactId } }) => {
  let loading
  let ready = false
  let contact = {}
  let venue
  let deleting
  getVenue(venueId).then(data => {
    venue = data
  })
  if (contactId > 0) {
    getContact(venueId, contactId).then(data => {
      contact = data
      ready = true
    })
  } else {
    ready = true
  }
  const createUpdateContact = (e) => {
    e.preventDefault()
    loading = true
    if (contact.id) {
      updateContact(venueId, contact).then(() => {
        loading = false
        notification.success({ title: t('contacts', 'updated') })
        m.route.set(`/venues/${venueId}?tab=Contacts`)
      })
    } else {
      createContact(venueId, contact).then(() => {
        loading = false
        notification.success({ title: t('contacts', 'created') })
        m.route.set(`/venues/${venueId}?tab=Contacts`)
      })
    }
  }
  return {
    view ({ attrs: { venueId, id } }) {
      return m('div', [
        venue && m(Breadcrumb, {
          links: [{
            title: 'Venues',
            href: '/venues'
          }, {
            title: venue.name,
            href: `/venues/${venueId}`
          }, {
            title: 'Contacts',
            href: `/venues/${venueId}?tab=Contacts`
          }, {
            title: id ? 'Edit' : 'Create'
          }]
        }),
        ready && m('div.p-4',
          m('div.md:grid.md:grid-cols-3.md:gap-6',
            [
              m('div.md:col-span-1.flex.flex-col.justify-between',
                m('div.px-4.sm:px-0',
                  [
                    m('h3.text-lg.font-medium.leading-6.text-gray-900',
                      contactId ? 'Edit Contact' : 'Create Contact'
                    ),
                    m('p.mt-1.text-sm.text-gray-600',
                      'Please enter the contact details.'
                    )
                  ]
                ),
                m('div',
                  m(Button, {
                    type: 'button',
                    variant: 'delete',
                    size: 'xs',
                    loading: deleting,
                    onclick: (e) => {
                      var r = confirm('Are you sure? This cannot be undone!')
                      deleting = true
                      if (r === true) {
                        deleteContact(venueId, contactId).then(() => {
                          deleting = false
                          m.route.set(`/venues/${venueId}?tab=Contacts`)
                        })
                      }
                    }
                  }, 'Delete'))
              ),
              m('div.mt-5.md:mt-0.md:col-span-2', [
                m('form', {
                  onsubmit: createUpdateContact
                }, [
                  m(Card, {
                    buttons: [m(Button, {
                      type: 'submit',
                      loading: loading
                    }, 'Save')]
                  },
                  m('.space-y-6.divide-y.divide-gray-200', [
                    m('div.grid.grid-cols-6.gap-6', [
                      m('div.col-span-6.md:col-span-3',
                        [
                          m(Input, {
                            label: t('contacts', 'firstName'),
                            value: contact.firstName,
                            type: 'text',
                            oninput (value) {
                              contact.firstName = value
                            }
                          })
                        ]
                      ),
                      m('div.col-span-6.md:col-span-3',
                        [
                          m(Input, {
                            label: t('contacts', 'lastName'),
                            value: contact.lastName,
                            type: 'text',
                            required: true,
                            oninput (value) {
                              contact.lastName = value
                            }
                          })
                        ]
                      ),
                      m('.col-span-6.md:col-span-5',
                        [
                          m(Input, {
                            label: t('contacts', 'position'),
                            value: contact.position,
                            type: 'text',
                            oninput (value) {
                              contact.position = value
                            }
                          })
                        ]
                      ),
                      m('div.col-span-6.md:col-span-3',
                        [
                          m(Input, {
                            label: t('contacts', 'telephone'),
                            value: contact.telephone,
                            type: 'tel',
                            oninput (value) {
                              contact.telephone = value
                            }
                          })
                        ]
                      ),
                      m('div.col-span-6.md:col-span-3',
                        [
                          m(Input, {
                            label: t('contacts', 'mobile'),
                            value: contact.mobile,
                            type: 'tel',
                            oninput (value) {
                              contact.mobile = value
                            }
                          })
                        ]
                      ),
                      m('div.col-span-6.md:col-span-4',
                        [
                          m(Input, {
                            label: t('contacts', 'email'),
                            value: contact.email,
                            type: 'text',
                            oninput (value) {
                              contact.email = value
                            }
                          })
                        ]
                      ),
                      m('div.col-span-6.md:col-span-5',
                        [m(TextArea, {
                          classes: ['h-full'],
                          label: t('contacts', 'notes'),
                          value: contact.notes,
                          oninput (value) {
                            contact.notes = value
                          }
                        })
                        ]
                      )
                    ])
                  ]))
                ])
              ])
            ]
          )
        )
      ])
    }
  }
}
