
import { Breadcrumb } from 'components/Breadcrumb'
import { Input, Checkbox, Select, TextArea } from 'components'
import { VisibilityBadge } from 'components/Badge'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { DateTime } from 'luxon'
import { getDepartment } from 'api/clients'
import { getCountries } from 'api/system'
import { getBooking, updateBooking } from 'api/bookings'
import { notification } from 'utils/notifications'

export const EditBooking = ({ attrs: { clientId, departmentId, bookingId, copy } }) => {
  let ready
  let booking
  let department
  let countries = []

  Promise.all([
    getDepartment(clientId, departmentId),
    getCountries(),
    getBooking(bookingId)
  ])
    .then(([d, cs, b]) => {
      department = d
      countries = cs
      booking = b
      ready = true
    })

  const saveBooking = (e) => {
    e.preventDefault()
    updateBooking(bookingId, booking).then((data) => {
      notification.success({ title: 'Updated' })
      m.route.set(`/clients/${clientId}/departments/${departmentId}/bookings/${data.id}`)
    }).catch(err => {
      console.log(err)
      notification.error({ title: err.response })
    })
  }

  return {
    view ({ attrs: { bookingId, clientId, departmentId, tab } }) {
      return [
        ready && m('.pr-8', [
          m(Breadcrumb, {
            links: [{
              title: 'Clients',
              href: '/clients'
            }, {
              title: department.clientName,
              href: `/clients/${clientId}`
            }, {
              title: 'Departments',
              href: `/clients/${clientId}?tab=Departments`
            }, {
              title: department.name,
              href: `/clients/${clientId}/departments/${departmentId}`
            }, {
              title: 'Bookings',
              href: `/clients/${clientId}/departments/${departmentId}?tab=Bookings`
            }, {
              title: booking.id > 0 ? `Edit ${booking.name}: ${DateTime.fromISO(booking.arrivalDate).toLocaleString()}` : 'New'
            }]
          }),
          copy ? m('div.text-center.px-3.py-1.font-medium.bg-purple-200.text-purple-800.text-xl', 'Cloned Booking - New Request') : '',
          m(EditForm, {
            onsubmit: saveBooking
          },
          [
            m(EditFormSection, {
              heading: 'Attendee',
              description: 'Edit the attendee details',
              buttons: []
            }, [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: 'Name',
                      value: booking.name,
                      type: 'text',
                      required: true,
                      oninput (value) {
                        booking.name = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: 'Email',
                      value: booking.email,
                      type: 'email',
                      required: true,
                      oninput (value) {
                        booking.email = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('createVenue', 'addressLine1'),
                      value: booking.addressLine1,
                      type: 'text',
                      oninput (value) {
                        booking.addressLine1 = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('createVenue', 'addressLine2'),
                      value: booking.addressLine2,
                      type: 'text',
                      oninput (value) {
                        booking.addressLine2 = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: t('createVenue', 'town'),
                      value: booking.townCity,
                      type: 'text',
                      oninput (value) {
                        booking.townCity = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'county'),
                      value: booking.county,
                      type: 'text',
                      oninput (value) {
                        booking.county = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: t('createVenue', 'postCode'),
                      value: booking.postCode,
                      type: 'text',
                      oninput (value) {
                        booking.postCode = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Select, {
                      label: t('createVenue', 'country'),
                      search: true,
                      value: booking.countryCode,
                      strValue: true,
                      onchange (e) {
                        booking.countryCode = e
                      },
                      choices: countries
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: 'Telephone',
                      value: booking.telephone,
                      type: 'text',
                      // required: true,
                      oninput (value) {
                        booking.telephone = value
                      }
                    })
                  ]
                )
              ])
            ]),
            m(EditFormSection, {
              heading: 'Booking Details',
              description: 'Update the booking details'
            }, [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'Arrival Date',
                      value: booking.arrivalDate,
                      type: 'date',
                      required: true,
                      oninput (value) {
                        booking.arrivalDate = value
                      }
                    })

                  ]
                ),
                m('div.col-span-6.md:col-span-4'),
                m('div.col-span-6.md:col-span-1',
                  [
                    m(Input, {
                      label: 'Nights',
                      value: booking.nights,
                      type: 'number',
                      required: true,
                      min: 1,
                      oninput (value) {
                        booking.nights = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-5'),
                booking.bookingFields && booking.bookingFields.map(bf => {
                  return [
                    m('div.col-span-6.md:col-span-4', [
                      bf.departmentBookingField.type === 0 ? m(Input, {
                        label: bf.departmentBookingField.label || '-',
                        required: bf.departmentBookingField.isMandatory,
                        value: bf.value,
                        requiredWarning: bf.departmentBookingField.isMandatory,
                        oninput (value) {
                          bf.value = value
                        }
                      }) : bf.departmentBookingField.type === 1 ? m(Checkbox, {
                        label: bf.departmentBookingField.label,
                        checked: bf.value,
                        requiredWarning: bf.departmentBookingField.isMandatory,
                        onchange () {
                          bf.value = !bf.value
                        }
                      }) : m(Select, {
                        label: bf.departmentBookingField.label,
                        search: false,
                        required: bf.departmentBookingField.isMandatory,
                        value: bf.value,
                        strValue: true,
                        onchange (e) {
                          bf.value = e
                        },
                        choices: bf.departmentBookingField.lookupOptions.includes(bf.value)
                          ? bf.departmentBookingField.lookupOptions.split('|').map(lo => { return { label: lo.trim(), value: lo.trim() } })
                          : [{ label: bf.value + ' - No longer available', value: bf.value }, ...(bf.departmentBookingField.lookupOptions.split('|').map(lo => { return { label: lo.trim(), value: lo.trim() } }))]
                      })
                    ]),
                    m('div.col-span-6.md:col-span-1.pt-8', [
                      m(VisibilityBadge, { visibility: bf.departmentBookingField.visibilityName })
                    ])
                  ]
                }),
                m('div.col-span-3',
                  [
                    m(TextArea, {
                      label: 'Additional Info',
                      value: booking.additionalInfo,
                      rows: 4,
                      oninput (value) {
                        booking.additionalInfo = value
                      }
                    })
                  ]
                ),
                m('div.col-span-3',
                  [
                    m(TextArea, {
                      label: 'Preference Info',
                      value: booking.preferenceInfo,
                      rows: 4,
                      oninput (value) {
                        booking.preferenceInfo = value
                      }
                    })
                  ]),
                m('div.col-span-6',
                  [
                    m(TextArea, {
                      label: 'Admin Notes',
                      value: booking.notes,
                      rows: 4,
                      oninput (value) {
                        booking.notes = value
                      }
                    })
                  ]
                )
              ])
            ])
          ])
        ])
      ]
    }
  }
}
