
import { Checkbox, TextArea } from 'components'
import { Dialog2 } from 'components/Dialog2'

export const EditFeesDialog = ({ attrs: { booking, loading } }) => {
  loading = false
  const formData = {
    isCommissionFree: booking.isCommissionFree,
    commissionFreeNotes: booking.commissionFreeNotes,
    isCancellationFeePayable: booking.isCancellationFeePayable
  }

  return {
    view ({ attrs: { booking, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          booking.isCommissionFree = formData.isCommissionFree
          booking.commissionFreeNotes = formData.commissionFreeNotes
          booking.isCancellationFeePayable = formData.isCancellationFeePayable
          onsubmit()
        },
        heading: 'Edit fees',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          m(Checkbox, {
            label: 'Commission Free?',
            checked: formData.isCommissionFree,
            onchange () {
              formData.isCommissionFree = !formData.isCommissionFree
            }
          }),
          formData.isCommissionFree && m(TextArea, {
            label: 'Commission Free Notes',
            value: formData.commissionFreeNotes,
            rows: 4,
            oninput (value) {
              formData.commissionFreeNotes = value
            }
          }),
          m(Checkbox, {
            label: 'Charge Cancellation Fee?',
            checked: formData.isCancellationFeePayable,
            onchange () {
              formData.isCancellationFeePayable = !formData.isCancellationFeePayable
            }
          })
        ])
      ])
    }
  }
}
