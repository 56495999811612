import { Breadcrumb } from 'components/Breadcrumb'
import { Input } from 'components/Input'
import { Card } from 'components/Card'
import { Button } from 'components/Button'
import { getUser, updateUser, addDepartmentUser } from 'api/users'
import { notification } from 'utils/notifications'
import { getDepartment } from 'api/clients'

export const EditDepartmentUser = ({ attrs: { clientId, departmentId, userId } }) => {
  let department
  let user
  let userLoading
  let ready

  if (departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
      const deptDictionary = {}
      deptDictionary[departmentId] = department.name
      if (userId) {
        getUser(userId)
          .then(res => {
            user = res
            ready = true
          })
      } else {
        user = {
          departments: deptDictionary,
          isEnabled: true
        }
        ready = true
      }
    })
  }

  return {
    view () {
      return [ready && m(Breadcrumb, {
        links: [{
          title: 'Clients',
          href: '/clients'
        }, {
          title: department.clientName,
          href: `/clients/${clientId}`
        }, {
          title: 'Departments',
          href: `/clients/${clientId}?tab=Departments`
        }, {
          title: department.name,
          href: `/clients/${clientId}/departments/${departmentId}`
        }, {
          title: 'Users',
          href: `/clients/${clientId}/departments/${departmentId}?tab=Users`
        }, {
          title: userId ? user.name : 'Add Department User'
        }]
      }),
      user && m('div.bg-gray-50.px-4.py-5.sm:p-6', [
        m('.p-4.w-full', [
          m('form', {
            onsubmit (e) {
              e.preventDefault()
              userLoading = true
              if (userId) {
                updateUser(user.id, user)
                  .then(res => {
                    notification.success({ title: 'User saved' })
                    userLoading = false
                    m.route.set(`/clients/${clientId}/departments/${departmentId}?tab=Users`)
                  })
                  .catch(() => {
                    notification.error({ title: 'Error. User not saved' })
                    userLoading = false
                  })
              } else {
                addDepartmentUser(user)
                  .then(res => {
                    notification.success({ title: 'User saved' })
                    userLoading = false
                    m.route.set(`/clients/${clientId}/departments/${departmentId}?tab=Users`)
                  })
                  .catch(() => {
                    notification.error({ title: 'Error. User not saved' })
                    userLoading = false
                  })
              }
            }
          }, [
            m(Card, {
              buttons: [m(Button, {
                type: 'submit',
                loading: userLoading
              }, t('createVenue', 'save'))]
            },
            m('.space-y-6.divide-y.divide-gray-200', [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6.md:col-span-4',
                  [
                    userId ? m(Input, {
                      label: t('settings', 'profileEmail'),
                      value: user.email,
                      type: 'readonly'
                    }) : m(Input, {
                      label: t('settings', 'profileEmail'),
                      value: user.email,
                      type: 'email',
                      required: true,
                      oninput (val) {
                        user.email = val
                      }
                    })

                  ]
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('settings', 'profileFirstName'),
                      value: user.firstName,
                      type: 'text',
                      required: true,
                      oninput (val) {
                        user.firstName = val
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('settings', 'profileLastName'),
                      value: user.lastName,
                      type: 'text',
                      required: true,
                      oninput (val) {
                        user.lastName = val
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('settings', 'profilePhoneNumber'),
                      type: 'tel',
                      value: user.phoneNumber,
                      oninput (val) {
                        user.phoneNumber = val
                      }
                    })
                  ]
                )
              ])
            ]))
          ])
        ])
      ])
      ]
    }
  }
}
