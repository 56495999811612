import { Button } from 'components'

export const File = () => {
  return {
    view ({
      attrs: {
        value,
        size = 'md',
        classes = [],
        oninput = () => {},
        errorMessage
      },
      children
    }) {
      return [
        !value
          ? m('label', {
            class: [
              'block text-center cursor-pointer text-sm',
              value
                ? 'rounded relative py-1 px-4 text-sm bg-red-500 hover:bg-red-400 text-white border-red-700 hover:border-red-500'
                : 'rounded relative py-1 px-4 text-sm bg-blue-500 hover:bg-blue-400 text-white border-blue-700 hover:border-blue-500',

              ...classes
            ].join(' ')
          }, [
            m('span', children),
            m('input', {
              class: 'hidden',
              type: 'file',
              onchange (e) {
                oninput(e.target.files[0])
              }
            })
          ])
          : m('.flex.justify-between', {
            class: [
              ...classes
            ].join(' ')
          }, [
            m(Button, {
              type: 'button',
              size: 'sm',
              onclick () {
                oninput(null)
              }
            }, 'Remove')
          ]),
        errorMessage &&
        m('.text-red-600.text-sm', errorMessage)
      ]
    }
  }
}
