import { Checkbox } from 'components/Checkbox'

export const CheckboxGroup = () => {
  return {
    view ({ attrs }) {
      const value = attrs.value || []
      return m('.py-3', [
        m('label.block.text-sm.text-gray-700', attrs.label),
        m('.text-red-600.text-sm', attrs.errorMessage),
        m('.justify-around', [
          attrs.options.map(o => {
            return m(Checkbox, {
              label: o.label,
              value: o.value,
              required: o.required,
              checked: value.some(v => v.value === o.value),
              onchange (val) {
                let newVal
                if (value.some(v => v.value === o.value)) {
                  newVal = value.filter(v => v.value !== o.value)
                } else {
                  newVal = [...value, o]
                }
                attrs.onchange(newVal)
              }
            })
          })
        ])

      ])
    }
  }
}
