import { Breadcrumb } from 'components/Breadcrumb'
import { Card } from 'components/Card'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Select } from 'components/Select'
import { createVenue } from 'api/venues'
import { notification } from 'utils/notifications'
import { getCountries, getVenueGroups } from 'api/system'

export const CreateVenue = () => {
  let ready
  const venue = {
    isActive: true,
    venueMeeting: {},
    venueAccommodation: {}
  }
  let countries = []
  let venueGroups = []

  Promise.all([
    getCountries(),
    getVenueGroups()
  ])
    .then(([cs, vg]) => {
      countries = cs
      venueGroups = vg
      ready = true
    })

  const create = (e) => {
    e.preventDefault()
    createVenue(venue).then(data => {
      notification.success({ title: t('createVenue', 'created') })
      m.route.set(`/venues/${data.id}`)
    })
  }

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Venues',
            href: '/venues'
          }, {
            title: 'Create Venue'
          }]
        }),
        ready && m('div.p-4',
          m('div.md:grid.md:grid-cols-3.md:gap-6',
            [
              m('div.md:col-span-1',
                m('div.px-4.sm:px-0',
                  [
                    m('h3.text-lg.font-medium.leading-6.text-gray-900',
                      'Create Venue'
                    ),
                    m('p.mt-1.text-sm.text-gray-600',
                      'Please enter the venue name and address.'
                    )
                  ]
                )
              ),
              m('div.mt-5.md:mt-0.md:col-span-2', [
                m('form', {
                  onsubmit: create
                }, [
                  m(Card, {
                    buttons: [m(Button, {
                      type: 'submit'
                    }, t('createVenue', 'save'))]
                  },
                  m('.space-y-6.divide-y.divide-gray-200', [
                    m('div.grid.grid-cols-6.gap-6', [
                      m('div.col-span-6.md:col-span-4',
                        [
                          m(Input, {
                            label: t('createVenue', 'venueName'),
                            value: venue.name,
                            type: 'text',
                            required: true,
                            oninput (value) {
                              venue.name = value
                            }
                          })
                        ]
                      ),
                      m('div.col-span-6.md:col-span-4',
                        [
                          m(Select, {
                            label: t('createVenue', 'venueGroup'),
                            search: false,
                            value: venue.venueGroupId,
                            onchange (e) {
                              venue.venueGroupId = e
                            },
                            choices: venueGroups
                          })
                        ]
                      )
                    ]),
                    m('.pt-6.grid.grid-cols-6.gap-y-6.gap-x-3',
                      [
                        m('h3.col-span-6.text-lg.leading-6.font-medium.text-gray-900',
                          'Address'
                        ),
                        m('.col-span-6.md:col-span-5',
                          [
                            m(Input, {
                              label: t('createVenue', 'addressLine1'),
                              value: venue.addressLine1,
                              type: 'text',
                              oninput (value) {
                                venue.addressLine1 = value
                              }
                            })
                          ]
                        ),
                        m('.col-span-6.md:col-span-5',
                          [
                            m(Input, {
                              label: t('createVenue', 'addressLine2'),
                              value: venue.addressLine2,
                              type: 'text',
                              oninput (value) {
                                venue.addressLine2 = value
                              }
                            })
                          ]
                        ),
                        m('.col-span-6.md:col-span-2',
                          [
                            m(Input, {
                              label: t('createVenue', 'town'),
                              value: venue.townCity,
                              type: 'text',
                              oninput (value) {
                                venue.townCity = value
                              }
                            })
                          ]
                        ),
                        m('.col-span-6.md:col-span-2',
                          [
                            m(Input, {
                              label: t('createVenue', 'county'),
                              value: venue.county,
                              type: 'text',
                              oninput (value) {
                                venue.county = value
                              }
                            })
                          ]
                        ),
                        m('.hidden.md:block.md:col-span-2'),
                        m('.col-span-6.md:col-span-2',
                          [
                            m(Input, {
                              label: t('createVenue', 'postCode'),
                              value: venue.postCode,
                              type: 'text',
                              oninput (value) {
                                venue.postCode = value
                              }
                            })
                          ]
                        ),
                        m('.col-span-6.md:col-span-2',
                          [
                            m(Select, {
                              label: t('createVenue', 'country'),
                              search: true,
                              value: venue.countryCode,
                              onchange (e) {
                                venue.countryCode = e
                              },
                              choices: countries
                            })
                          ]
                        )
                      ]
                    )
                  ]))
                ])
              ])
            ]
          )
        )
      ]
    }
  }
}
