import { Breadcrumb } from 'components/Breadcrumb'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { Input } from 'components/Input'
import { TextArea } from 'components/TextArea'
import { Checkbox } from 'components/Checkbox'
import { notification } from 'utils/notifications'

import { getDepartment, updateDepartment, createDepartment, getClient } from 'api/clients'

export const EditDepartment = ({ attrs: { clientId, departmentId } }) => {
  let department
  let ready
  let client

  if (departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
      ready = true
    })
  } else {
    department = { isActive: true }
    getClient(clientId).then(data => {
      client = data
      department.clientName = client.name
      ready = true
    })
  }

  const update = (e) => {
    e.preventDefault()
    if (departmentId) {
      updateDepartment(clientId, department).then(() => {
        notification.success({ title: 'Department updated' })
        m.route.set(`/clients/${clientId}/departments/${departmentId}`)
      })
    } else {
      createDepartment(clientId, department).then((data) => {
        notification.success({ title: 'Department created' })
        m.route.set(`/clients/${clientId}/departments/${data.id}`)
      })
    }
  }

  return {
    view ({ attrs: { clientId, departmentId } }) {
      return [
        ready && m(Breadcrumb, {
          links: [{
            title: 'Clients',
            href: '/clients'
          }, {
            title: department.clientName || (client && client.name),
            href: `/clients/${clientId}`
          }, {
            title: 'Departments',
            href: `/clients/${clientId}?tab=Departments`
          }, {
            title: !departmentId ? 'Create' : department.name,
            href: departmentId && `/clients/${clientId}/departments/${departmentId}`
          }, departmentId && {
            title: 'Edit'
          }]
        }),
        ready && m(EditForm, {
          onsubmit: update
        },
        [
          m(EditFormSection, {
            heading: `${!departmentId ? 'Create' : 'Edit'} Department`,
            description: 'Update the department details'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6.md:col-span-3',
                [
                  m(Input, {
                    label: 'Name',
                    value: department.name,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      department.name = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-6',
                [
                  m(TextArea, {
                    classes: ['w-full'],
                    label: 'Description',
                    value: department.description,
                    oninput (e) {
                      department.description = e
                    }
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(Checkbox, {
                    label: 'Active',
                    checked: department.isActive,
                    onchange () {
                      department.isActive = !department.isActive
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-6',
                [
                  m(TextArea, {
                    classes: ['w-full'],
                    label: 'Notes',
                    value: department.notes,
                    oninput (e) {
                      department.notes = e
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-4',
                [
                  m(Input, {
                    label: 'Email',
                    value: department.email,
                    type: 'text',
                    required: false,
                    oninput (value) {
                      department.email = value
                    }
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(Checkbox, {
                    label: 'Only Require 1 Venue?',
                    checked: department.onlyRequireOneVenueChoice,
                    onchange () {
                      department.onlyRequireOneVenueChoice = !department.onlyRequireOneVenueChoice
                    }
                  })
                ]
              ),
            ])
          ])
        ])
      ]
    }
  }
}
