import { request } from './request'

export const getDelegateVenues = (params) => {
  return request({
    url: 'delegates/accommodations',
    method: 'GET',
    params: {
      pagesize: 9999,
      ...params
    }
  })
}

export const getDelegateDeptInfo = (params) => {
  return request({
    url: 'delegates/departments',
    method: 'GET',
    params
  })
}

export const addBooking = (body, params) => {
  return request({
    url: 'delegates/bookings',
    method: 'POST',
    body,
    params
  })
}
