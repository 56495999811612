
import { Select, Input, Checkbox, Dialog2, LoadingFullScreen, TinyMce } from 'components'
import { getMeetingEmail, getDefaultMeetingEmail, getMeetingContacts } from 'api/meetings'

export const UpdateStatusDialog = ({ attrs: { meeting, templates, loading, templateType, sendEmail, emailToAddresses, statusId } }) => {
  loading = false
  let editor
  let selectTemplate

  const formData = {
    statusId: statusId,
    sendEmail: sendEmail,
    email: {
      subject: '',
      emailBody: '',
      templateId: null,
      templateType: templateType || null,
      emailToAddresses: templateType === 3 ? [meeting.email] : [],
      delay: 15
    },
    newEmailToAddresses: []
  }

  let contacts
  getMeetingContacts(meeting.id).then(data => {
    contacts = data
  })

  if (templateType) {
    getDefaultMeetingEmail(meeting.id, formData.email.templateType).then(data => {
      formData.email.subject = data.subject
      formData.email.templateId = data.templateId
      formData.email.delay = data.delay
      const value = data.emailBody
      if (value) {
        editor.setContent(value)
        formData.email.emailBody = value
      }
      if (selectTemplate) {
        selectTemplate.setChoiceByValue(formData.email.templateId)
      }
    })
  }

  return {
    view ({ attrs: { meeting, templates, statuses, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          if (!formData.sendEmail) {
            formData.email = null
            formData.newEmailToAddresses = null
            loading = true
            onsubmit(formData)
          } else {
            formData.email.emailToAddresses = formData.email.emailToAddresses.concat(formData.newEmailToAddresses)

            if (formData.email.emailToAddresses.length) {
              loading = true
              onsubmit(formData)
            }
          }
        },
        classes: ['lg:max-w-3xl', 'xl:max-w-4xl'],
        heading: 'Update meeting status',
        saving: loading,
        saveLabel: formData.sendEmail ? 'Send Email & Update' : 'Update Status'
      }, [
        m('.flex.flex-col.gap-3', [
          m(Select, {
            label: 'Change Status',
            value: formData.statusId,
            choices: statuses.map(st => {
              return { label: st.name, value: st.id }
            }),
            onchange (val) {
              formData.statusId = val
            }
          }),
          m(Checkbox, {
            label: 'Send Email?',
            checked: formData.sendEmail,
            onchange () {
              formData.sendEmail = !formData.sendEmail
            }
          }),
          formData.sendEmail && [
            m(Select, {
              label: 'Select Template',
              value: formData.email.templateId,
              choices: templates.map(template => {
                return { label: template.description, value: template.id }
              }),
              onchange (val) {
                formData.email.templateId = val
                getMeetingEmail(meeting.id, formData.email.templateId).then(data => {
                  formData.email.subject = data.subject
                  formData.email.templateType = data.templateType
                  const value = data.emailBody
                  if (value) {
                    editor.setContent(value)
                    formData.email.emailBody = value
                  }
                })
              },
              instance (obj) {
                selectTemplate = obj
              }
            }),
            m('.grid.grid-cols-3.gap-x-3', [
              m('.col-span-2',
                contacts ? m(Select, {
                  label: 'To',
                  value: formData.email.emailToAddresses.length ? formData.email.emailToAddresses : [],
                  choices: contacts.map((contact, ix) => {
                    return { disabled: !contact.email, label: contact.name ? `${contact.name} &lt;${contact.email || 'No email set'}&gt; (${contact.type})` : `${contact.email || 'No email set'} (${contact.type})`, value: ix }
                  }),
                  subtype: 'multiple',
                  removeItemButton: true,
                  onchange (val) {
                    formData.email.emailToAddresses = val.map(contactIx => {
                      return contacts[contactIx].email
                    })
                  }
                }) : m(LoadingFullScreen)
              ),

              m(Input, {
                label: 'New email address',
                value: formData.newEmailToAddresses.length ? formData.newEmailToAddresses.join(',') : '',
                oninput (value) {
                  formData.newEmailToAddresses = value.split(',')
                }
              })
            ]),
            m('.grid.grid-cols-3.gap-x-3', [
              m('.col-span-2',
                m(Input, {
                  label: 'Subject',
                  value: formData.email.subject,
                  required: true,
                  oninput (value) {
                    formData.email.subject = value
                  }
                })
              ), m('div',
                m(Input, {
                  label: 'Delay',
                  value: formData.email.delay,
                  required: true,
                  oninput (value) {
                    formData.email.delay = value
                  },
                  suffix: 'mins'
                })
              )
            ]),
            m('.flex.flex-col', [
              m(TinyMce, {
                value: formData.email.emailBody,
                label: 'Body',
                height: 300,
                onchange: (content) => {
                  formData.email.emailBody = content
                },
                editor: (instance) => {
                  editor = instance
                }
              })
            ])
          ]

        ])
      ])
    }
  }
}
