
import { Breadcrumb } from 'components/Breadcrumb'
import { Input, TextArea, Checkbox } from 'components'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { DateTime } from 'luxon'
import { getDepartment } from 'api/clients'
import { getMeeting, updateMeeting, addMeeting } from 'api/meetings'
import { notification } from 'utils/notifications'

export const EditMeeting = ({ attrs: { clientId, departmentId, meetingId, copy } }) => {
  let ready
  let meeting
  let department

  if (meetingId) {
    Promise.all([
      getDepartment(clientId, departmentId),
      getMeeting(meetingId)
    ])
      .then(([d, m]) => {
        department = d
        meeting = m
        ready = true
      })
  } else {
    meeting = {
      departmentId: departmentId
    }

    getDepartment(clientId, departmentId).then((data) => {
      department = data
      ready = true
    })
  }

  const saveMeeting = (e) => {
    e.preventDefault()
    if (meeting.id) {
      updateMeeting(meetingId, meeting).then((data) => {
        notification.success({ title: 'Updated' })
        m.route.set(`/clients/${clientId}/departments/${departmentId}/meetings/${data.id}`)
      }).catch(err => {
        console.log(err)
        notification.error({ title: err.response })
      })
    } else {
      addMeeting(meeting).then((data) => {
        notification.success({ title: 'Created' })
        m.route.set(`/clients/${clientId}/departments/${departmentId}/meetings/${data.id}`)
      }).catch(err => {
        console.log(err)
        notification.error({ title: err.response })
      })
    }
  }

  return {
    view ({ attrs: { meetingId, clientId, departmentId, tab } }) {
      return [
        ready && m('.pr-8', [
          m(Breadcrumb, {
            links: [{
              title: 'Clients',
              href: '/clients'
            }, {
              title: department.clientName,
              href: `/clients/${clientId}`
            }, {
              title: 'Departments',
              href: `/clients/${clientId}?tab=Departments`
            }, {
              title: department.name,
              href: `/clients/${clientId}/departments/${departmentId}`
            }, {
              title: t('app', 'meetings'),
              href: `/clients/${clientId}/departments/${departmentId}?tab=Meetings`
            }, {
              title: meeting.id > 0 ? `Edit ${meeting.name}: ${DateTime.fromISO(meeting.arrivalDate).toLocaleString()}` : 'New'
            }]
          }),
          copy ? m('div.text-center.px-3.py-1.font-medium.bg-purple-200.text-purple-800.text-xl', 'Cloned Meeting - New Request') : '',
          m(EditForm, {
            onsubmit: saveMeeting
          },
          [
            m(EditFormSection, {
              heading: 'Meeting Details',
              description: meeting.id ? 'Update the meeting details' : 'Add the meeting details'
            }, [
              m('.grid.grid-cols-6.gap-6', [
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'tobook Ref',
                      value: meeting.eventRef,
                      type: 'text',
                      required: false,
                      oninput (value) {
                        meeting.eventRef = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-4'),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: 'Description',
                      value: meeting.description,
                      type: 'text',
                      required: true,
                      oninput (value) {
                        meeting.description = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'Arrival Date',
                      value: meeting.arrivalDate,
                      type: 'date',
                      required: true,
                      oninput (value) {
                        meeting.arrivalDate = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-1',
                  [
                    m(Input, {
                      label: 'Days',
                      value: meeting.days,
                      type: 'number',
                      required: true,
                      oninput (value) {
                        meeting.days = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2'),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'Start Time',
                      value: meeting.startTime,
                      type: 'text',
                      required: false,
                      oninput (value) {
                        meeting.startTime = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'End Time',
                      value: meeting.endTime,
                      type: 'text',
                      required: false,
                      oninput (value) {
                        meeting.endTime = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-2'),
                m('div.col-span-6.md:col-span-2',
                  [
                    m(Input, {
                      label: 'No of Delegates',
                      value: meeting.noOfDelegates,
                      type: 'number',
                      required: true,
                      oninput (value) {
                        meeting.noOfDelegates = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-5'),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: 'Contact Name',
                      value: meeting.name,
                      type: 'text',
                      required: true,
                      oninput (value) {
                        meeting.name = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: 'Email',
                      value: meeting.email,
                      type: 'email',
                      required: true,
                      oninput (value) {
                        meeting.email = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-3',
                  [
                    m(Input, {
                      label: 'Telephone',
                      value: meeting.telephone,
                      type: 'text',
                      // required: true,
                      oninput (value) {
                        meeting.telephone = value
                      }
                    })
                  ]
                ),
                m('div.col-span-6',
                  [
                    m(TextArea, {
                      label: 'Admin Notes',
                      value: meeting.notes,
                      rows: 4,
                      oninput (value) {
                        meeting.notes = value
                      }
                    })
                  ]
                )
              ])
            ]),
            m(EditFormSection, {
              heading: 'Venue Room Details',
              description: meeting.id ? 'Update the venue room details' : 'Add the venue room details'
            }, [
              m('.grid.grid-cols-6.gap-6', [
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: 'Room Name',
                      value: meeting.roomName,
                      type: 'text',
                      oninput (value) {
                        meeting.roomName = value
                      }
                    })
                  ]
                ),
                m('.col-span-6.md:col-span-5',
                  [
                    m(Input, {
                      label: 'Layout',
                      value: meeting.layout,
                      type: 'text',
                      oninput (value) {
                        meeting.layout = value
                      }
                    })
                  ]
                ),

                m('div.col-span-6.md:col-span-4', [
                  m(Checkbox, {
                    label: 'AV Required?',
                    checked: meeting.isAVRequired,
                    onchange () {
                      meeting.isAVRequired = !meeting.isAVRequired
                    }
                  })
                ])
              ])
            ]),
            m(EditFormSection, {
              heading: 'Requirements',
              description: meeting.id ? 'Update the meeting requirements' : 'Add the meeting requirements'
            }, [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6',
                  [
                    m(TextArea, {
                      label: 'Requirements',
                      value: meeting.requirementsInfo,
                      rows: 10,
                      oninput (value) {
                        meeting.requirementsInfo = value
                      }
                    })
                  ]
                )
              ])
            ]),
            m(EditFormSection, {
              heading: 'Quote Details',
              description: meeting.id ? 'Update the meeting quote details' : 'Add the meeting quote details'
            }, [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6',
                  [
                    m(TextArea, {
                      label: 'Quote Details',
                      value: meeting.quoteInfo,
                      rows: 10,
                      oninput (value) {
                        meeting.quoteInfo = value
                      }
                    })
                  ])
              ])
            ])
          ])
        ])
      ]
    }
  }
}
