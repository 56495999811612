import { Dialog2 } from 'components/Dialog2'
import { File } from 'components/File'

export const ImportBookingsDialog = ({ attrs: { loading } }) => {
  loading = false

  const importForm = {
    file: null,
    error: null
  }
  return {
    view ({ attrs: { loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        saveLabel: 'Import',
        save () {
          loading = true
          if (importForm.file) {
            onsubmit(importForm)
          } else {
            importForm.error = 'File required'
          }
        },
        heading: 'Import Bookings',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          m('h5.text-sm.text-gray-500', `Import spreadsheet: ${importForm.file ? importForm.file.name : ''}`),
          importForm.error
            ? m('h5.text-sm.text-red-500', importForm.error)
            : '',
          m('.mt-2.grid.md:grid-cols-2.gap-3', [
            m(File, {
              type: 'file',
              name: 'file',
              classes: ['my-2'],
              label: 'Upload',
              value: importForm.file,
              oninput: (val) => {
                importForm.error = null
                importForm.file = val
              }
            }, 'Select File')
          ])

        ])
      ])
    }
  }
}
