import { Breadcrumb, CheckboxGroup, Checkbox, Input, Card, Button } from 'components'
import { getUser, updateUser, addClientUser, addDepartmentUser } from 'api/users'
import { notification } from 'utils/notifications'
import { getClient } from 'api/clients'

export const EditClientUser = ({ attrs: { clientId, userId } }) => {
  let client
  let user
  let userLoading
  let ready

  if (clientId) {
    getClient(clientId).then(data => {
      client = data
      if (userId) {
        getUser(userId)
          .then(res => {
            user = res
            if (user.departments) {
              user.departmentsArray = Object.keys(user.departments).map(dept => {
                return { label: user.departments[dept], value: dept }
              })
            } else {
              user.departmentsArray = []
            }
            ready = true
          })
      } else {
        user = {
          clients: {},
          departments: {},
          departmentsArray: []
        }
        user.clients[clientId] = client.name
        ready = true
      }
    })
  }

  return {
    view () {
      return [ready && m(Breadcrumb, {
        links: [{
          title: 'Clients',
          href: '/clients'
        }, {
          title: client.name,
          href: `/clients/${clientId}`
        }, {
          title: 'Users',
          href: `/clients/${clientId}?tab=Users`
        }, {
          title: userId ? 'Edit User' : 'Add User'
        }]
      }),
      user && m('div.bg-gray-50.px-4.py-5.sm:p-6', [
        m('.p-4.w-full', [
          m('form', {
            onsubmit (e) {
              e.preventDefault()
              userLoading = true
              if (user.departmentsArray.length) {
                user.clients = {}
                user.departments = {}
                user.departmentsArray.forEach(element => {
                  user.departments[element.value] = element.label
                })
                if (userId) {
                } else {
                  addDepartmentUser(user)
                    .then(res => {
                      notification.success({ title: 'User saved' })
                      userLoading = false
                      m.route.set(`/clients/${clientId}?tab=Users`)
                    })
                    .catch(() => {
                      notification.error({ title: 'Error. User not saved' })
                      userLoading = false
                    })
                }
              } else {
                user.clients[clientId] = client.name
                user.departments = {}
                if (userId) {
                } else {
                  addClientUser(user)
                    .then(res => {
                      notification.success({ title: 'User saved' })
                      userLoading = false
                      m.route.set(`/clients/${clientId}?tab=Users`)
                    })
                    .catch(() => {
                      notification.error({ title: 'Error. User not saved' })
                      userLoading = false
                    })
                }
              }
              if (userId) {
                updateUser(user.id, user)
                  .then(res => {
                    notification.success({ title: 'User saved' })
                    userLoading = false
                    m.route.set(`/clients/${clientId}?tab=Users`)
                  })
                  .catch(() => {
                    notification.error({ title: 'Error. User not saved' })
                    userLoading = false
                  })
              } else {

              }
            }
          }, [
            m(Card, {
              buttons: [m(Button, {
                type: 'submit',
                loading: userLoading
              }, t('createVenue', 'save'))]
            },
            m('.space-y-6.divide-y.divide-gray-200', [
              m('div.grid.grid-cols-6.gap-6', [
                m('div.col-span-6.md:col-span-4',
                  [
                    userId ? m(Input, {
                      label: 'Username',
                      value: user.userName,
                      type: 'readonly'
                    }) : m(Input, {
                      label: 'Username',
                      value: user.userName,
                      type: 'text',
                      required: false,
                      oninput (val) {
                        user.userName = val
                      }
                    })

                  ]
                ),
                !userId && user.userName && m('div.col-span-6.md:col-span-2',
                  m(Input, {
                    label: 'Set ' + t('register', 'password'),
                    value: user.newPassword,
                    type: 'password',
                    required: true,
                    oninput (val) {
                      user.newPassword = val
                    }
                  })
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    userId ? m(Input, {
                      label: t('settings', 'profileEmail'),
                      value: user.email,
                      type: 'readonly'
                    }) : m(Input, {
                      label: t('settings', 'profileEmail'),
                      value: user.email,
                      type: 'email',
                      required: true,
                      oninput (val) {
                        user.email = val
                      }
                    })

                  ]
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('settings', 'profileFirstName'),
                      value: user.firstName,
                      type: 'text',
                      required: true,
                      oninput (val) {
                        user.firstName = val
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('settings', 'profileLastName'),
                      value: user.lastName,
                      type: 'text',
                      required: true,
                      oninput (val) {
                        user.lastName = val
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Input, {
                      label: t('settings', 'profilePhoneNumber'),
                      type: 'tel',
                      value: user.phoneNumber,
                      oninput (val) {
                        user.phoneNumber = val
                      }
                    })
                  ]
                ),
                m('div.col-span-6.md:col-span-4',
                  [
                    m(Checkbox, {
                      label: 'Restrict access to department level?',
                      checked: user.isDepartmentUser,
                      onchange () {
                        user.isDepartmentUser = !user.isDepartmentUser
                      }
                    })
                  ]
                ),
                user.isDepartmentUser && m('div.col-span-6.md:col-span-4',
                  [
                    m(CheckboxGroup, {
                      label: 'Departments',
                      value: user.departmentsArray,
                      options: client.departments.map(d => {
                        return { label: d.name, value: d.id }
                      }),
                      onchange (val) {
                        user.departmentsArray = val
                        console.log(user)
                      }
                    })
                  ]
                )
              ])
            ]))
          ])
        ])
      ])
      ]
    }
  }
}
