
import { Input } from 'components'
import { Dialog2 } from 'components/Dialog2'

export const EditRefsDialog = ({ attrs: { booking, loading } }) => {
  loading = false

  const formData = {
    reservationCode: booking.reservationCode
  }

  return {
    view ({ attrs: { booking, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          booking.reservationCode = formData.reservationCode
          onsubmit()
        },
        heading: 'Edit Reservation Code',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          m(Input, {
            label: 'Reservation Code',
            value: formData.reservationCode,
            type: 'text',
            oninput (value) {
              formData.reservationCode = value
            }
          })
        ])
      ])
    }
  }
}
