import { Breadcrumb, Button, Card, Table, Cell, Pagination, BookingStatusBadge, Input, Select, LoadingFullScreen } from 'components'
import { DateTime } from 'luxon'
import { getMeetingsSearch, exportMeetingsSearch } from 'api/search'
import { getAllDepartments, getClients, getDepartmentVenues } from 'api/clients'
// import { getVenues } from 'api/venues'
import { getMeetingStatuses } from 'api/meetings'

export const SearchMeetings = ({ attrs: { filters } }) => {
  if (!filters) { filters = { page: 1, statusIds: [0, 1, 2], sortBy: 'arrival', sortOrder: 'desc', name: null, poNumber: null, eventRef: null, search: null } }
  let meetings = []
  let meta
  let departments
  let clients
  // let venues
  let statuses
  let criteriaReady = false
  let tableLoading = false
  let selectSearch
  let selectDepartment
  let selectVenue
  let exportLoading = false

  Promise.all([
    getAllDepartments({ pagesize: 100 }),
    getClients({ pagesize: 100 }),
    // getVenues({ pagesize: 10000 }),
    getMeetingStatuses()
  ]).then(([d, c, s]) => {
    departments = d.data.map(row => {
      return { label: row.name, value: row.id, clientId: row.clientId }
    })
    clients = c.data.map(row => {
      return { label: row.name, value: row.id }
    })
    // venues = v.data.map(row => {
    //   return { label: row.name, value: row.id }
    // })
    statuses = s.map(row => {
      return { label: row.name, value: row.id }
    })
    criteriaReady = true
  })

  const search = () => {
    meetings = []
    tableLoading = true
    getMeetingsSearch(filters).then(data => {
      meetings = data.data
      meta = data.meta
      tableLoading = false
    })
  }

  // const refreshData = () => {
  //   getBookings(filters).then(data => {
  //     bookings = data.data
  //     meta = data.meta
  //   })
  // }

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Search Meetings'
          }]
        }),
        m('.my-4.px-4',
          [
            criteriaReady ? m('form', {
              onsubmit (e) {
                e.preventDefault()
                search()
              }
            }, [
              m(Card, {
                buttons: [
                  m(Button, {
                    type: 'button',
                    loading: exportLoading,
                    onclick () {
                      exportLoading = true
                      exportMeetingsSearch(filters).then(() => {
                        exportLoading = false
                      })
                    }
                  }, 'Export'),
                  m(Button, {
                    type: 'submit',
                    loading: tableLoading
                  }, 'Search')]
              }, m('.grid.grid-cols-6.gap-4', [
                m('.col-span-2', [
                  m(Select, {
                    label: 'Select a client',
                    value: filters.clientId,
                    choices: clients,
                    search: true,
                    required: false,
                    onchange (val, label) {
                      filters.clientId = val
                      selectDepartment.setChoices(departments.filter(d => {
                        return val === '' || d.clientId === Number(val)
                      }).map(d => {
                        return {
                          label: d.label,
                          value: d.value
                        }
                      }), 'value', 'label', true)
                    }
                  })
                ]),
                m('.col-span-2', [
                  m(Select, {
                    label: 'Select a department',
                    value: filters.departmentId,
                    choices: departments,
                    search: true,
                    required: false,
                    onchange (val, label) {
                      filters.departmentId = val
                      getDepartmentVenues(val, { pagesize: 1000 }).then(dv => {
                        const venues = dv.data.map(d => {
                          return {
                            label: d.venueName,
                            value: d.venueId
                          }
                        })
                        selectVenue.setChoices(venues, 'value', 'label', true)
                      })
                    },
                    instance (obj) {
                      selectDepartment = obj
                    }
                  })
                ]),
                m('.col-span-2', [
                  m(Select, {
                    label: 'Select a venue',
                    value: filters.venueId,
                    choices: [],
                    search: true,
                    required: false,
                    onchange (val, label) {
                      filters.venueId = val
                    },
                    instance (obj) {
                      selectVenue = obj
                    }
                  })
                ]),
                m('.col-span-5', [
                  m(Select, {
                    label: 'Select status(es)',
                    value: filters.statusIds,
                    choices: statuses,
                    search: true,
                    required: false,
                    subtype: 'multiple',
                    removeItemButton: true,
                    onchange (val) {
                      filters.statusIds = val
                    },
                    instance (obj) {
                      selectSearch = obj
                    }
                  })
                ]),
                m('.flex.items-end', [
                  m(Button, {
                    type: 'button',
                    size: 'sm',
                    onclick () {
                      selectSearch.removeActiveItems()
                      filters.statusIds = null
                    }
                  }, 'Clear all')
                ]),
                m('.col-span-1', [
                  m(Input, {
                    label: 'Date from',
                    value: filters.fromDate,
                    type: 'date',
                    oninput (value) {
                      filters.fromDate = value
                    },
                    dateInstance (obj) {
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Input, {
                    label: 'Date to',
                    value: filters.toDate,
                    type: 'date',
                    oninput (value) {
                      filters.toDate = value
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Input, {
                    label: 'Days from',
                    value: filters.daysFrom,
                    type: 'number',
                    oninput (value) {
                      filters.daysFrom = value
                    }
                  })
                ]),
                m('.col-span-1', [
                  m(Input, {
                    label: 'Days to',
                    value: filters.daysTo,
                    type: 'number',
                    oninput (value) {
                      filters.daysTo = value
                    }
                  })
                ]),
                m('.col-span-2'),
                m('.col-span-2', [
                  m(Input, {
                    label: 'Description / Contact / Email',
                    value: filters.search,
                    type: 'text',
                    oninput (value) {
                      filters.search = value
                    }
                  })
                ]),
                m('.col-span-2', [
                  m(Input, {
                    label: 'tobook Reference',
                    value: filters.eventRef,
                    type: 'text',
                    oninput (value) {
                      filters.eventRef = value
                    }
                  })
                ]),
                m('.col-span-2', [
                  m(Input, {
                    label: 'PO Number',
                    value: filters.poNumber,
                    type: 'text',
                    oninput (value) {
                      filters.poNumber = value
                    }
                  })
                ])
              ])
              )
            ]) : m(LoadingFullScreen),
            m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
              m(Table, {
                tight: true,
                cols: [
                  {
                    label: 'Ref'
                  },
                  {
                    label: 'Description'
                  },
                  {
                    label: 'Contact'
                  },
                  {
                    label: 'Email',
                    filterClasses: 'hidden'
                  },
                  {
                    label: 'Department',
                    classes: ['w-24']
                  },
                  {
                    label: 'Venue'
                  },
                  {
                    label: 'Arrival',
                    classes: ['w-24 justify-center']
                  },
                  {
                    label: 'Days',
                    classes: ['w-20 justify-center']
                  },
                  {
                    label: 'Delegates',
                    classes: ['w-20 justify-center']
                  },
                  {
                    label: 'Status',
                    classes: ['w-40 justify-center']
                  }
                ],
                filters: true,
                footer: meta && meta.totals
                  ? Object.keys(meta.totals).map(t => {
                    return [m('.flex', [m('span.font-bold', t + ':'), m('span.ml-1', meta.totals[t])])]
                  })
                  : false
              },
              [
                tableLoading ? m(LoadingFullScreen) : m('.overflow-auto.flex-grow',
                  meetings.length ? meetings.map((v, ix) => {
                    return m(MeetingListItem, { item: v, key: v.id })
                  }) : m('p.text-center.p-3.text-gray-400', 'No results')
                ),
                meta && m(Pagination, {
                  meta: meta,
                  onpage: (page) => {
                    filters.page = page
                    getMeetingsSearch(filters).then(data => {
                      meetings = data.data
                      meta = data.meta
                    })
                  }
                })
              ])
            )
          ])
      ]
    }
  }
}

const MeetingListItem = ({ attrs: { item, classes = [], clientId, departmentId } }) => {
  const newFormat = { ...DateTime.DATE_SHORT, year: '2-digit', time: false }
  return {
    view () {
      const rowColor = 'bg-white'
      return m(m.route.Link, {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'cursor-pointer', rowColor, ...classes].join(' '),
        href: `/clients/${item.clientId}/departments/${item.departmentId}/meetings/${item.id}`
      }, [
        m(Cell, { tight: true }, m('span', item.eventRef)),
        m(Cell, { tight: true }, m('span', item.description)),
        m(Cell, { tight: true }, m('span', item.name)),
        m(Cell, { tight: true, cellClasses: ['tracking-tight'] }, m('span', item.email)),
        m(Cell, { width: 'w-24', tight: true },
          item.departmentName
        ),
        m(Cell, { tight: true, cellClasses: ['tracking-tight'] },
          item.venueName
        ),
        m(Cell, { width: 'w-24', align: 'center', tight: true },
          DateTime.fromISO(item.arrivalDate).toLocaleString(newFormat)
        ),
        m(Cell, { width: 'w-20', align: 'center', tight: true },
          item.days
        ),
        m(Cell, { width: 'w-20', align: 'center', tight: true },
          item.noOfDelegates
        ),
        m(Cell, { width: 'w-40', align: 'center', tight: true, noEllipsis: 'true' },
          m(BookingStatusBadge, {
            status: item.statusName, size: 'xs', margin: 'm-0'
          }
          )
        )
      ])
    }
  }
}
