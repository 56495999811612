import { Breadcrumb, Select, Input, EditForm, EditFormSection, TinyMce } from 'components'
import { getEmailTemplate, updateEmailTemplate, createEmailTemplate } from 'api/emailTemplates'
import { notification } from 'utils/notifications'

export const EditEmailTemplate = ({ attrs: { id } }) => {
  let ready
  let emailTemplate

  if (id) {
    getEmailTemplate(id).then(data => {
      emailTemplate = data

      ready = true
    })
  } else {
    emailTemplate = {}
    ready = true
  }

  const createOrUpdate = (e) => {
    e.preventDefault()
    if (id) {
      updateEmailTemplate(emailTemplate.id, emailTemplate).then(() => {
        notification.success({ title: 'Email template updated' })
        m.route.set('/email-templates')
      }).catch(err => {
        notification.error({ title: err.response })
      })
    } else {
      createEmailTemplate(emailTemplate).then(() => {
        notification.success({ title: 'Email template created' })
        m.route.set('/email-templates')
      }).catch(err => {
        notification.error({ title: err.response })
      })
    }
  }

  const bookingTags = () => {
    return {
      view () {
        return [m('ul', [
          m('li', '{{Name}}'),
          m('li', '{{ArrivalDate}}'),
          m('li', '{{Nights}}'),
          m('li', '{{VenueP1}}'),
          m('li', '{{RateDescriptionP1}}'),
          m('li', '{{VenueP2}}'),
          m('li', '{{RateDescriptionP2}}'),
          m('li', '{{PreferenceInfo}}'),
          m('li', '{{AdditionalInfo}}'),
          m('li', '{{Notes}}'),
          m('li', '{{ReservationNumber}}'),
          m('li', '{{GuestBillingInstructions}}'),
          m('li', '{{VenueBillingInstructions}}'),
          m('li', '{{DailyTariff}}'),
          m('li', '{{TotalTariff}}'),
          m('li', '{{RateDescription}}'),
          m('li', '{{VenueName}}'),
          m('li', '{{Venue}}'),
          m('li', '{{VenuePhone}}'),
          m('li', '{{VenueWebsite}}'),
          m('li', '{{Department}}'),
          m('li', '{{PONumberContactName}}'),
          m('li', '{{PONumberContactEmail}}'),
          m('li', '{{PONumber}}'),
          m('li', '{{BF:[dept booking field name]}}'),
          m('li', '{{BFL:V:[dept booking field name]}}'),
          m('li', '{{BFTable}}')
        ])]
      }
    }
  }

  const meetingTags = () => {
    return {
      view () {
        return [m('ul', [
          m('li', '{{Description}}'),
          m('li', '{{ContactName}}'),
          m('li', '{{ContactEmail}}'),
          m('li', '{{ArrivalDate}}'),
          m('li', '{{Days}}'),
          m('li', '{{NoOfDelegates}}'),
          m('li', '{{RequirementsInfo}}'),
          m('li', '{{QuoteInfo}}'),
          m('li', '{{Notes}}'),
          m('li', '{{PONumber}}'),
          m('li', '{{TotalTariff}}'),
          m('li', '{{VenueName}}'),
          m('li', '{{VenueAddress}}'),
          m('li', '{{VenuePhone}}'),
          m('li', '{{VenueWebsite}}'),
          m('li', '{{Department}}'),
          m('li', '{{Client}}'),
          m('li', '{{ClientAddress}}'),
          m('li', '{{Rates}}')
        ])]
      }
    }
  }

  const invoiceTags = () => {
    return {
      view () {
        return [m('ul', [
          m('li', 'Invoice Document:'),
          m('li', ''),
          m('li', '{{VenueName}}'),
          m('li', '{{BillingContactName}}'),
          m('li', '{{VenueBillingName}}'),
          m('li', '{{DepartmentName}}'),
          m('li', '{{Department}}'),
          m('li', '{{InvoiceNumber}}'),
          m('li', '{{InvoiceDate}}'),
          m('li', '{{InvoiceTotalGross}}')
        ])]
      }
    }
  }

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Email Templates',
            href: '/email-templates'
          }, {
            title: !id ? 'Create' : (ready ? `Edit ${emailTemplate.description}` : 'Edit')
          }]
        }),

        ready && m(EditForm, {
          onsubmit: createOrUpdate
        },
        [
          m(EditFormSection, {
            heading: id ? 'Edit Email Template' : 'Create Email Template',
            description: emailTemplate.templateType < 10 ? 'Allowed Accommodation tags' : emailTemplate.templateType === 10 ? 'Allowed Invoice tags' : emailTemplate.templateType > 10 ? 'Allowed Meetings tags' : '',
            extra: emailTemplate.templateType < 10 ? m(bookingTags) : emailTemplate.templateType === 10 ? m(invoiceTags) : emailTemplate.templateType > 10 ? m(meetingTags) : null
          }, [
            m('.grid.grid-cols-6.gap-6', [
              id ? m('.col-span-4',
                [
                  m(Input, {
                    label: 'Template Type',
                    value: emailTemplate.templateTypeString,
                    type: 'readonly'
                  })

                ])
                : m('.col-span-4',
                  [
                    m(Select, {
                      label: 'Template Type',
                      search: true,
                      required: true,
                      value: emailTemplate.templateType,
                      onchange (e) {
                        emailTemplate.templateType = e
                      },
                      choices: [
                        { label: 'Booking Ad Hoc', value: 6 },
                        // { label: 'Booking Acknowledgement', value: 1 },
                        // { label: 'Booking Request', value: 2 },
                        // { label: 'Booking Confirmation', value: 3 },
                        // { label: 'Booking Reminder', value: 4 },
                        // { label: 'Booking Cancellation', value: 5 },
                        { label: 'Meeting Ad Hoc', value: 11 }
                        // { label: 'Meeting Quote Request', value: 12 },
                        // { label: 'Meeting Quote To Client', value: 13 },
                        // { label: 'Meeting Confirmation To Client', value: 14 },
                        // { label: 'Meeting PO Request', value: 15 },
                        // { label: 'Meeting Cancellation', value: 16 },
                        // { label: 'Invoice', value: 10 }
                      ]
                    })
                  ]),
              m('.col-span-2'),
              ready && m('.col-span-4',
                [
                  m(Input, {
                    label: 'Name',
                    value: emailTemplate.description,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      emailTemplate.description = value
                    }
                  })
                ]
              ),
              ready && m('.col-span-2',
                [
                  m(Input, {
                    label: 'Delay',
                    value: emailTemplate.delay,
                    type: 'number',
                    required: true,
                    oninput (value) {
                      emailTemplate.delay = value
                    },
                    suffix: 'mins'
                  })

                ]),
              ready && m('.col-span-6',
                [
                  m(Input, {
                    label: 'Default Subject',
                    value: emailTemplate.defaultSubject,
                    type: 'text',
                    required: false,
                    oninput (value) {
                      emailTemplate.defaultSubject = value
                    }
                  })
                ]
              ),
              m('.col-span-6.flex.flex-col', [
                m(TinyMce, {
                  value: emailTemplate.body,
                  label: 'Email Content',
                  name: 'email_content',
                  onchange: (content) => {
                    emailTemplate.body = content
                  }
                })
              ])
            ])
          ])
        ])
      ]
    }
  }
}
