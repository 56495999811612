import { Dialog2, LoadingFullScreen } from 'components'
import { EmailMessageStatusBadge } from 'components/Badge'
import { DateTime } from 'luxon'

export const EmailMessageDialog = ({ attrs: { loading } }) => {
  loading = false

  return {
    view ({ attrs: { email, open } }) {
      return m(Dialog2, {
        open,
        heading: email.to && m('.flex.justify-between.pr-6', [`To: ${email.to}`, m(EmailMessageStatusBadge, { status: email.statusName, statusId: email.emailMessageStatusId })]),
        classes: ['md:max-w-2xl'],
        cancelLabel: 'Close'
      }, [
        email.to ? m('.flex.flex-col.gap-3', [
          m('iframe.border.h-72', {
            srcdoc: email.body
          }),
          email.sentDate && m('p', `Sent: ${DateTime.fromISO(email.sentDate).toLocaleString(DateTime.DATETIME_MED)}`)
        ]) : m(LoadingFullScreen)
      ])
    }
  }
}
