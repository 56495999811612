
import { Select } from 'components/Select'
import { Dialog2 } from 'components/Dialog2'

export const DepartmentVenueDialog = ({ attrs: { meeting, venues, loading } }) => {
  loading = false
  const formData = {
    departmentVenueId: meeting.departmentVenueId ? meeting.departmentVenue.id : null,
    departmentVenueLabel: meeting.departmentVenue ? meeting.departmentVenue.venue.name : null
  }

  return {
    view ({ attrs: { meeting, venues, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          if (meeting.departmentVenue && formData.departmentVenueId) {
            meeting.departmentVenue.id = formData.departmentVenueId
            meeting.departmentVenue.venue.name = formData.departmentVenueLabel
            meeting.departmentVenueId = formData.departmentVenueId
          } else if (formData.departmentVenueId) {
            meeting.departmentVenue = { id: formData.departmentVenueId, venue: { name: formData.departmentVenueLabel } }
            meeting.departmentVenueId = formData.departmentVenueId
          }
          onsubmit()
        },
        heading: 'Set venue for meeting',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          m(Select, {
            label: 'Venue',
            value: meeting.departmentVenue ? meeting.departmentVenue.id : '',
            choices: venues.map(dv => {
              return { label: dv.venueName, value: dv.id }
            }),
            onchange (val, label) {
              formData.departmentVenueId = val
              formData.departmentVenueLabel = label
            }
          })
        ])
      ])
    }
  }
}
