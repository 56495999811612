import { DelegateCard } from '../DelegateCard'
import { VenueSelect } from '../VenueSelect'

import { addBooking } from 'api/delegate'

export const HotelSelection = ({ attrs: { booking, departmentInfo, isSubmitted } }) => {
  const isValid = flyd.stream(false)

  const validationCheck = (booking) => {
    if (!booking.firstChoice) {
      isValid(false)
      return
    }
    if (booking.firstChoice && booking.firstChoice.alt) {
      isValid(true)
      return
    }
    if (!booking.secondChoice && !departmentInfo.onlyRequireOneVenueChoice) {
      isValid(false)
      return
    }
    isValid(true)
  }

  const loading = flyd.stream(false)

  const submitBookingRequest = () => {
    loading(true)
    booking.venuePreferences = []
    booking.venuePreferences.push({ departmentVenueRateId: booking.firstChoice.rate, preference: 1 })
    if (booking.secondChoice) { booking.venuePreferences.push({ departmentVenueRateId: booking.secondChoice.rate, preference: 2 }) }
    addBooking(booking, booking.departmentId ? { departmentId: booking.departmentId } : null).then(() => {
      loading(false)
      isSubmitted(Date.now())
      m.redraw()
    }).catch(err => {
      console.log(err)
      alert(`Sorry the booking has not been submitted. An error has occurred: ${err.message}`)
      loading(false)
    })
  }

  return {
    view ({ attrs: { booking, departmentInfo, venues, currentStep, currentStepValid } }) {
      validationCheck(booking)
      if (currentStep() === 2) {
        currentStepValid(isValid())
      }
      return m(DelegateCard, {
        stepNumber: '3',
        heading: t('delegateBooking', 'hotelSelection'),
        buttonText: t('delegateBooking', 'confirmBooking'),
        currentStep,
        isValid,
        loading,
        onchange: () => {
          if (isValid()) {
            submitBookingRequest()
          }
        },
        back: () => {
          currentStep(1)
        }
      }, [
        m('fieldset' + (currentStep() !== 2 ? '[disabled]' : ''), [
          m(VenueSelect, {
            label: t('delegateBooking', 'firstChoice'),
            dialogTitle: t('delegateBooking', 'firstChoiceTitle'),
            buttonText: t('delegateBooking', 'confirmSelection'),
            changeText: t('delegateBooking', 'changeThis'),
            value: booking.firstChoice,
            onchange (val, notes) {
              booking.firstChoice = val
              booking.preferenceInfo = notes
            },
            venues,
            location: booking.location,
            other: booking.secondChoice,
            preferenceInfo: booking.preferenceInfo
          }),
          (booking.firstChoice && booking.firstChoice.alt) || departmentInfo.onlyRequireOneVenueChoice ? '' : m(VenueSelect, {
            label: t('delegateBooking', 'secondChoice'),
            dialogTitle: t('delegateBooking', 'secondChoiceTitle'),
            buttonText: t('delegateBooking', 'confirmSelection'),
            changeText: t('delegateBooking', 'changeThis'),
            disabled: !booking.firstChoice || booking.firstChoice.alt,
            value: booking.secondChoice,
            onchange (val, notes) {
              booking.secondChoice = val
              booking.preferenceInfo = notes
            },
            venues,
            location: booking.location,
            other: booking.firstChoice,
            preferenceInfo: booking.preferenceInfo
          })
        ])
      ])
    }
  }
}
