import { Breadcrumb, Table, Cell, Tabs, Pagination, BookingAlertBadge } from 'components'
import { LoadingFullScreen } from 'components/Loading/FullScreen'
import { DateTime } from 'luxon'
import { getMeetings, getMeetingStatusTotals } from 'api/meetings'
import { getAllDepartments } from 'api/clients'
import { debounce } from 'utils/debounce'
import { isClientAdmin, isDelegate } from 'store/profile'

export const MeetingsDashboard = ({ attrs: { tab } }) => {
  if (isClientAdmin()) {
    m.route.set('/client/dashboard')
  } else if (isDelegate()) {
    m.route.set('/')
  }

  const filters = { page: 1, search: null, statusId: 0, sortby: 'arrival' }
  let meetings
  let meta
  let departments
  let statusTotals
  let activeTab

  getAllDepartments({ pagesize: 100 }).then(data => {
    if (data) { departments = data.data }
  })

  getMeetingStatusTotals().then(data => {
    if (data) {
      statusTotals = data
    }
  })

  const filterDepartments = (val) => {
    filters.departmentId = val
    filters.page = 1
    getMeetings(filters).then(data => {
      meetings = data.data
      meta = data.meta
    })
  }

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    refreshData()
  }, 300)

  const refreshData = () => {
    getMeetings(filters).then(data => {
      meetings = data.data
      meta = data.meta
    })
  }

  const tabs = [{ name: 'New Requests', statusId: 0 }, { name: 'Awaiting Quote', statusId: 1 }, { name: 'Quoted', statusId: 2 }, { name: 'Provisional', statusId: 3 }, { name: 'Confirmed', statusId: 5 }, { name: 'Awaiting PO No', statusId: 7 }, { name: 'Cancelled', statusId: 15 }].map((t, ix) => {
    return {
      id: ix + 1,
      name: t.name,
      statusId: t.statusId
    }
  })

  const selectTab = (name) => {
    tabs.forEach(t => {
      t.active = false
      if (t.name === name) {
        t.active = true
        activeTab = t
      }
    })
    filters.statusId = activeTab.statusId
    refreshData()
    var pageUrl = `?tab=${activeTab ? activeTab.name : ''}`
    window.history.pushState('', '', pageUrl)
  }

  selectTab(tab || 'New Requests')

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: `${t('app', 'meetings')} ${t('app', 'dashboard')}`
          }]
        }),
        m('.mt-4.px-4.sm:px-6.lg:px-8',
          [
            // Summary
            m('.mt-2.grid.grid-cols-1.gap-5.sm:grid-cols-2.lg:grid-cols-4',
              // New Request
              m('div.bg-white.overflow-hidden.shadow.rounded-lg.flex.flex-col.justify-between',
                [
                  m('div.p-5',
                    m('div.flex-col',
                      [
                        m('dl',
                          [
                            m('dt.text-sm.font-medium.text-gray-500.truncate',
                              'New Requests '
                            ),
                            m('dd.grid.grid-cols-2.text-gray-900',
                              m('div.text-lg.font-medium',
                                'Total'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.NewRequest.total : 0
                              )
                            ),
                            m('dd.grid.grid-cols-2',
                              { class: statusTotals && statusTotals.NewRequest.expired > 0 ? 'text-red-300' : 'text-gray-900' },
                              m('div.text-lg.font-medium',
                                'Expired'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.NewRequest.expired : 0
                              )
                            ),
                            m('dd.grid.grid-cols-2',
                              { class: statusTotals && statusTotals.NewRequest.urgent > 0 ? 'text-orange-600' : 'text-gray-900' },
                              m('div.text-lg.font-medium',
                                'Urgent'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.NewRequest.urgent : 0
                              )
                            )
                            // m('dd.grid.grid-cols-2',
                            //   { class: statusTotals && statusTotals.NewRequest.alerts > 0 ? 'text-red-600' : 'text-gray-900' },
                            //   m('div.text-lg.font-medium',
                            //     'Alerts'
                            //   ),
                            //   m('div.text-lg.font-medium',
                            //     statusTotals ? statusTotals.NewRequest.alerts : 0
                            //   )
                            // )
                          ]
                        )
                      ]
                    )
                  ),
                  m('div.bg-gray-50.px-5.py-3',
                    m('div.text-sm',
                      m("a.font-medium.text-cyan-700.hover:text-cyan-900[href='#']",
                        {
                          onclick: () => {
                            selectTab('New Requests')
                          }
                        },
                        ' View all '
                      )
                    )
                  )

                ]
              ),
              // Awaiting Quote
              m('div.bg-white.overflow-hidden.shadow.rounded-lg.flex.flex-col.justify-between',
                [
                  m('div.p-5',
                    m('div.flex-col',
                      [
                        m('dl',
                          [
                            m('dt.text-sm.font-medium.text-gray-500.truncate',
                              'Awaiting Quote '
                            ),
                            m('dd.grid.grid-cols-2.text-gray-900',
                              m('div.text-lg.font-medium',
                                'Total'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.AwaitingQuote.total : 0
                              )
                            ),
                            m('dd.grid.grid-cols-2',
                              { class: statusTotals && statusTotals.AwaitingQuote.expired > 0 ? 'text-red-300' : 'text-gray-900' },
                              m('div.text-lg.font-medium',
                                'Expired'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.AwaitingQuote.expired : 0
                              )
                            ),
                            m('dd.grid.grid-cols-2',
                              { class: statusTotals && statusTotals.AwaitingQuote.urgent > 0 ? 'text-orange-600' : 'text-gray-900' },
                              m('div.text-lg.font-medium',
                                'Urgent'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.AwaitingQuote.urgent : 0
                              )
                            )
                            // m('dd.grid.grid-cols-2',
                            //   { class: statusTotals && statusTotals.AwaitingQuote.alerts > 0 ? 'text-red-600' : 'text-gray-900' },
                            //   m('div.text-lg.font-medium',
                            //     'Alerts'
                            //   ),
                            //   m('div.text-lg.font-medium',
                            //     statusTotals ? statusTotals.AwaitingQuote.alerts : 0
                            //   )
                            // )
                          ]
                        )
                      ]
                    )
                  ),
                  m('div.bg-gray-50.px-5.py-3',
                    m('div.text-sm',
                      m("a.font-medium.text-cyan-700.hover:text-cyan-900[href='#']",
                        {
                          onclick: () => {
                            selectTab('Awaiting Quote')
                          }
                        },
                        ' View all '
                      )
                    )
                  )

                ]
              ),
              // Provisional
              m('div.bg-white.overflow-hidden.shadow.rounded-lg',
                [
                  m('div.p-5',
                    m('div.flex-col',
                      [
                        m('dl',
                          [
                            m('dt.text-sm.font-medium.text-gray-500.truncate',
                              'Provisional '
                            ),
                            m('dd.grid.grid-cols-2.text-gray-900',
                              m('div.text-lg.font-medium',
                                'Total'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.Provisional.total : 0
                              )
                            ),
                            m('dd.grid.grid-cols-2',
                              { class: statusTotals && statusTotals.Provisional.expired > 0 ? 'text-red-300' : 'text-gray-900' },
                              m('div.text-lg.font-medium',
                                'Expired'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.Provisional.expired : 0
                              )
                            ),
                            m('dd.grid.grid-cols-2',
                              { class: statusTotals && statusTotals.Provisional.urgent > 0 ? 'text-orange-600' : 'text-gray-900' },
                              m('div.text-lg.font-medium',
                                'Urgent'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.Provisional.urgent : 0
                              )
                            ),
                            m('dd.grid.grid-cols-2',
                              { class: statusTotals && statusTotals.Provisional.alerts > 0 ? 'text-red-600' : 'text-gray-900' },
                              m('div.text-lg.font-medium',
                                'Alerts'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.Provisional.alerts : 0
                              )
                            )
                          ]
                        )
                      ]
                    )
                  ),
                  m('div.bg-gray-50.px-5.py-3',
                    m('div.text-sm',
                      m("a.font-medium.text-cyan-700.hover:text-cyan-900[href='#']",
                        {
                          onclick: () => {
                            selectTab('Provisional')
                          }
                        },
                        ' View all '
                      )
                    )
                  )
                ]
              ),
              // Awaiting PO
              m('div.bg-white.overflow-hidden.shadow.rounded-lg.flex.flex-col.justify-between',
                [
                  m('div.p-5',
                    m('div',
                      [
                        m('dl',
                          [
                            m('dt.text-sm.font-medium.text-gray-500.truncate',
                              'Awaiting PO No'
                            ),
                            m('dd.grid.grid-cols-2.text-gray-900',
                              m('div.text-lg.font-medium',
                                'Total'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.AwaitingPONumber.total : 0
                              )
                            ),
                            m('dd.grid.grid-cols-2',
                              { class: statusTotals && statusTotals.AwaitingPONumber.alerts > 0 ? 'text-red-600' : 'text-gray-900' },
                              m('div.text-lg.font-medium',
                                'Urgent'
                              ),
                              m('div.text-lg.font-medium',
                                statusTotals ? statusTotals.AwaitingPONumber.urgent : 0
                              )
                            )
                          ]
                        )
                      ]
                    )
                  ),
                  m('div.bg-gray-50.px-5.py-3',
                    m('div.text-sm',
                      m("a.font-medium.text-cyan-700.hover:text-cyan-900[href='#']",
                        {
                          onclick: () => {
                            selectTab('Awaiting PO No')
                          }
                        },
                        ' View all '
                      )
                    )
                  )
                ]
              )
            ),
            // Tabs
            m('.mt-3', [
              m(Tabs, {
                tabs: tabs,
                onTabChange: (tabId) => {
                  tabs.forEach(t => {
                    if (t.id === tabId) {
                      selectTab(t.name)
                    }
                  })
                }
              }),
              m('', { style: { maxHeight: 'calc(100vh - 18rem)' } }, [
                m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
                  meetings && departments ? m(Table, {
                    cols: [
                      {
                        label: 'Description',
                        search: true,
                        searchPlaceholder: 'Search description, contact name or email...',
                        filterClasses: 'flex-2',
                        searchOnChange: (val) => {
                          debouncedSearch(val)
                        }
                      },
                      {
                        label: 'Contact Name',
                        // classes: ['w-96'],
                        filterClasses: 'hidden'
                      },
                      {
                        label: 'Email',
                        // classes: ['w-96'],
                        filterClasses: 'hidden'
                      },
                      {
                        label: 'Department',
                        classes: ['w-48'],
                        filter: true,
                        filterOptions: departments,
                        filterEmptyOption: true,
                        filterOnChange: (val) => {
                          filterDepartments(val)
                        }
                      },
                      {
                        label: 'Venue',
                        classes: ['w-48']
                        // filter: true,
                        // filterOptions: venues,
                        // filterEmptyOption: true,
                        // filterOnChange: (val) => {
                        //   filterDepartments(val)
                        // }
                      },
                      {
                        label: 'Arrival',
                        classes: ['w-32 justify-center']
                      },
                      {
                        label: 'Days',
                        classes: ['w-12 justify-center']
                      },
                      {
                        label: 'Actioned',
                        classes: ['w-40 justify-center']
                      },
                      {
                        label: '',
                        search: false,
                        classes: ['w-12 justify-center']
                      }
                    ],
                    filters: true
                  },
                  [
                    m('.overflow-auto.flex-grow',
                      meetings.length ? meetings.map((v, ix) => {
                        return m(MeetingListItem, { item: v, key: v.id })
                      }) : m('p.text-center.p-3.text-gray-400', 'No results')
                    ),
                    meta && m(Pagination, {
                      meta: meta,
                      onpage: (page) => {
                        filters.page = page
                        getMeetings(filters).then(data => {
                          meetings = data.data
                          meta = data.meta
                        })
                      }
                    })
                  ]) : m(LoadingFullScreen)
                )
              ])
            ])
          ]
        )
      ]
    }
  }
}

const MeetingListItem = ({ attrs: { item, classes = [], clientId, departmentId } }) => {
  const newFormat = { ...DateTime.DATETIME_SHORT, year: '2-digit' }
  return {
    view () {
      const dateDiff = (DateTime.fromISO(item.arrivalDate)).diff(DateTime.now().startOf('day'), 'days')
      const rowColor = dateDiff.as('days') < 0 ? 'bg-red-200' : dateDiff.as('days') <= 5 ? 'bg-orange-200' : 'bg-white'
      return m(m.route.Link, {
        class: ['flex', 'flex-shrink-0', 'hover:bg-gray-300', 'cursor-pointer', rowColor, ...classes].join(' '),
        'data-days': dateDiff.as('days'),
        style: {
          height: '40px'
        },
        href: `/clients/${item.clientId}/departments/${item.departmentId}/meetings/${item.id}`
      }, [
        m(Cell,
          item.description
        ),
        m(Cell, { cellClasses: ['tracking-tight'] },
          item.name
        ),
        m(Cell, { cellClasses: ['tracking-tight'] },
          item.email
        ),
        m(Cell, { width: 'w-48' },
          item.departmentName
        ),
        m(Cell, { width: 'w-48', cellClasses: ['tracking-tight'] },
          item.venueName || 'No Preference'
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          DateTime.fromISO(item.arrivalDate).toLocaleString()
        ),
        m(Cell, { width: 'w-12', align: 'center', padding: 'py-3', noEllipsis: 'true' },
          item.days
        ),
        m(Cell, { width: 'w-40', align: 'center', padding: 'px-2 py-3' },
          DateTime.fromISO(item.lastActionDate).toLocaleString(newFormat)
        ),
        m(Cell, { width: 'w-12', align: 'center', padding: 'px-3 py-2', noEllipsis: true },
          m(BookingAlertBadge, {
            alert: item.alertMessage, size: 'xs', margin: 'm-0'
          }
          )
        )
      ])
    }
  }
}
