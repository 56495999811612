import { Heading } from 'components/Heading'
import { Button } from 'components/Button'

export const DescriptionList = () => {
  return {
    view ({ attrs: { title, description, actions, items } }) {
      return m('.h-full.flex.flex-col', [
        title && m('.border-b.sm:px-6.flex.justify-between.items-center.flex-shrink-0', [
          m('.flex.flex-col.justify-center', [
            m(Heading, { level: 3 }, title),
            description && m('p.mt-1.max-w-2xl.text-sm.leading-5.text-gray-500', description)
          ]),
          actions && m('div', [
            actions.map(a => {
              return m(Button, {
                onclick: a.action
              }, a.label)
            })
          ])
        ]),
        items && m('.h-100.overflow-y-auto', [
          m('dl', [
            items.map(item => {
              return item.type === 'title'
                ? m(DescriptionListTitle, {
                  key: item.label,
                  label: item.label,
                  actions: item.actions
                })
                : m(DescriptionListItem, {
                  key: item.label,
                  label: item.label
                }, item.value)
            })
          ])
        ])
      ])
    }
  }
}

const DescriptionListTitle = ({ attrs: { label, actions } }) => {
  return {
    view () {
      return m('.w-full.bg-blue-300.px-4.sm:px-6.py-1.text-white.text-xs.flex.justify-between', [
        m('span', label),
        m('.flex', [
          actions.map(a => {
            return m('.cursor-pointer.mx-1', {
              onclick: a.action
            }, a.label)
          })
        ])
      ])
    }
  }
}

const DescriptionListItem = ({ attrs: { label }, children }) => {
  return {
    view () {
      return m('.px-4.py-5.sm:grid.sm:grid-cols-4.sm:gap-4.sm:px-6.border-b', [
        m('dd.text-sm.leading-5.font-medium.text-gray-500', label),
        m('dt.mt-1.text-sm.leading-5.text-gray-900.sm:mt-0.sm:col-span-3', [
          (!children[0].children || !children[0].children.flat().filter(c => { return c }).length) && !children[0].text
            ? m('span.text-gray-300', 'Not entered')
            : children
        ])
      ])
    }
  }
}
