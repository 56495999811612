import { DelegateCard } from '../DelegateCard'
import { DelegateInput } from '../DelegateInput'
import { DelegateTextArea } from '../DelegateTextArea'
import { DelegateSelect } from '../DelegateSelect'
import { DelegateCheckbox } from '../DelegateCheckbox'
import { DateTime } from 'luxon'

function onlyUnique (value, index, self) {
  return self.findIndex(item => (value ? value.toLowerCase() : '') === (item ? item.toLowerCase() : '')) === index
}

export const BookingDetails = ({ attrs: { booking, venues, departmentInfo } }) => {
  const distinctTowns = venues.map(l => {
    return l.townCity
  }).filter(onlyUnique)

  const locations = distinctTowns.map(l => {
    return { label: l, value: l }
  })

  return {
    view ({ attrs: { booking, departmentInfo, currentStep, currentStepValid, isSubmitted } }) {
      if (currentStep() === 1) {
        currentStepValid(true)
      }
      return m(DelegateCard, {
        stepNumber: '2',
        heading: t('delegateBooking', 'bookingDetails'),
        buttonText: t('delegateBooking', 'next'),
        currentStep,
        onchange: () => {
          currentStep(2)
        },
        back: () => {
          currentStep(0)
        }
      }, [
        m('fieldset' + (currentStep() !== 1 ? '[disabled]' : ''), [
          locations && m('div', [
            m(DelegateSelect, {
              label: t('delegateBooking', 'location'),
              required: true,
              value: booking.location,
              position: 'bottom',
              choices: locations,
              shouldSort: true,
              strValue: true,
              onchange: (val) => {
                booking.location = val
              }
            })
          ]),
          m(DelegateInput, {
            label: t('delegateBooking', 'dateOfStay'),
            type: 'date',
            labelClasses: ['w-32'],
            required: true,
            value: booking.arrivalDate,
            min: DateTime.local().toISODate(),
            oninput: (val) => {
              if (DateTime.fromISO(val) >= DateTime.local().startOf('day')) {
                booking.arrivalDate = val
              }
            }
          }),
          m(DelegateInput, {
            label: t('delegateBooking', 'numberOfNights'),
            type: 'number',
            labelClasses: ['w-32'],
            required: true,
            value: booking.nights,
            min: 1,
            oninput: (val) => {
              booking.nights = val
            }
          }),
          m(DelegateTextArea, {
            label: t('delegateBooking', 'additionalInfo'),
            placeholder: 'Notes',
            value: booking.additionalInfo,
            oninput: (val) => {
              booking.additionalInfo = val
            }
          }),
          departmentInfo && departmentInfo.departmentBookingFields && m('.grid.gap-3', [
            departmentInfo.departmentBookingFields.map(bf => {
              return m('div', [
                bf.type === 0 ? m(DelegateInput, {
                  label: bf.label,
                  classes: ['flex-col'],
                  labelClasses: ['w-full'],
                  required: bf.isMandatory,
                  value: booking.bookingFields && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id) && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id).value,
                  oninput (value) {
                    if (!booking.bookingFields) {
                      booking.bookingFields = []
                    }
                    let found = false
                    for (let index = 0; index < booking.bookingFields.length; index++) {
                      const element = booking.bookingFields[index]
                      if (element.departmentBookingFieldId === bf.id) {
                        element.value = value
                        found = true
                      }
                    }
                    if (!found) {
                      booking.bookingFields.push({ departmentBookingFieldId: bf.id, value: value })
                    }
                  }
                }) : bf.type === 1 ? m(DelegateCheckbox, {
                  label: bf.label,
                  checked: booking.bookingFields && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id) && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id).value,
                  required: bf.isMandatory,
                  onchange (value) {
                    if (!booking.bookingFields) {
                      booking.bookingFields = []
                    }
                    let found = false
                    for (let index = 0; index < booking.bookingFields.length; index++) {
                      const element = booking.bookingFields[index]
                      if (element.departmentBookingFieldId === bf.id) {
                        element.value = !element.value
                        found = true
                      }
                    }
                    if (!found) {
                      booking.bookingFields.push({ departmentBookingFieldId: bf.id, value: !value })
                    }
                  }
                }) : m('.delegate-input.border-t.w-full', [
                  m('.flex.items-center.py-3.px-1', {
                  }, [
                    m('label.block.text-sm.ml-5.mr-2.flex-shrink-0', {
                      class: 'w-1/4'
                    }, [bf.label, bf.isMandatory && m('span.text-cyan.ml-1', '*')]),
                    m('select.flex-grow', {
                      required: bf.isMandatory,
                      onchange (e) {
                        const value = e.target.value
                        if (!booking.bookingFields) {
                          booking.bookingFields = []
                        }
                        let found = false
                        for (let index = 0; index < booking.bookingFields.length; index++) {
                          if (booking.bookingFields[index].departmentBookingFieldId === bf.id) {
                            booking.bookingFields[index].value = value
                            found = true
                          }
                        }
                        if (!found) {
                          booking.bookingFields.push({ departmentBookingFieldId: bf.id, value: value })
                        }
                      }
                    }, [
                      m('option', { value: '' }, 'Please select...'),
                      bf.lookupOptions.split('|').map(lo => {
                        return m('option', { value: lo.trim(), selected: (lo.trim() === booking.bookingFields && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id) && booking.bookingFields.find(z => z.departmentBookingFieldId === bf.id).value) ? true : null }, lo.trim())
                      })
                    ])
                  ])
                ])
                // m(DelegateSelect, {
                //   label: bf.label,
                //   search: false,
                //   strValue: true,
                //   postion: 'auto',
                //   required: bf.isMandatory,
                //   choices: bf.lookupOptions.split('|').map(lo => { return { label: lo.trim(), value: lo.trim() } }),
                //   onchange (value) {
                //     if (!booking.bookingFields) {
                //       booking.bookingFields = []
                //     }
                //     let found = false
                //     for (let index = 0; index < booking.bookingFields.length; index++) {
                //       const element = booking.bookingFields[index]
                //       if (element.departmentBookingFieldId === bf.id) {
                //         element.value = value
                //         found = true
                //       }
                //     }
                //     if (!found) {
                //       booking.bookingFields.push({ departmentBookingFieldId: bf.id, value: value })
                //     }
                //   }
                // })
              ])
            })
          ])
          // m(DelegateInput, {
          //   label: t('delegateBooking', 'authorisation'),
          //   required: true,
          //   placeholder: '346-456-718',
          //   tooltip: 'this is an example of a tooltip',
          //   value: booking.authorisation,
          //   oninput: (val) => {
          //     booking.authorisation = val
          //   }
          // })
        ])

      ])
    }
  }
}
