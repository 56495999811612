
import { Dialog2, Select, TinyMce } from 'components'
import { getBookingActivityTypes } from 'api/bookings'

export const NoteDialog = ({ attrs: { loading } }) => {
  loading = false
  const formData = {
    note: '',
    type: 5
  }

  let types

  getBookingActivityTypes().then(data => {
    types = data.map(d => {
      return { label: d.name, value: d.id, selected: d.id === 5 }
    })
  })

  return {
    view ({ attrs: { loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          onsubmit(formData)
        },
        classes: ['lg:max-w-3xl', 'xl:max-w-4xl'],
        heading: 'Add note',
        saving: loading,
        saveLabel: 'Add'
      }, [
        types && m('.flex.flex-col.gap-3', [
          m('div', [
            m(Select, {
              label: 'Type',
              value: formData.type,
              choices: types,
              search: true,
              required: false,
              onchange (val, label) {
                formData.type = val
              }
            })
          ]),
          m('.flex.flex-col', [
            m(TinyMce, {
              value: formData.note,
              label: 'Body',
              height: 250,
              toolbar: 'bold italic',
              onchange: (content) => {
                formData.note = content
              }
            })
          ])
        ])
      ])
    }
  }
}
