export const DelegateTextArea = ({ attrs: { autofocus } }) => {
  let isFocused = false

  return {
    oncreate ({ dom }) {
      if (autofocus) {
        dom.querySelector('input').focus()
      }
    },
    view ({ attrs }) {
      return m('.delegate-input.shadow-lg.border-t.w-full.py-3.px-1' + (isFocused ? '.active' : ''), {
        class: attrs.classes && [...attrs.classes],
        onclick (e) {
          this.querySelector('textarea').focus()
        }
      }, [
        m('label.flex.w-full.text-sm.ml-5.mr-2.flex-shrink-0.items-center', [attrs.label, attrs.required && m('span.text-cyan.ml-1', '*')]),
        m('textarea.h-24.bg-transparent.focus:outline-none.px-2.py-1.w-full', {
          value: attrs.value,
          max: attrs.max,
          rows: attrs.rows,
          placeholder: attrs.placeholder,
          class: attrs.errorMessage ? 'border-red-600' : '',
          onfocus () {
            isFocused = true
          },
          onblur () {
            isFocused = false
          },
          oninput (e) {
            attrs.oninput(e.target.value)
          }
        }),
        m('.text-red-600.text-sm', attrs.errorMessage)
      ])
    }
  }
}
