
const Radio = () => {
  return {
    view ({ attrs: { ix, label, price, value, checked, onchange } }) {
      return m('label.relative.flex.cursor-pointer.rounded-sm.border.bg-white.p-4.shadow-sm.focus:outline-none', {
        class: checked ? 'border-teal-500 ring-2 ring-teal-500' : 'hover:border-teal-400 hover:ring-1 hover:ring-teal-400'
      },
      [
        m('input.sr-only', {
          value,
          checked,
          type: 'radio',
          name: 'delegate-accomm-rate',
          'aria-labelledby': `option-delegate-accomm-rate${ix}`,
          onchange (e) {
            onchange(value)
          }
        }),
        m('span.flex.flex-1',
          m('span.flex.flex-col',
            [
              m('span.block.text-sm.font-medium.text-gray-900', {
                id: `option-delegate-accomm-rate${ix}`
              }, label
              ),
              m('span.mt-3.text-sm.font-medium.text-gray-900',
                price
              )
            ]
          )
        ),
        m("svg.h-5.w-5.text-teal-600[xmlns='http://www.w3.org/2000/svg'][viewBox='0 0 20 20'][fill='currentColor'][aria-hidden='true']", {
          class: checked ? '' : 'invisible'
        },
        m("path[fill-rule='evenodd'][d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z'][clip-rule='evenodd']")
        ),
        m("span.pointer-events-none.absolute.-inset-px.rounded-sm.border-2[aria-hidden='true']")
      ]
      )

      // m('.radio.d-radio.py-1.md:py-2.cursor-pointer', [
      //   m('input', {
      //     type: 'radio',
      //     value,
      //     checked,
      //     onclick (e) {
      //       onchange(value)
      //     }
      //   }),
      //   m('label.radio-label.cursor-pointer.text-sm', {
      //     onclick (e) {
      //       onchange(value)
      //     }
      //   }, label)
      // ])
    }
  }
}

export const DelegateRadioGroup = () => {
  return {
    view ({ attrs: { value, options, errorMessage, onchange } }) {
      return m('.grow', [
        m('.mt-3.grid.grid-cols-1.gap-y-6', [
          options.map((o, ix) => {
            return m(Radio, {
              ix,
              label: o.label,
              price: o.price,
              value: o.value,
              checked: o.value === value,
              onchange
            })
          })
        ]),
        m('.text-red-600.text-sm', errorMessage)
      ])
    }
  }
}
