import { request } from './request'

export const getMeetings = params => {
  return request({
    url: 'meetings',
    params: {
      pagesize: 25,
      ...params
    },
    method: 'GET'
  })
}

export const getMeeting = (meetingId) => {
  return request({
    url: `meetings/${meetingId}`,
    method: 'GET'
  })
}

export const getMeetingActivity = (meetingId, params) => {
  return request({
    url: `meetings/${meetingId}/activity`,
    method: 'GET',
    params: {
      pagesize: 100,
      isModelUpdate: false,
      ...params
    }
  })
}

export const addMeeting = (body) => {
  return request({
    url: 'meetings',
    method: 'POST',
    body
  })
}

export const copyMeeting = (meetingId) => {
  return request({
    url: `meetings/copy/${meetingId}`,
    method: 'POST'
  })
}

export const updateMeeting = (meetingId, body) => {
  return request({
    url: `meetings/${meetingId}`,
    method: 'PUT',
    body
  })
}

export const addMeetingRate = (meetingId, body) => {
  return request({
    url: `meetings/${meetingId}/rate`,
    method: 'POST',
    body
  })
}
export const updateMeetingRate = (meetingId, rateId, body) => {
  return request({
    url: `meetings/${meetingId}/rate/${rateId}`,
    method: 'PUT',
    body
  })
}

export const deleteMeetingRate = (meetingId, rateId) => {
  return request({
    url: `meetings/${meetingId}/rate/${rateId}`,
    method: 'DELETE'
  })
}

export const setMeetingVenue = (meetingId, departmentVenueId) => {
  return request({
    url: `meetings/${meetingId}/select-venue/${departmentVenueId}`,
    method: 'PUT'
  })
}

export const getMeetingEmailTemplates = (meetingId) => {
  return request({
    url: `meetings/${meetingId}/emailtemplates`,
    method: 'GET'
  })
}

export const getDefaultMeetingEmail = (meetingId, templateType) => {
  return request({
    url: `meetings/${meetingId}/defaultemail/${templateType}`,
    method: 'GET'
  })
}

export const getMeetingEmail = (meetingId, templateId) => {
  return request({
    url: `meetings/${meetingId}/email/${templateId}`,
    method: 'GET'
  })
}

export const sendMeetingEmail = (meetingId, body) => {
  return request({
    url: `meetings/${meetingId}/email`,
    method: 'POST',
    body
  })
}

export const addMeetingNote = (meetingId, body) => {
  return request({
    url: `meetings/${meetingId}/note`,
    method: 'POST',
    body
  })
}

export const getMeetingStatuses = () => {
  return request({
    url: 'meetings/statuses',
    method: 'GET'
  })
}

export const getMeetingStatusTotals = () => {
  return request({
    url: 'meetings/statustotals',
    method: 'GET'
  })
}

export const updateMeetingStatus = (meetingId, body) => {
  return request({
    url: `meetings/${meetingId}/status`,
    method: 'PUT',
    body
  })
}

export const getMeetingActivityTypes = (params) => {
  return request({
    url: 'meetings/activitytypes',
    method: 'GET',
    params
  })
}

export const getMeetingContacts = (meetingId) => {
  return request({
    url: `meetings/${meetingId}/contacts`,
    method: 'GET'
  })
}

export const importMeetings = (departmentId, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return request({
    method: 'POST',
    url: `meetings/import/${departmentId}`,
    body: formData
  }, {
    contentType: false
  })
}
