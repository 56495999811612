import { Select, Checkbox, TinyMce } from 'components'
import { Dialog2 } from 'components/Dialog2'
import { getDepartmentBillingInstructions, getDepartmentBillingInstruction } from 'api/clients'

export const EditBillingInstructionsDialog = ({ attrs: { booking, loading } }) => {
  let guestBillingEditor
  let venueBillingEditor
  let billingTemplates = []
  loading = false
  const formData = {
    isPONumberRequired: booking.isPONumberRequired,
    guestBillingInstructions: booking.guestBillingInstructions,
    venueBillingInstructions: booking.venueBillingInstructions
  }

  getDepartmentBillingInstructions(booking.departmentId)
    .then(data => {
      billingTemplates = data
    })

  const fetchDepartmentBillingInstruction = (billingInstructionId) => {
    getDepartmentBillingInstruction(booking.departmentId, billingInstructionId)
      .then(data => {
        guestBillingEditor.setContent(data.guestInstructions)
        venueBillingEditor.setContent(data.venueInstructions)
        formData.isPONumberRequired = data.isPONumberRequired
        formData.guestBillingInstructions = data.guestInstructions
        formData.venueBillingInstructions = data.venueInstructions
      })
  }

  return {
    view ({ attrs: { booking, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          booking.isPONumberRequired = formData.isPONumberRequired
          booking.guestBillingInstructions = formData.guestBillingInstructions
          booking.venueBillingInstructions = formData.venueBillingInstructions
          onsubmit()
        },
        heading: 'Edit Billing Instructions',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          billingTemplates && billingTemplates.length ? m(Select, {
            label: 'Select Template',
            value: '',
            choices: billingTemplates.map(template => {
              return { label: template.description, value: template.id }
            }),
            onchange (val, label) {
              if (val) {
                fetchDepartmentBillingInstruction(val)
              }
            }
          }) : null,
          m('.col-span-6.flex.flex-col', [
            m(TinyMce, {
              value: formData.guestBillingInstructions,
              label: 'Guest Billing Instructions',
              height: 200,
              onchange: (content) => {
                formData.guestBillingInstructions = content
              },
              editor: (instance) => {
                guestBillingEditor = instance
              }
            })
          ]),
          m('.col-span-6.flex.flex-col', [
            m(TinyMce, {
              value: formData.venueBillingInstructions,
              label: 'Venue Billing Instructions',
              height: 200,
              onchange: (content) => {
                formData.venueBillingInstructions = content
              },
              editor: (instance) => {
                venueBillingEditor = instance
              }
            })
          ]),
          m('div.col-span-6',
            [
              m(Checkbox, {
                label: 'PO Number Required?',
                checked: formData.isPONumberRequired,
                onchange () {
                  formData.isPONumberRequired = !formData.isPONumberRequired
                }
              })
            ]
          )
        ])
      ])
    }
  }
}
