import { NavbarUser } from './components/Navbar/NavbarUser'
import { Svg } from 'components'
import homeIcon from 'assets/icons/outline/home.svg'
import usersIcon from 'assets/icons/outline/users.svg'
import clientsIcon from 'assets/icons/outline/briefcase.svg'
import deptsIcon from 'assets/icons/outline/collection.svg'
import ratesIcon from 'assets/icons/outline/currency-pound.svg'
import invoicesIcon from 'assets/icons/outline/receipt-tax.svg'
import venuesIcon from 'assets/icons/outline/office-building.svg'
import venueGroupsIcon from 'assets/icons/outline/library.svg'
import emailsIcon from 'assets/icons/outline/mail.svg'
import xIcon from 'assets/icons/solid/x.svg'
import menuIcon from 'assets/icons/solid/menu-alt-1.svg'
import bookingIcon from 'assets/icons/outline/calendar.svg'
import searchIcon from 'assets/icons/outline/search-circle.svg'
import meetingsIcon from 'assets/icons/outline/identification.svg'

import logo from 'assets/images/tobook-logo.png'

import { DisplayNotifications } from 'utils/notifications'
import { hasStoredToken } from 'store/auth'
import { getVersion } from 'api/system'

import { profile, isAdmin, isClientAdmin } from 'store/profile'

const isDelegateUser = flyd.stream(false)

// async function checkIsDelegate () {
//   const isUserDelegate = await isDelegate()
//   if (isUserDelegate) {
//     isDelegateUser(true)
//     throw Error('delegate')
//   } else {
//     return false
//   }
// }

const NavLinks = ({ attrs: { version, profile } }) => {
  return {
    view () {
      return [m('div.flex-shrink-0.flex.items-center.px-4',
        m('img.h-8.w-auto.t-0', {
          src: logo,
          alt: 'tobook logo'
        })
      ),
      m("nav.mt-5.flex-shrink-0.divide-y.divide-orange-500.overflow-y-auto[aria-label='Sidebar']",
        [
          isAdmin() && m('div.px-2.space-y-1',
            [
              m(NavLink, { href: '/', label: t('app', 'accommodation'), icon: homeIcon }),
              m(NavLink, { href: '/meetings', label: t('app', 'meetings'), icon: meetingsIcon }),
              m(NavLink, { href: '/searchaccommodation', label: `Search ${t('app', 'accommodation')}`, icon: searchIcon }),
              m(NavLink, { href: '/searchmeetings', label: `Search ${t('app', 'meetings')}`, icon: searchIcon }),
              m(NavLink, { href: '/clients', label: 'Clients', icon: clientsIcon }),
              m(NavLink, { href: '/departments', label: 'Departments', icon: deptsIcon }),
              m(NavLink, { href: '/venues', label: 'Venues', icon: venuesIcon }),
              m(NavLink, { href: '/invoices', label: 'Invoices', icon: invoicesIcon }),
              m(NavLink, { href: '/emails', label: 'Emails', icon: emailsIcon })
            ]
          ),
          isClientAdmin() && m('div.px-2.space-y-1',
            [
              m(NavLink, { href: '/client/dashboard', label: 'Dashboard', icon: homeIcon }),
              m(NavLink, { href: '/client/search', label: 'Search', icon: searchIcon })
            ]),
          m('div.mt-6.pt-6',
            m('div.px-2.space-y-1',
              [
                m(NavLink, { href: '/booking', label: 'Delegate Booking', icon: bookingIcon })
              ]
            )
          ),
          isAdmin() && m('div.mt-6.pt-6',
            m('div.px-2.space-y-1',
              [
                m(NavLink, { href: '/users', label: 'Admin Users', icon: usersIcon }),
                m(NavLink, { href: '/rate-types', label: 'Rate Types', icon: ratesIcon }),
                m(NavLink, { href: '/venue-groups', label: 'Venue Groups', icon: venueGroupsIcon }),
                m(NavLink, { href: '/email-templates', label: 'Email Templates', icon: emailsIcon })
              ]
            )
          ),
          m('div.mt-6.pt-6',
            m('div.px-2.space-y-1', [
              m('p.text-xs.text-orange-400', `App: v${process.env.APP_VERSION}`),
              m('p.text-xs.text-orange-400', `Api: v${version}`)

            ]
            )
          )
        ]
      )]
    }
  }
}
const NavLink = () => {
  return {
    view ({ attrs: { label, href, icon } }) {
      return m(m.route.Link, {
        class: `group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md ${(href === m.route.get() || (href.length > 1 && m.route.get().indexOf(href) === 0)) ? 'text-white bg-orange-500' : 'text-orange-100 hover:text-white hover:bg-orange-500'}`,
        href
      }, [
        icon && m(Svg, { classes: ['w-6', 'h-6', 'mr-4', 'text-orange-100'] }, icon),
        label])
    }
  }
}

export const Layout = () => {
  let isSideNavOpen = false
  let isOnline = navigator.onLine

  window.ononline = () => {
    isOnline = true
    m.redraw()
  }

  window.onoffline = () => {
    isOnline = false
    m.redraw()
  }

  let version
  getVersion().then(s => {
    version = s
  })
  // if (!isAdmin()) {
  //   m.route.set('/client/search')
  // }

  return {
    view ({ children, attrs: { hideNav, allowDelegate } }) {
      if (hasStoredToken() && profile() && !allowDelegate && profile().roles[0] === 'user') {
        m.route.set('/booking')
      }
      return m('div.h-screen.flex.overflow-hidden.bg-gray-100', [
        !hideNav && m('div.lg:hidden', {
          class: isSideNavOpen ? '' : 'hidden'
        },
        m('div.fixed.inset-0.flex.z-40',
          [
            m('div.fixed.inset-0',
              m(".absolute.inset-0.bg-gray-600.opacity-75[aria-hidden='true']")
            ),
            m('div.relative.flex-1.flex.flex-col.max-w-xs.w-full.pt-5.pb-4.bg-orange-600',
              [
                m('div.absolute.top-0.right-0.-mr-12.pt-2',
                  m('button.ml-1.flex.items-center.justify-center.h-10.w-10.rounded-full.focus:outline-none.focus:ring-2.focus:ring-inset.focus:ring-white',
                    {
                      type: 'button',
                      onclick: () => {
                        isSideNavOpen = false
                      }
                    },
                    [
                      m('span.sr-only',
                        'Close sidebar'
                      ),
                      m(Svg, { classes: ['w-6', 'h-6'] }, xIcon)
                    ]
                  )
                ),
                version && m(NavLinks, { version: version })
              ]
            ),
            m(".flex-shrink-0.w-14[aria-hidden='true']")
          ]
        )
        ),
        !hideNav && m('div.hidden.lg:flex.lg:flex-shrink-0',
          m('div.flex.flex-col.w-56.xl:w-64',
            m('div.flex.flex-col.flex-grow.bg-orange-600.pt-5.pb-4.overflow-y-auto',
              [
                version && m(NavLinks, { version: version })
              ]
            )
          )
        ),

        m(DisplayNotifications),
        m('.flex-1.overflow-auto.focus:outline-none[tabindex="0"]', [
          !hideNav && m('.relative.z-10.flex-shrink-0.flex.h-16.bg-orange-400.border-b.border-gray-200', [
            m('button.px-4.border-r.border-orange-300.text-gray-800.focus:outline-none.focus:ring-2.focus:ring-inset.focus:ring-orange-500.lg:hidden',
              {
                type: 'button',
                onclick: () => {
                  isSideNavOpen = true
                }
              },
              [
                m('span.sr-only',
                  'Open sidebar'
                ),
                m(Svg, { classes: ['w-6', 'h-6'] }, menuIcon)
              ]
            ),
            m('.flex-1.px-4.flex.justify-between.sm:px-6.lg:mx-auto.lg:px-8',
              [
                m('div.flex-1.flex.items-center',
                  // m("form.w-full.flex.md:ml-0[action='#'][method='GET']",
                  //   []
                  // )
                  process.env.APP_ALERT && [
                    m('span.mr-3', process.env.APP_ALERT),
                    m('a.text-xs', { href: process.env.API_URL, target: '_blank' }, `API: ${process.env.API_URL}`)
                  ]
                ),
                m('div.ml-4.flex.items-center.md:ml-6',
                  [
                    hasStoredToken() && profile() && m('div.ml-3.relative',
                      [
                        m(NavbarUser)
                      ]
                    )
                  ]
                )
              ]
            )
          ]
          ),
          m('main.flex-1.relative.z-0.overflow-y-auto', {
            style: {
              height: isDelegateUser() || hideNav ? '' : 'calc(100vh - 64px)'
            }
          }, [
            children
          ])
        ]),
        !isOnline &&
        m('.absolute.top-0.w-full.z-50', [
          m('.bg-red-500.top-0.w-auto.text-center.rounded-b.text-white.text-sm.shadow.p-2', [
            t('app', 'offline')
          ])
        ])
      ])
    }
  }
}
