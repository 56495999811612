import { request } from './request'

export const getSearch = (params) => {
  return request({
    url: 'search/bookings',
    method: 'GET',
    params: {
      pagesize: 24,
      ...params
    }
  })
}

export const exportSearch = (params) => {
  return request({
    url: 'search/bookings/export',
    method: 'GET',
    params: {
      pagesize: 24,
      ...params
    },
    responseType: 'blob',
    extract (val) {
      return val.response
    }
  })
    .then(res => {
      if (res) {
        const date = new Date()
        var anchor = document.createElement('a')
        anchor.download = `tobook_search_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
        anchor.href = window.URL.createObjectURL(res)
        anchor.click()
      } else {
        alert('An error has occurred')
      }
    })
}

export const getMeetingsSearch = (params) => {
  return request({
    url: 'search/meetings',
    method: 'GET',
    params: {
      pagesize: 24,
      ...params
    }
  })
}

export const exportMeetingsSearch = (params) => {
  return request({
    url: 'search/meetings/export',
    method: 'GET',
    params: {
      pagesize: 24,
      ...params
    },
    responseType: 'blob',
    extract (val) {
      return val.response
    }
  })
    .then(res => {
      const date = new Date()
      var anchor = document.createElement('a')
      anchor.download = `tobook_search_${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}.xlsx`
      anchor.href = window.URL.createObjectURL(res)
      anchor.click()
    })
}
