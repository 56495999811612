import { request } from './request'

export const getRates = (params) => {
  return request({
    url: 'ratetypes',
    method: 'GET',
    params: {
      pagesize: 24,
      ...params
    }
  })
}

export const getAccommodationRates = (params) => {
  return request({
    url: 'ratetypes?bookingtype=1',
    method: 'GET',
    params: {
      pagesize: 24,
      ...params
    }
  })
}

export const getMeetingRates = (params) => {
  return request({
    url: 'ratetypes?bookingtype=2',
    method: 'GET',
    params: {
      pagesize: 24,
      ...params
    }
  })
}

export const getRate = (id) => {
  return request({
    url: `ratetypes/${id}`,
    method: 'GET'
  })
}

export const createRate = (rate) => {
  return request({
    url: 'ratetypes',
    method: 'POST',
    body: rate
  })
}

export const updateRate = (id, rate) => {
  return request({
    url: `ratetypes/${id}`,
    method: 'PUT',
    body: rate
  })
}
