export const DelegateCheckbox = () => {
  return {
    view ({ attrs: { label, value, checked, required, onchange, classes = [] } }) {
      return m('.checkbox.pb-0.pt-3.cursor-pointer.border-t', {
        class: [...classes],
        onclick (e) {
          onchange(value)
        }
      }, [
        m('input.absolute', {
          type: 'checkbox',
          value,
          checked,
          required: required ? 'required' : null,
          onclick (e) {
            e.stopPropagation()
            onchange(value)
          }
        }),
        m('label.cursor-pointer', {
          onclick (e) {
            e.stopPropagation()
            onchange(value)
          }
        }, label)
      ])
    }
  }
}
