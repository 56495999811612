
import { Select } from 'components/Select'
import { Dialog2 } from 'components/Dialog2'
import { File } from 'components/File'

export const ImportDLAPiperBookingsDialog = ({ attrs: { loading } }) => {
  loading = false

  const importForm = {
    file: null,
    departmentVenueId: 0
  }
  return {
    view ({ attrs: { venues, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          onsubmit(importForm)
        },
        heading: 'Import bookings',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          m('h5.text-sm.text-gray-500', 'Import spreadsheet:'),
          importForm.file
            ? m('h5.text-sm.text-gray-500', importForm.file.name)
            : '',
          m('.mt-2.grid.md:grid-cols-2.gap-3', [
            m(File, {
              type: 'file',
              name: 'file',
              classes: ['my-2'],
              label: 'Upload',
              value: importForm.file,
              oninput: (val) => {
                importForm.file = val
              }
            }, 'Select File')
          ]),
          m('h5.text-sm.text-gray-500.mt-2', 'Select Venue:'),
          m(Select, {
            label: 'Venue',
            value: importForm.departmentVenueId,
            choices: venues.map(dv => {
              return { label: dv.venueName, value: dv.id }
            }),
            onchange (val, label) {
              importForm.departmentVenueId = val
              importForm.departmentVenueLabel = label
            }
          })
        ])
      ])
    }
  }
}
