import { request } from './request'

export const getVersion = () => {
  return m.request({
    url: process.env.API_URL + 'version',
    method: 'GET'
  })
}

export const getAvailableLanguages = () => {
  return request({
    url: 'languages',
    method: 'GET'
  })
    .then(languages => {
      return languages.map(l => {
        return {
          label: l.name,
          value: l.id
        }
      })
    })
}

export const getCountries = () => {
  return request({
    url: 'countries',
    method: 'GET'
  })
    .then(countries => {
      return countries.map(c => {
        return {
          label: c.name,
          value: c.code
        }
      })
    })
}

export const getCurrencies = () => {
  return request({
    url: 'currencies',
    method: 'GET'
  }).then(currencies => {
    return currencies.map(c => {
      return {
        label: c.name,
        value: c.id
      }
    })
  })
}

export const getVenueStatuses = () => {
  return request({
    url: 'core/statuses',
    method: 'GET'
  }).then(statuses => {
    return statuses.map(s => {
      return {
        label: s.name,
        value: s.id
      }
    })
  })
}

export const getVenueGroups = () => {
  return request({
    url: 'venues/groups?pagesize=999'
  })
    .then(vg => {
      return vg.data.map(v => ({
        label: v.name,
        value: v.id
      }))
    })
}

export const getClientStatuses = () => {
  return request({
    url: 'core/statuses'
  })
    .then(cs => {
      return cs.map(s => ({
        label: s.name,
        value: s.id
      }))
    })
}

export const getDepartmentStatuses = () => {
  return request({
    url: 'core/statuses'
  })
    .then(ds => {
      return ds.map(s => ({
        label: s.name,
        value: s.id
      }))
    })
}

export const getEmailMessages = params => {
  return request({
    url: 'emailmessages',
    params: {
      pagesize: 25,
      ...params
    },
    method: 'GET'
  })
}

export const cancelEmailMessage = (id) => {
  return request({
    url: `emailmessages/${id}/cancel`,
    method: 'PUT'
  })
}
export const deleteEmailMessage = (id) => {
  return request({
    url: `emailmessages/${id}`,
    method: 'DELETE'
  })
}

export const getEmailMessage = (id) => {
  return request({
    url: `emailmessages/${id}`,
    method: 'GET'
  })
}
