import { Input } from 'components/Input'
import { TextArea } from 'components/TextArea'
import { Breadcrumb } from 'components/Breadcrumb'
import { EditForm } from 'components/EditForm'
import { EditFormSection } from 'components/EditFormSection'
import { Table } from 'components/Table'
import { getInvoice, updateInvoice, createInvoice } from 'api/invoices'
import { notification } from 'utils/notifications'
import { formatMoney } from 'utils'

export const EditInvoice = ({ attrs: { invoiceId } }) => {
  let invoice
  getInvoice(invoiceId).then(data => {
    invoice = data
    invoice.commissionPercent = (invoice.commissionRate * 100).toFixed(2)
  })

  const createUpdateInvoice = (e) => {
    e.preventDefault()
    if (invoice.id) {
      updateInvoice(invoice).then(() => {
        notification.success({ title: 'Updated' })
        m.route.set(`/invoices/${invoiceId}`)
      })
    } else {
      createInvoice(invoice).then(() => {
        notification.success({ title: 'Created' })
        m.route.set(`/invoices/${invoiceId}`)
      }).catch(err => {
        console.log(err)
        notification.error({ title: 'Error - unable to create invoice' })
      })
    }
  }
  return {
    view ({ attrs: { invoiceId } }) {
      return m('div', [
        invoice && m(Breadcrumb, {
          links: [{
            title: 'Invoices',
            href: '/invoices'
          }, {
            title: invoice.departmentVenue.department.name
          }, {
            title: 'Invoice'
          }, {
            title: invoiceId ? 'Edit' : 'Add'
          }]
        }),
        invoice && m(EditForm, {
          onsubmit: createUpdateInvoice
        },
        [
          m(EditFormSection, {
            heading: invoiceId ? 'Edit Invoice' : 'Add Invoice',
            description: 'Please enter the invoice details'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6.lg:col-span-2',
                [
                  m(Input, {
                    label: 'Invoice Number',
                    value: invoice.number,
                    type: 'text',
                    oninput (value) {
                      invoice.number = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.lg:col-span-4'),
              m('div.col-span-6.lg:col-span-2',
                [
                  m(Input, {
                    label: 'Reference',
                    value: invoice.reference,
                    type: 'text',
                    oninput (value) {
                      invoice.reference = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.lg:col-span-4'),
              m('div.col-span-6.lg:col-span-2',
                [
                  m(Input, {
                    label: 'Invoice Date',
                    value: invoice.invoiceDate,
                    type: 'date',
                    oninput (value) {
                      invoice.invoiceDate = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.lg:col-span-2',
                [
                  m(Input, {
                    label: 'Due Date',
                    value: invoice.dueDate,
                    type: 'date',
                    oninput (value) {
                      invoice.dueDate = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.lg:col-span-2'),
              m('div.col-span-6.md:col-span-3.lg:col-span-2',
                [
                  m(Input, {
                    label: 'Commission Rate',
                    value: invoice.commissionPercent,
                    type: 'number',
                    min: 0,
                    step: '.01',
                    required: true,
                    oninput (value) {
                      invoice.commissionPercent = value
                      invoice.commissionRate = (value / 100).toFixed(4)
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-3.lg:col-span-2'),
              m('div.col-span-6',
                [
                  m(TextArea, {
                    classes: ['w-full'],
                    label: t('venues', 'notes'),
                    value: invoice.notes,
                    oninput (e) {
                      invoice.notes = e
                    }
                  })
                ]
              )
            ])
          ]),
          invoice.bookingType === 1
            ? m(EditFormSection, {
              heading: 'Bookings',
              description: 'Bookings in this invoice',
              buttons: []
            }, [
              m(Table, {
                cols: [
                  {
                    label: 'Name'
                  },
                  {
                    label: 'Tariff'
                  },
                  {
                    label: 'Date'
                  }
                ],
                filters: false
              },
              [
                m('.overflow-auto',
                  invoice.lines.map((v, ix) => {
                    return m(BookingListItem, { item: v, invoice })
                  })
                )
              ])
            ])
            : m(EditFormSection, {
              heading: t('app', 'meetings'),
              description: 'Meetings in this invoice',
              buttons: []
            }, [
              m(Table, {
                cols: [
                  {
                    label: 'Description'
                  },
                  {
                    label: 'Contact'
                  },
                  {
                    label: 'Tariff'
                  },
                  {
                    label: 'Date'
                  }
                ],
                filters: false
              },
              [
                m('.overflow-auto',
                  invoice.meetingLines.map((v, ix) => {
                    return m(MeetingListItem, { item: v, invoice })
                  })
                )
              ])
            ])
        ])
      ])
    }
  }
}

const BookingListItem = ({ attrs: { item, invoice } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/clients/${invoice.departmentVenue.department.clientId}/departments/${invoice.departmentVenue.department.id}/bookings/${item.bookingId}`)
        }
      }, [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.booking.name)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', formatMoney(item.bookingTariffGross, invoice.currency.symbol, 2))
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.booking.arrivalDate)
        )
      ])
    }
  }
}

const MeetingListItem = ({ attrs: { item, invoice } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/clients/${invoice.departmentVenue.department.clientId}/departments/${invoice.departmentVenue.department.id}/meetings/${item.meetingId}`)
        }
      }, [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.meeting.description)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.meeting.name)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', formatMoney(item.meetingTariffGross, invoice.currency.symbol, 2))
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.font-medium.text-gray-900',
          m('span', item.meeting.arrivalDate)
        )
      ])
    }
  }
}
