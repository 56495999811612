import tinymce from 'tinymce'
/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default'

/* A theme is also required */
import 'tinymce/themes/silver'
import 'tinymce/skins/ui/oxide/skin.css'

/* Import plugins */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/plugins/paste'

export const TinyMce = () => {
  return {
    view ({ attrs: { name, onchange, label, value, height, editor, toolbar } }) {
      name = name || label.replaceAll(' ', '_').toLowerCase()
      return m('.flex-grow', [
        m(`label.block.text-sm.font-medium.text-gray-700[for='${name}']`,
          label
        ),
        m('textarea', {
          name: name,
          id: name,
          oncreate: el => {
            tinymce.init({
              selector: 'textarea#' + name,
              height: height || 500,
              menubar: false,
              plugins: [
                'advlist lists link',
                'table paste code'
              ],
              toolbar: toolbar || 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat table | ' +
              'code',
              content_style: 'body { font-family:Arial,Helvetica, sans-serif; font-size:14px } table { width: 100%; max-width: 558px; table-layout: fixed; } table td { padding: 2px 4px; } table tr:nth-child(odd) td { background-color: #efefef;} table tr:nth-child(even) td { background-color: #f9f9f9 }',
              setup: (ed) => {
                ed.on('change blur', function (e) {
                  onchange && onchange(ed.getContent())
                })
                if (editor) {
                  editor(ed)
                }
              }
            })
          },
          onremove: () => {
            tinymce.remove('textarea#' + name)
          }
        }, value)
      ])
    }
  }
}
