import { request } from './request'

export const getBookings = params => {
  return request({
    url: 'bookings',
    params: {
      pagesize: 25,
      ...params
    },
    method: 'GET'
  })
}

export const getBooking = (bookingId) => {
  return request({
    url: `bookings/${bookingId}`,
    method: 'GET'
  })
}

export const getBookingActivity = (bookingId, params) => {
  return request({
    url: `bookings/${bookingId}/activity`,
    method: 'GET',
    params: {
      pagesize: 100,
      isModelUpdate: false,
      ...params
    }
  })
}

export const addBooking = (body) => {
  return request({
    url: 'bookings',
    method: 'POST',
    body
  })
}
export const copyBooking = (bookingId) => {
  return request({
    url: `bookings/copy/${bookingId}`,
    method: 'POST'
  })
}

export const updateBooking = (bookingId, body) => {
  return request({
    url: `bookings/${bookingId}`,
    method: 'PUT',
    body
  })
}

export const setDepartmentVenueRate = (bookingId, departmentVenueRateId) => {
  return request({
    url: `bookings/${bookingId}/select-rate/${departmentVenueRateId}`,
    method: 'PUT'
  })
}
export const updateBookingRate = (bookingId, bookingRateId, body) => {
  return request({
    url: `bookings/${bookingId}/rate/${bookingRateId}`,
    method: 'PUT',
    body
  })
}

export const addPreference = (bookingId, body) => {
  return request({
    url: `bookings/${bookingId}/preference`,
    method: 'POST',
    body
  })
}

export const getBookingEmailTemplates = (bookingId) => {
  return request({
    url: `bookings/${bookingId}/emailtemplates`,
    method: 'GET'
  })
}

export const getDefaultBookingEmail = (bookingId, templateType) => {
  return request({
    url: `bookings/${bookingId}/defaultemail/${templateType}`,
    method: 'GET'
  })
}

export const getBookingEmail = (bookingId, templateId) => {
  return request({
    url: `bookings/${bookingId}/email/${templateId}`,
    method: 'GET'
  })
}

export const sendEmail = (bookingId, body) => {
  return request({
    url: `bookings/${bookingId}/email`,
    method: 'POST',
    body
  })
}

export const addBookingNote = (bookingId, body) => {
  return request({
    url: `bookings/${bookingId}/note`,
    method: 'POST',
    body
  })
}

export const getStatuses = () => {
  return request({
    url: 'bookings/statuses',
    method: 'GET'
  })
}

export const getStatusTotals = () => {
  return request({
    url: 'bookings/statustotals',
    method: 'GET'
  })
}

export const updateBookingStatus = (bookingId, body) => {
  return request({
    url: `bookings/${bookingId}/status`,
    method: 'PUT',
    body
  })
}

export const getBookingActivityTypes = (params) => {
  return request({
    url: 'bookings/activitytypes',
    method: 'GET',
    params
  })
}

export const getBookingContacts = (bookingId) => {
  return request({
    url: `bookings/${bookingId}/contacts`,
    method: 'GET'
  })
}

export const importBookings = (departmentId, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return request({
    method: 'POST',
    url: `bookings/import/${departmentId}`,
    body: formData
  }, {
    contentType: false
  })
}

export const importApprenticeBookings = (departmentVenueId, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return request({
    method: 'POST',
    url: `bookings/import-apprentices/${departmentVenueId}`,
    body: formData
  }, {
    contentType: false
  })
}

export const importDLAPiperBookings = (departmentVenueId, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return request({
    method: 'POST',
    url: `bookings/import-dlapiper/${departmentVenueId}`,
    body: formData
  }, {
    contentType: false
  })
}
