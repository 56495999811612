
import { Input } from 'components'
import { Dialog2 } from 'components/Dialog2'

export const EditPONumberDialog = ({ attrs: { booking, loading } }) => {
  loading = false
  const formData = {
    poNumber: booking.poNumber
  }

  return {
    view ({ attrs: { booking, loading, open, onsubmit } }) {
      return m(Dialog2, {
        open,
        save () {
          loading = true
          booking.poNumber = formData.poNumber
          onsubmit()
        },
        heading: 'Edit PO Number',
        saving: loading
      }, [
        m('.flex.flex-col.gap-3', [
          m(Input, {
            label: 'PO Number',
            value: formData.poNumber,
            type: 'text',
            oninput (value) {
              formData.poNumber = value
            }
          })
        ])
      ])
    }
  }
}
