import { Card, Link, Heading, Input, Button } from 'components'
import { resetPassword } from 'api/auth'
import { getError, displayErrors } from 'validators/utils'
import logo from 'assets/images/tobook-logo.png'
import { notification } from 'utils/notifications'

export const SetPassword = ({ attrs: { code, email } }) => {
  let errors = []
  let serverErrors
  let loading
  let success = false
  let requestToken = false
  const formData = {
    resetCode: code,
    usernameOrEmail: email,
    password: ''
  }
  const triggerPasswordReset = () => {
    errors = []
    loading = true
    resetPassword(formData)
      .then(res => {
        loading = false
        success = true
      })
      .catch(err => {
        loading = false
        serverErrors = err.response.Errors
        notification.error({ title: 'Password setting failed - link is invalid or has expired' })
        requestToken = true
      })
  }
  return {
    view () {
      return m('.h-full.flex.flex-col.justify-center.items-center.bg-orange-500.h-screen',
        [
          m('img.my-10.w-64', { src: logo }),
          m(Card, {
            classes: ['w-4/5', 'max-w-md']
          }, [
            m('.p-6', [
              m(Heading, 'Create a Password'),
              success ? m('div', [
                m('p', 'Password set. Please login to acces your new account'),
                m(Button, {
                  type: 'button',
                  onclick () {
                    m.route.set('/login')
                  }
                }, 'Login')
              ]) : m('form.grid.gap-6', {
                onsubmit (e) {
                  e.preventDefault()
                  triggerPasswordReset()
                }
              }, [
                m(Input, {
                  label: t('forgottenPassword', 'email'),
                  value: formData.usernameOrEmail,
                  type: 'readonly'
                }),
                m(Input, {
                  label: 'New ' + t('register', 'password'),
                  value: formData.password,
                  type: 'password',
                  required: true,
                  errorMessage: getError(errors, 'password'),
                  oninput (val) {
                    formData.password = val
                  }
                }),
                displayErrors(serverErrors),
                m('.flex.justify-between.items-center', [
                  m(Button, {
                    variant: 'positive',
                    loading
                  }, 'Set Password'),
                  requestToken && m(Link, {
                    classes: ['text-orange-900', 'underline'],
                    href: '/forgotten-password',
                    options: { replace: true }
                  }, 'Request New Link')
                ])
              ])
            ])
          ])
        ])
    }
  }
}
