import { Dialog2 } from 'components/Dialog2'
import { Table, Cell } from 'components/Table'

export const ImportResponseDialog = ({ attrs: { loading, importResponse } }) => {
  loading = false

  const RowError = ({ attrs: { row, classes } }) => {
    return {
      view () {
        return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
          class: classes ? classes.join(' ') : 'bg-white',
          style: {
            height: '40px'
          }
        }, [
          m(Cell, { width: 'w-24', align: 'left' },
            row.rowNumber
          ),
          m(Cell, { noEllipsis: false },
            row.errorMessage
          )
        ])
      }
    }
  }

  return {
    view ({ attrs: { loading, importResponse, open, heading } }) {
      debugger
      return m(Dialog2, {
        open,
        classes: ['md:max-w-4xl'],
        save: false,
        cancelLabel: 'Close',
        heading: heading || 'Import Details',
        saving: loading
      }, [
        importResponse && m('.flex.flex-col.gap-3', [
          importResponse.hasError
            ? m('', [
              m('h5.text-sm.text-red-500', 'The Import completed with Errors.'),
              m('text-red-500', `Error: ${importResponse.errorMessage}`)
            ])
            : m('', [
              m('h5.text-sm.text-green-500', 'The Import completed without errors.')
            ]),
          m('h5.text-sm.text-gray-500', 'Summary:'),
          m('', `Rows Processed: ${importResponse.totalRowCount}`),
          m('', `Row Errors: ${importResponse.errorCount}`),
          m('', `Meetings Created: ${importResponse.createdCount}`)
        ]),
        importResponse && importResponse.rowErrors && importResponse.rowErrors.length > 0
          ? m('.w-full', { style: 'max-height: 500px' }, [
            m('h5.text-sm.text-gray-500', 'Row Errors:'),
            m(Table, {
              cols: [
                {
                  label: 'Row',
                  classes: ['w-24 justify-left']
                },
                {
                  label: 'Error'
                }
              ],
              filters: false
            },
            m('.overflow-auto.flex-grow',
              importResponse && importResponse.rowErrors ? importResponse.rowErrors.map((v, ix) => {
                return m(RowError, { key: v.rowNumber, row: v })
              }) : m('p.text-center.p-3.text-gray-400', 'No results')
            ))
          ])
          : ''
      ])
    }
  }
}
