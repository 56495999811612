import { Svg } from 'components/Svg'
import warningIcon from 'assets/icons/outline/exclamation.svg'

export const Alert = () => {
  return {
    view ({ children, attrs: { type, title } }) {
      return m('div.rounded-md.bg-yellow-50.p-4',
        m('div.flex',
          [
            m('div.flex-shrink-0',
              m(Svg, { classes: ['text-yellow-400', 'w-6', 'h-6'] }, warningIcon)
            ),
            m('div.ml-3',
              [
                title && m('h3.text-sm.font-medium.text-yellow-800',
                  title
                ),
                m('div.mt-2.text-sm.text-yellow-700',
                  children
                )
              ]
            )
          ]
        )
      )
    }
  }
}
