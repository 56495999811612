import { Input, CurrencyInput, Select, EditForm, Checkbox, Breadcrumb } from 'components'
import { EditFormSection } from 'components/EditFormSection'
import { getVenue, getDepartmentVenue, getDepartmentVenueMeetingRate, updateDepartmentVenueMeetingRate, createDepartmentVenueMeetingRate } from 'api/venues'
import { getDepartment } from 'api/clients'
import { getMeetingRates } from 'api/rates'
import { notification } from 'utils/notifications'

export const EditDepartmentVenueMeetingRate = ({ attrs: { clientId, departmentId, venueId, departmentVenueId, departmentVenueMeetingRateId } }) => {
  let ready = false
  let rates = []
  let departmentVenueMeetingRate = {}
  let venue
  let department
  let departmentVenue
  let selectRateType

  // This screen can be called from the department or from the Venue.
  // When routed through the department the venueId will be zero
  // When routed through the venue the clientId will be zero

  if (venueId) {
    getVenue(venueId).then(data => {
      venue = data
    })
  }
  if (clientId && departmentId) {
    getDepartment(clientId, departmentId).then(data => {
      department = data
    })
  }

  getDepartmentVenue(departmentId, departmentVenueId).then(dv => {
    departmentVenue = dv
  })

  if (departmentVenueMeetingRateId > 0) {
    getDepartmentVenueMeetingRate(departmentId, departmentVenueId, departmentVenueMeetingRateId).then(dvr => {
      departmentVenueMeetingRate = dvr
      departmentVenueMeetingRate.commissionRatePercent = departmentVenueMeetingRate.commissionRate * 100
      ready = true
    })
  } else {
    getMeetingRates({ pagesize: 10000 }).then(data => {
      // eslint-disable-next-line no-unused-vars
      rates = data
      departmentVenueMeetingRate = { departmentVenueId: departmentVenueId, overrideCommission: false }
      ready = true
    })
  }

  const createUpdateDepartmentVenueMeetingRate = (e) => {
    e.preventDefault()
    if (departmentVenueMeetingRate.id) {
      updateDepartmentVenueMeetingRate(departmentId, departmentVenueId, departmentVenueMeetingRate.id, departmentVenueMeetingRate).then(() => {
        notification.success({ title: 'Updated' })
        if (venueId) {
          m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`)
        } else {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`)
        }
      }).catch(() => {
        notification.error({ title: 'Error - unable to update department venue meeting rate' })
      })
    } else {
      createDepartmentVenueMeetingRate(departmentId, departmentVenueId, departmentVenueMeetingRate).then(() => {
        notification.success({ title: 'Created' })
        if (venueId) {
          m.route.set(`/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`)
        } else {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`)
        }
      }).catch(err => {
        console.log(err)
        notification.error({ title: 'Error - unable to create department venue meeting rate' })
      })
    }
  }
  return {
    view ({ attrs: { clientId, departmentId, venueId, departmentVenueId, departmentVenueMeetingRateId } }) {
      return m('div', [
        department && departmentVenue && m(Breadcrumb, {
          links: [{
            title: 'Clients',
            href: '/clients'
          }, {
            title: department.clientName,
            href: `/clients/${clientId}`
          }, {
            title: 'Departments',
            href: `/clients/${clientId}?tab=Departments`
          }, {
            title: department.name,
            href: `/clients/${clientId}/departments/${departmentId}`
          }, {
            title: 'Venues',
            href: `/clients/${clientId}/departments/${departmentId}?tab=Venues`
          }, {
            title: departmentVenue.venue.name,
            href: `/clients/${clientId}/departments/${departmentId}/venues/${departmentVenueId}`
          }, {
            title: 'Meeting Rates'
          }, {
            title: departmentVenueMeetingRateId ? 'Edit' : 'Add'
          }]
        }),
        venue && departmentVenue && m(Breadcrumb, {
          links: [{
            title: 'Venues',
            href: '/venues'
          }, {
            title: venue.name,
            href: `/venues/${venueId}`
          }, {
            title: 'Departments',
            href: `/venues/${venueId}?tab=Departments`
          }, {
            title: departmentVenue.department.name,
            href: `/venues/${venueId}/departments/${departmentId}/${departmentVenueId}`
          }, {
            title: 'Meeting Rates'
          }, {
            title: departmentVenueMeetingRateId ? 'Edit' : 'Add'
          }]
        }),
        ready && m(EditForm, {
          onsubmit: createUpdateDepartmentVenueMeetingRate
        },
        [
          m(EditFormSection, {
            heading: departmentVenueMeetingRateId ? 'Edit Venue Meeting Rate' : 'Add Venue Meeting Rate',
            description: 'Please enter the meeting rate details for the venue at this department'
          }, [
            m('div.grid.grid-cols-6.gap-6', [
              m('div.col-span-6',
                [
                  departmentVenueMeetingRateId ? null : m(Select, {
                    label: 'Select a default Meeting Rate',
                    search: true,
                    value: null,
                    required: false,
                    onchange (e) {
                      departmentVenueMeetingRate.label = rates.data.find(d => { return d.id === parseInt(e) }).name
                      departmentVenueMeetingRate.rateMode = rates.data.find(d => { return d.id === parseInt(e) }).rateMode
                      if (selectRateType) {
                        selectRateType.setChoiceByValue(rates.data.find(d => { return d.id === parseInt(e) }).rateMode)
                      }
                    },
                    choices: rates.data.map(d => {
                      return { label: `${d.bookingTypeDescription} - ${d.name}`, value: d.id }
                    })
                  })
                ]
              ),
              m('div.col-span-6',
                [
                  m(Input, {
                    label: 'Label',
                    value: departmentVenueMeetingRate.label,
                    type: 'text',
                    required: true,
                    oninput (value) {
                      departmentVenueMeetingRate.label = value
                    }
                  })
                ]
              ),
              m('div.col-span-6', [
                m(Select, {
                  label: 'Type',
                  removeItemButton: false,
                  search: false,
                  required: true,
                  value: departmentVenueMeetingRate.rateMode,
                  onchange (e) {
                    departmentVenueMeetingRate.rateMode = parseInt(e)
                  },
                  instance (obj) {
                    selectRateType = obj
                  },
                  choices: [
                    { label: 'Day Rate', value: 0 },
                    { label: 'Delegate Rate', value: 1 },
                    { label: 'Delegate Day Rate', value: 2 },
                    { label: 'Unit Rate', value: 3 },
                    { label: 'Flat Rate', value: 4 }
                  ]
                })
              ]),
              m('div.col-span-6.md:col-span-3',
                [
                  m(CurrencyInput, {
                    label: 'Tariff',
                    value: departmentVenueMeetingRate.tariff,
                    type: 'number',
                    min: 0,
                    step: '.01',
                    required: true,
                    oninput (value) {
                      departmentVenueMeetingRate.tariff = value
                    }
                  })
                ]
              ),
              m('div.col-span-6.md:col-span-3'),
              m('div.col-span-6', [
                m(Checkbox, {
                  label: 'Override Commission Rate?',
                  checked: departmentVenueMeetingRate.overrideCommission,
                  onchange () {
                    departmentVenueMeetingRate.overrideCommission = !departmentVenueMeetingRate.overrideCommission
                  }
                })
              ]),
              departmentVenueMeetingRate.overrideCommission
                ? m('.col-span-2.pt-4.flex.items-end.gap-2', [
                  m(Input, {
                    label: 'Commision Rate',
                    value: departmentVenueMeetingRate.commissionRatePercent,
                    type: 'number',
                    min: 0,
                    step: '.01',
                    required: true,
                    oninput (value) {
                      departmentVenueMeetingRate.commissionRatePercent = value
                      departmentVenueMeetingRate.commissionRate = (value / 100).toFixed(4)
                    }
                  }),
                  m('.mb-2', '%')
                ]) : null,
              departmentVenueMeetingRate.overrideCommission
                ? m('.col-span-2') : null
            ])
          ])
        ])
      ])
    }
  }
}
