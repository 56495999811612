import { request } from './request'

export const getEmailTemplates = (params) => {
  return request({
    url: 'emailtemplates',
    method: 'GET',
    params: {
      pagesize: 24,
      ...params
    }
  })
}
export const getEmailTemplate = (id) => {
  return request({
    url: `emailtemplates/${id}`,
    method: 'GET'
  })
}

export const createEmailTemplate = (rate) => {
  return request({
    url: 'emailtemplates',
    method: 'POST',
    body: rate
  })
}

export const updateEmailTemplate = (id, rate) => {
  return request({
    url: `emailtemplates/${id}`,
    method: 'PUT',
    body: rate
  })
}

export const deleteEmailTemplate = (id) => {
  return request({
    url: `emailtemplates/${id}`,
    method: 'DELETE'
  })
}
